import { SimilarSoldItemDto } from 'types/dtos/similar-sold-item'
import { SimilarSoldItemModel } from 'types/models/similar-sold-item'

import { transformCurrencyAmountDto } from './currency-amount'
import { transformItemPhotoDto } from './item'
import { transformItemBoxDto } from './item-box'

export const transformSimilarSoldItemDtoToModel = (
  item: SimilarSoldItemDto,
): SimilarSoldItemModel => ({
  id: item.id,
  price: transformCurrencyAmountDto(item.price),
  totalPrice: transformCurrencyAmountDto(item.total_price),
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  mainPhoto: transformItemPhotoDto(item.main_photo),
  itemBox: transformItemBoxDto(item.item_box),
  isProSeller: item.is_pro_seller,
  discountPrice: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
})
