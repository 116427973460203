'use client'

import { keyBy } from 'lodash'
import { createContext, ReactNode, useMemo } from 'react'

import { FeatureSwitchDto, FeatureSwitches } from '../types'

type FeatureSwitchesProviderProps = {
  children: ReactNode
  featureSwitches: Array<FeatureSwitchDto>
}

export const FeatureSwitchesContext = createContext<FeatureSwitches>({})

const FeatureSwitchesProvider = ({ featureSwitches, children }: FeatureSwitchesProviderProps) => {
  const featureSwitchesObject = useMemo(
    () => keyBy(featureSwitches, featureSwitch => featureSwitch.name),
    [featureSwitches],
  )

  return (
    <FeatureSwitchesContext.Provider value={featureSwitchesObject}>
      {children}
    </FeatureSwitchesContext.Provider>
  )
}

export default FeatureSwitchesProvider
