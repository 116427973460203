export enum SearchSuggestionDtoType {
  Brand,
  Catalog,
}

export enum SearchSuggestionType {
  Brand = 'brand',
  Catalog = 'catalog',
  Fallback = 'frontend_generated',
  Scoped = 'scoped',
  Multiword = 'multiword',
}

export enum SearchBarSearchType {
  Item = 'item',
  User = 'user',
  Faq = 'faq',
}

export const SEARCH_START_ID_KEY = 'searchStartId'
export const SEARCH_START_TYPE_KEY = 'searchStartType'
export const SEARCH_SESSION_ID_KEY = 'searchSessionId'
export const GLOBAL_SEARCH_SESSION_ID_KEY = 'globalSearchSessionId'
export const GLOBAL_CATALOG_BROWSE_SESSION_ID_KEY = 'globalCatalogBrowseSessionId'
