import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { ConversationInitiatorType } from 'constants/conversation'
import { HttpStatus } from 'data/api/response-codes'
import { ErrorItem } from 'types/api'

import {
  CurrencyAmountModel,
  OfferRequestOptionsModel,
  TransactionModel,
  ReactionModel,
  LabelOptionsModel,
} from 'types/models'

import {
  State,
  Conversation,
  ComplaintResolution,
  Message,
  OfferPriceSuggestionOption,
} from './types'
import { stateName } from './constants'

export const initialState: State = {
  conversation: undefined,
  recipientId: null,
  ui: {
    isSidebarOpen: false,
    sendMessage: { error: null, uiState: UiState.Idle },
    createOffer: { error: null, uiState: UiState.Idle },
    getTransaction: { error: null, uiState: UiState.Idle },
    acceptOffer: { error: null, uiState: UiState.Idle },
    rejectOffer: { error: null, uiState: UiState.Idle },
    offerRequestOptions: { error: null, uiState: UiState.Idle },
    getConversation: { error: null, uiState: UiState.Idle, status: null },
    harassmentWarning: {
      isModalOpen: false,
      wasModalOpen: false,
      scoredMessage: null,
      scoredHarassmentMessageCorrelationId: null,
    },
    isEmailSharingModalVisible: false,
    labelOptions: {
      error: null,
      uiState: UiState.Idle,
    },
    isFirstTimeListerEducationModalVisible: false,
  },
  transaction: null,
  selectedOfferPriceSuggestionOption: undefined,
  offerRequestSessionId: undefined,
  labelOptions: undefined,
  shippingContactErrors: [],
}

const getConversationSuccess: CaseReducer<
  State,
  PayloadAction<{ conversation: Conversation; status: HttpStatus }>
> = (draft, action) => {
  const { conversation, status } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.status = status
}

const getConversationUiPending: CaseReducer<State, PayloadAction<{ fromPostReply?: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.getConversation.uiState = UiState.Pending
  draft.ui.getConversation.status = null

  if (!action.payload.fromPostReply) {
    draft.conversation = undefined
  }
}

const getConversationUiFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null; status: HttpStatus | null }>
> = (draft, action) => {
  const { error, status } = action.payload

  draft.conversation = undefined
  draft.ui.getConversation.error = error
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.status = status
}

const clearConversation: CaseReducer<State> = draft => {
  draft.conversation = undefined
  draft.ui.getConversation.status = null
  draft.ui.getConversation.uiState = UiState.Idle
  draft.ui.getConversation.error = null
}

const setConversation: CaseReducer<State, PayloadAction<{ conversation: Conversation }>> = (
  draft,
  action,
) => {
  const { conversation } = action.payload

  draft.conversation = conversation
  draft.ui.getConversation.status = HttpStatus.Ok
  draft.ui.getConversation.uiState = UiState.Success
  draft.ui.getConversation.error = null
}

const getComplaintSuccess: CaseReducer<State, PayloadAction<ComplaintResolution>> = (
  draft,
  action,
) => {
  if (draft.conversation) {
    draft.conversation.complaintResolution = action.payload
  }
}

const setIsSidebarOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isSidebarOpen = action.payload.isOpen
}

const postReplyRequest: CaseReducer<
  State,
  PayloadAction<{ value: string | null | undefined; photoTempUuids?: Array<string> | null }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const postReplySuccess: CaseReducer<State> = draft => {
  draft.ui.sendMessage.uiState = UiState.Success
  draft.ui.sendMessage.error = null
}

const setSendMessageUiFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.sendMessage.error = error
  draft.ui.sendMessage.uiState = UiState.Failure
}

const createItemConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    itemId: string
    sellerId: string
    initiator: ConversationInitiatorType
  }>
> = draft => {
  draft.ui.getConversation.uiState = UiState.Pending
}

const createItemConversationThreadFailure: CaseReducer<
  State,
  PayloadAction<{
    error: string | null
  }>
> = (draft, action) => {
  draft.ui.getConversation.uiState = UiState.Failure
  draft.ui.getConversation.error = action.payload.error
}

const createItemConversationThreadSuccess: CaseReducer<
  State,
  PayloadAction<{
    conversation: Conversation
  }>
> = (draft, actions) => {
  draft.conversation = actions.payload.conversation
  draft.ui.getConversation.uiState = UiState.Success
}

const createConversationThreadRequest: CaseReducer<
  State,
  PayloadAction<{
    recipientId: number | null | undefined
    value: string | null | undefined
    photoTempUuids?: Array<string> | null
  }>
> = draft => {
  draft.ui.sendMessage.uiState = UiState.Pending
}

const setRecipientId: CaseReducer<State, PayloadAction<{ recipientId: number | null }>> = (
  draft,
  action,
) => {
  draft.recipientId = action.payload.recipientId
}

const createOfferRequest: CaseReducer<
  State,
  PayloadAction<{
    offerPrice: string
    currentPrice: string
    isBuyer: boolean
    currency: string
    transactionId: number
    offerSuggestionOptionIndex?: number
  }>
> = draft => {
  draft.ui.createOffer.uiState = UiState.Pending
}

const createOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.createOffer.uiState = UiState.Success
  draft.ui.createOffer.error = null
}

const createOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.createOffer.uiState = UiState.Failure
  draft.ui.createOffer.error = error
}

const acceptOfferRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; offerRequestId: number }>
> = draft => {
  draft.ui.acceptOffer.uiState = UiState.Pending
}

const acceptOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.acceptOffer.uiState = UiState.Success
  draft.ui.acceptOffer.error = null
}

const acceptOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.acceptOffer.uiState = UiState.Failure
  draft.ui.acceptOffer.error = error
}

const rejectOfferRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number; offerRequestId: number }>
> = draft => {
  draft.ui.rejectOffer.uiState = UiState.Pending
}

const rejectOfferSuccess: CaseReducer<State> = draft => {
  draft.ui.rejectOffer.uiState = UiState.Success
  draft.ui.rejectOffer.error = null
}

const rejectOfferFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.rejectOffer.uiState = UiState.Failure
  draft.ui.rejectOffer.error = error
}

const setMessageDeleted: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => (message as Message).id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.url = ''
    photo.isHidden = true
  } else {
    message.isHidden = true
    message.content = ''
  }
}

const setMessageLiked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reaction: ReactionModel }>
> = (draft, action) => {
  const { messageId, photoId, reaction } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = {
      id: reaction.id,
      type: reaction.type,
    }

    return
  }

  message.reaction = {
    id: reaction.id,
    type: reaction.type,
  }
}

const setMessageUnliked: CaseReducer<
  State,
  PayloadAction<{ messageId: number; photoId?: number; reactionId: number }>
> = (draft, action) => {
  const { messageId, photoId } = action.payload

  const entity = draft.conversation?.messages.find(
    ({ message }) => message && 'id' in message && message.id === messageId,
  )

  const message = entity?.message as Message

  if (!message) return

  if (photoId) {
    const photo = message.photos.find(({ id }) => id === photoId)

    if (!photo) return

    photo.reaction = null

    return
  }

  message.reaction = null
}

const setHarassmentMessage: CaseReducer<
  State,
  PayloadAction<{ messageText: string | null; correlationId: string | null }>
> = (draft, action) => {
  const { messageText, correlationId } = action.payload

  draft.ui.sendMessage.uiState = UiState.Failure
  draft.ui.harassmentWarning.isModalOpen = true
  draft.ui.harassmentWarning.wasModalOpen = true
  draft.ui.harassmentWarning.scoredMessage = messageText
  draft.ui.harassmentWarning.scoredHarassmentMessageCorrelationId = correlationId
}

const clearHarassmentMessage: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
  draft.ui.harassmentWarning.wasModalOpen = false
  draft.ui.harassmentWarning.scoredMessage = null
}

const setHarassmentWarningModalClosed: CaseReducer<State> = draft => {
  draft.ui.harassmentWarning.isModalOpen = false
}

const setEmailSharingModalVisible: CaseReducer<State, PayloadAction<{ isVisible: boolean }>> = (
  draft,
  action,
) => {
  const { isVisible } = action.payload

  draft.ui.isEmailSharingModalVisible = isVisible
}

const getOfferRequestOptionsRequest: CaseReducer<
  State,
  PayloadAction<{ itemPrice: CurrencyAmountModel; sellerId: number }>
> = draft => {
  draft.ui.offerRequestOptions.uiState = UiState.Pending
}

const getOfferRequestOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{ offerRequestOptions: OfferRequestOptionsModel; isOfferGuidanceEnabled: boolean }>
> = (draft, action) => {
  draft.offerRequestOptions = action.payload.offerRequestOptions

  draft.ui.offerRequestOptions.uiState = UiState.Success
  draft.ui.offerRequestOptions.error = null

  draft.selectedOfferPriceSuggestionOption = action.payload.isOfferGuidanceEnabled
    ? {
        isCustom: true,
      }
    : undefined
}

const getOfferRequestOptionsFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  draft.ui.offerRequestOptions.uiState = UiState.Failure
  draft.ui.offerRequestOptions.error = action.payload.error
}

const fetchTransactionRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: number
  }>
> = draft => {
  draft.ui.getTransaction.uiState = UiState.Pending
}

const fetchTransactionRequestSuccess: CaseReducer<
  State,
  PayloadAction<{ transaction: TransactionModel }>
> = (draft, action) => {
  const { transaction } = action.payload

  draft.transaction = transaction

  draft.ui.getTransaction.uiState = UiState.Success
  draft.ui.getTransaction.error = null
}

const fetchTransactionRequestFailure: CaseReducer<
  State,
  PayloadAction<{ error: string | null }>
> = (draft, action) => {
  const { error } = action.payload

  draft.ui.getTransaction.uiState = UiState.Failure
  draft.ui.getTransaction.error = error
}

const setFirstTimeListerEducationModalVisible: CaseReducer<
  State,
  PayloadAction<{ isVisible: boolean }>
> = (draft, action) => {
  const { isVisible } = action.payload

  draft.ui.isFirstTimeListerEducationModalVisible = isVisible
}

const setSelectedOfferPriceSuggestionOption: CaseReducer<
  State,
  PayloadAction<{ offerPriceSuggestionOption: OfferPriceSuggestionOption }>
> = (draft, action) => {
  draft.selectedOfferPriceSuggestionOption = action.payload.offerPriceSuggestionOption
}

const resetSelectedOfferPriceSuggestionOption: CaseReducer<State, PayloadAction> = draft => {
  draft.selectedOfferPriceSuggestionOption = {
    isCustom: true,
  }
}

const setOfferRequestSessionId: CaseReducer<
  State,
  PayloadAction<{ offerRequestSessionId: string }>
> = (draft, action) => {
  draft.offerRequestSessionId = action.payload.offerRequestSessionId
}

const getLabelOptionsRequest: CaseReducer<State, PayloadAction<{ shipmentId: number }>> = draft => {
  draft.ui.labelOptions.uiState = UiState.Pending
}

const getLabelOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{ labelOptions: LabelOptionsModel }>
> = (draft, action) => {
  const { labelOptions } = action.payload

  draft.labelOptions = labelOptions
  draft.ui.labelOptions.uiState = UiState.Success
}

const getLabelOptionsFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.labelOptions.uiState = UiState.Failure
  draft.ui.labelOptions.error = error
}

const clearLabelOptions: CaseReducer<State> = draft => {
  draft.labelOptions = undefined
}

const setShippingContactNumber = (
  draft: State,
  action: PayloadAction<{ phoneNumber: string | null }>,
) => {
  draft.transaction!.buyerPhoneNumber = action.payload.phoneNumber
  draft.transaction!.sellerPhoneNumber = action.payload.phoneNumber
}

const setShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.shippingContactErrors.push(error)
}

const removeShippingContactErrors: CaseReducer<State> = draft => {
  draft.shippingContactErrors = []
}

const removeShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.shippingContactErrors = draft.shippingContactErrors.filter(
    ({ field, value }) => field !== error.field && value !== error.value,
  )
}

const conversationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getConversationSuccess,
    getConversationUiFailure,
    getConversationUiPending,
    clearConversation,
    setConversation,
    getComplaintSuccess,
    setIsSidebarOpen,
    postReplyRequest,
    postReplyFailure: setSendMessageUiFailure,
    postReplySuccess,
    setRecipientId,
    createConversationThreadRequest,
    createConversationThreadFailure: setSendMessageUiFailure,
    createOfferRequest,
    createOfferSuccess,
    createOfferFailure,
    acceptOfferRequest,
    acceptOfferSuccess,
    acceptOfferFailure,
    rejectOfferRequest,
    rejectOfferSuccess,
    rejectOfferFailure,
    setSelectedOfferPriceSuggestionOption,
    resetSelectedOfferPriceSuggestionOption,
    setOfferRequestSessionId,
    setMessageDeleted,
    setMessageLiked,
    setMessageUnliked,
    setHarassmentMessage,
    clearHarassmentMessage,
    setHarassmentWarningModalClosed,
    setEmailSharingModalVisible,
    getOfferRequestOptionsRequest,
    getOfferRequestOptionsSuccess,
    getOfferRequestOptionsFailure,
    createItemConversationThreadRequest,
    createItemConversationThreadSuccess,
    createItemConversationThreadFailure,
    fetchTransactionRequest,
    fetchTransactionRequestSuccess,
    fetchTransactionRequestFailure,
    setFirstTimeListerEducationModalVisible,
    getLabelOptionsRequest,
    getLabelOptionsSuccess,
    getLabelOptionsFailure,
    clearLabelOptions,
    setShippingContactNumber,
    setShippingContactError,
    removeShippingContactErrors,
    removeShippingContactError,
  },
})

export const { actions } = conversationSlice
export const plug = { [stateName]: conversationSlice.reducer }
export default conversationSlice.reducer
