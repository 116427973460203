import { Sagas } from './types'

import conversationSagas from './conversation/sagas'
import { sagas as checkoutSagas } from './checkout'
import itemUploadSagas from './item-upload/sagas'
import bannersSagas from './banners/sagas'
import businessAddressesSagas from './business-addresses/sagas'
import systemConfigurationSagas from './system-configuration/sagas'

export const getStaticSagas = (): Sagas => []

export const getCoreSagas = (): Sagas => [
  conversationSagas(),
  checkoutSagas(),
  itemUploadSagas(),
  bannersSagas(),
  businessAddressesSagas(),
  systemConfigurationSagas(),
]

export { default as businessAccountsSaga } from './business-accounts/sagas'
