export const USER_STATUS_MEMBER_DELETED = 3
export const USER_STATUS_BANNED = 8

export const VERIFICATION_LIST = ['facebook', 'google', 'email']

export const CLOSET_PROMOTIONS_COUNT = 4

export const DEFAULT_PAGE_SIZE = 20

export const INITIAL_PAGE = 1

export const SEARCH_SUGGESTION_FALLBACK_ID = -1
export const SEARCH_SUGGESTION_MIN_QUERY_LENGTH = 1

export enum AccessChannel {
  VintedGuide = 'vinted_guide',
  HcSearch = 'hc_search',
  HcTransaction = 'hc_transaction',
  HcTopics = 'hc_topics',
  ConversationTx = 'conversation_tx',
  ConversationNoTx = 'conversation_no',
  ProductLink = 'product_link',
  ExternalLink = 'external_link',
  DataExport = 'data_export',
}

export const CDN_ASSETS_URL = 'https://static-assets.vinted.com'
export const CDN_IMAGES_URL = `${CDN_ASSETS_URL}/images`
export const CDN_ADS_URL = `${CDN_ASSETS_URL}/ads`
export const CDN_SHIPPING_DIRECTORY = 'shipping'
export const CDN_SHIPPING_TRACKING_ASSETS_URL = `${CDN_ASSETS_URL}/${CDN_SHIPPING_DIRECTORY}/tracking/tracking_code_`

export const TWO_FA_CANCELLED_EVENT = 'twoFACancelled'
export const TWO_FA_COMPLETED_EVENT = 'twoFACompleted'

export enum TransparencyLawSubject {
  BuyerRights = 'buyerRights',
  ProfessionalSeller = 'professionalSeller',
}
