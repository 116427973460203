'use client'

import { ReactNode, useEffect, useState } from 'react'
import { throttle, isEqual } from 'lodash'

import useWindowEvent from 'hooks/useWindowEvent'
import { Breakpoints } from 'types/components'
import useCookie from '_libs/common/cookie-manager/hooks/useCookie'

import { Provider } from './BreakpointContext'
import {
  getInitialBreakpoints,
  getClientSideBreakpoints,
  setViewportCookie,
  SsrBreakpointsConfigs,
} from './utils'

const RESIZE_DELAY = 50

type Props = {
  children: ReactNode
  ssrConfigs?: SsrBreakpointsConfigs
}

const BreakpointProvider = ({ children, ssrConfigs }: Props) => {
  const [breakpoints, setBreakpoints] = useState<Breakpoints>(() =>
    getInitialBreakpoints(ssrConfigs),
  )

  const cookies = useCookie()

  useEffect(() => {
    setViewportCookie(cookies)
  }, [breakpoints, cookies])

  useWindowEvent(
    'resize',
    throttle(() => {
      const newBreakpoints = getClientSideBreakpoints()

      setBreakpoints(prevBreakpoints =>
        isEqual(newBreakpoints, prevBreakpoints) ? prevBreakpoints : newBreakpoints,
      )
    }, RESIZE_DELAY),
  )

  return <Provider value={{ breakpoints }}>{children}</Provider>
}

export default BreakpointProvider
