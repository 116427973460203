export enum ClickableElement {
  AccountName = 'account_name',
  AddItemToBundle = 'add_item_to_bundle',
  AddEprUin = 'add_epr_uin',
  Continue = 'continue',
  CancelAddEprUin = 'cancel_add_epr_uin',
  DiscardAddEprUin = 'discard_add_epr_uin',
  BrowseCatalog = 'browse_catalog',
  CancelBundle = 'cancel_bundle',
  CancelInstallAppBanner = 'cancel_install_app_banner',
  ChangeEmail = 'change_email',
  ClosePage = 'close_page',
  CloseScreen = 'close_screen',
  ConfirmEverythingIsOk = 'confirm_everything_is_ok',
  ConfirmOrderReturn = 'confirm_order_return',
  StartBundle = 'start_bundle',
  CreateBundle = 'create_bundle',
  EditBankAccount = 'edit_bank_account',
  EditPhoto = 'edit_photo',
  FilterSortingSelection = 'filter_sorting_selection',
  Help = 'help',
  IHaveAnIssue = 'i_have_an_issue',
  InstallAppBanner = 'install_app_banner',
  MarkAsReceived = 'mark_as_received',
  NeverShowTranslationBanner = 'never_show_translation_banner',
  NpsSurveyDismiss = 'nps_survey_dismiss',
  NpsSurveySend = 'nps_survey_send',
  OpenTrackingPage = 'open_tracking_page',
  PersonalizationButtonAfterFeed = 'personalization_button_after_feed',
  PortalDraftItemReminderBannerCta = 'portal_draft_item_reminder_banner_cta',
  PushUpItem = 'push_up_item',
  PromoteCloset = 'promote_closet',
  RecentSearchOpen = 'recent_search_open',
  RemoveItemFromBundle = 'remove_item_from_bundle',
  RemoveSearch = 'remove_search',
  RequestReturn = 'request_to_return',
  ResolveComplaint = 'confirm_resolve_this_issue',
  SaveSearch = 'save_search',
  SavedSearchOpen = 'saved_search_open',
  SearchBar = 'search_bar',
  SearchBarType = 'search_bar_type',
  SeeWholeClosetCta = 'see_whole_closet_cta',
  ShowItemPerformance = 'show_item_performance',
  SubmitRearrange = 'submit_rearrange',
  SubmitKybForm = 'submit_kyb',
  TrackingInformationLink = 'tracking_information_link',
  TrackParcel = 'track_parcel',
  TrackReturnParcel = 'track_return_parcel',
  TrackShipmentView = 'track_shipment_view',
  TranslateDescription = 'translate_description',
  TranslateFeedback = 'translate_feedback',
  TranslateMessage = 'translate_message',
  UploadItem = 'upload_item',
  UploadMoreAccept = 'upload_more_accept',
  UploadMoreCancel = 'upload_more_cancel',
  UploadPackageSelect = 'upload_package_select',
  ViewMorePromotedUsers = 'view_more_promoted_users',
  ViewComplaint = 'view_complaint',
  ViewDeliveryInstructions = 'view_delivery_instructions',
  ViewIssueDetails = 'view_issue_details',
  DownloadShippingLabel = 'download_shipping_label',
  CheckoutPayButton = 'pay',
  AddNewCardButton = 'add_new_card',
  AddNewCardFromPaymentOptions = 'add_new_card_from_payment_options',
  ConfirmComplaintResolved = 'confirm_complaint_resolved',
  RefuseComplaintResolved = 'refuse_complaint_resolved',
  PopularItemsButton = 'all_popular_items_button',
  PopularItemsBlock = 'all_popular_items_block',
  CancelLeavingModal = 'cancel_leaving_modal',
  ShowLeavingModal = 'show_leaving_modal',
  ProceedFromLeavingModal = 'proceed_from_leaving_modal',
  Favourite = 'favorite',
  LearnMore = 'learn_more',
  KycSaveAddress = 'save_address',
  KycContinueWithSelectedDocument = 'continue_with_selected_document',
  KycContinueWithSelectedSupportingDocument = 'continue_with_selected_supporting_document',
  KycUploadPhotoDone = 'upload_photo_done',
  KycDeleteDocument = 'delete_document',
  KycSubmitIdentityConfirmation = 'submit_identity_confirmation',
  KycDiscardChanges = 'discard_changes',
  KycTryAgain = 'try_again',
  KycContactSupport = 'contact_support',
  KycConfirmIdentity = 'confirm_identity',
  GetShippingLabel = 'get_shipping_label',
  Confirm = 'confirm',
  Notification = 'notification',
  DonationsSetup = 'set_up_donations',
  FundraiserSetup = 'set_up_donations',
  DonationsStart = 'start_donations',
  FundraiserStart = 'start_donations',
  DonationsManage = 'manage_donations',
  FundraiserManage = 'manage_donations',
  DonationsUpdate = 'save_donations_changes',
  FundraiserUpdate = 'save_donations_changes',
  DonationsStop = 'stop_donations',
  FundraiserStop = 'stop_donations',
  DonationsLearnMore = 'learn_about_donations_from_donations_overview',
  FundraiserLearnMore = 'learn_about_donations_from_donations_overview',
  DonationsAboutUserProfile = 'learn_about_donations_from_wardrobe',
  DonationsAboutUserInfo = 'learn_about_donations_from_user_about_section',
  RecentlyFavouritedItemsButton = 'all_recently_favorited_items_button',
  RecentlyFavouritedItemsBlock = 'all_recently_favorited_items_block',
  Hyperlink = 'hyperlink',
  DropOffPointsLink = 'drop_off_points_link',
  MergeFavoriteItemListBanner = 'merge_favorite_item_list_banner',
  MergeCatalogBanner = 'merge_catalog_banner',
  MergeReviewsBanner = 'merge_reviews_banner',
  MergeBalanceBanner = 'merge_balance_banner',
  MergeFollowersBanner = 'merge_followers_banner',
  MergeFollowingBanner = 'merge_following_banner',
  MergeAnnouncementAction = 'merge_announcement_cta',
  MergeAnnouncementLink = 'merge_announcement_link',
  MergeSelectFaq = 'merge_select_faq',
  ItemsBasedOnRecentPurchasesButton = 'all_items_based_on_recent_purchases_button',
  ItemsBasedOnRecentPurchasesBlock = 'all_items_based_on_recent_purchases_block',
  SearchThisArea = 'search_this_area',
  CloseListerActivationBanner = 'close_lister_activation_banner',
  UploadAfterListerActivationBanner = 'upload_after_lister_activation_banner',
  SaveBillingAddress = 'save_address',
  GoToMyBalance = 'go_to_my_balance',
  SelectDropoffType = 'select_dropoff_type',
  SubmitPaymentMethod = 'submit_payment_method',
  SelectPaymentMethod = 'select_payment_method',
  WebPhotosLearnMore = 'web_photos_learn_more',
  WebPhotosCloseModal = 'web_photos_ok_close_modal',
  LoginWithFacebook = 'login_with_facebook',
  LoginWithGoogle = 'login_with_google',
  LoginWithApple = 'login_with_apple',
  Login = 'login',
  UseThisCard = 'use_this_card',
  SaveTheCard = 'save_the_card',
  DontSaveTheCard = 'dont_save_the_card',
  CopyTrackingNumber = 'copy_tracking_number',
  SaveAddress = 'save_address',
  AddPhoto = 'add_photo',
  Submit = 'submit',
  ServiceFeeInfo = 'service_fee_info',
  ShippingMethodSelection = 'shipping_method_selection',
  ShippingMethodEducation = 'shipping_method_education',
  SelectShippingMethod = 'select_shipping_method',
  ChooseParcelSize = 'choose_parcel_size',
  SelectParcelSize = 'select_parcel_size',
  BusinessRegistrationBack = 'business_registration_back',
  CompleteRegistration = 'complete_registration',
  ConvertToBusiness = 'convert_to_business',
  OpenDigitalLabel = 'open_digital_label',
  DownloadDigitalLabel = 'download_digital_label',
  DownloadIosApp = 'download_ios_app',
  DownloadAndroidApp = 'download_android_app',
  DiscardChanges = 'discard_changes',
  PricingDetails = 'pricing_details',
  ContactDetailsContinue = 'contact_details_continue',
  ContactDetailsDelete = 'delete_contact_details',
  ContactDetailsDeleteConfirm = 'confirm_phone_number_deletion',
  MuteVideo = 'mute_video',
  UnmuteVideo = 'unmute_video',
  MergeMessageListBanner = 'merge_message_list_banner',
  SellerAddContactDetails = 'seller_add_contact_details',
  SavePhoneNumber = 'save_phone_number',
  MergeMissingInfoContinue = 'merge_missing_info_continue',
  ZipCodeForTaxInfoBanner = 'zip_code_for_tax_info_banner',
  CrmMessageLink = 'crm_message_link',
  FeedLoadMoreButton = 'feed_load_more_button',
  CrmInAppMessageDismiss = 'crm_in_app_message_dismiss',
  SalesTaxInfo = 'sales_tax_info',
  BundlesPolicyLink = 'bundles_policy_help_centre_link',
  Skip = 'skip',
  Save = 'save',
  Ok = 'ok',
  SubmitEprUin = 'submit_epr_uin',
  ImpressionsTab = 'impressions_tab',
  ProfileVisitsTab = 'profile_visits_tab',
  ItemVisitsTab = 'item_visits_tab',
  InsightsHelp = 'discovery_help',
  ActionsHelp = 'actions_help',
  FreeReturnLabelLearnMore = 'free_return_label_learn_more',
  ReviewClosetPromotionOrder = 'review_closet_promo_order',
  ConfirmClosetPromotionOrder = 'pay_closet_promo_order',
  ClosetPromotionPreCheckoutHelp = 'how_its_working',
  ClosetPromotionReviewHelp = 'how_it_works',
  CatalogTab = 'catalog_tab',
  RefundPolicyLink = 'refund_policy_link',
  PushUpBundle = 'push_up_bundle',
  MarkTransactionAsShipped = 'mark_transaction_as_shipped',
  SelectLabelType = 'select_label_type',
  ConfirmMarkTransactionAsShipped = 'confirm_mark_transaction_as_shipped',
  ReviewPushupOrder = 'review_push_up_order',
  MultiplePushUp = 'multiple_push_up',
  PushUpDurationChange = 'push_up_duration_change',
  Cancel = 'cancel',
  ConfirmDirectDonationOrder = 'pay_direct_donation_order',
  ConfirmItemPushUp = 'confirm_item_push_up',
  ConfirmMultipleItemsPushUp = 'confirm_multiple_items_push_up',
  MakeBuyerSideOffer = 'make_buyer_side_offer',
  SelectSuggestedOfferDiscount = 'select_suggested_discount',
  PushUpLearnMode = 'push_up_learn_more',
  Logo = 'logo',
  Done = 'done',
  SellingTips = 'improvement_tips',
  PhotoTips = 'photo_tips',
  PriceTips = 'price_tips',
  DescriptionTips = 'description_tips',
  UpdateItem = 'update_listing',
  InteractionRate = 'engagement_metric',
  ReturnOrder = 'return_order',
  GiveFeedback = 'give_feedback',
  SubmitSuccess = 'submit_success',
  ConfirmSubmit = 'confirm_submit',
  ApplyFilters = 'apply_filters',
  ClearFilters = 'clear_filters',
  CancelFilters = 'cancel_filters',
  Drag = 'drag',
  ReuploadItem = 'reupload_item',
  PickPackageSize = 'pick_package_size',
  SubmitPackageSize = 'submit_package_size',
  SelectFilters = 'select_filters',
  SustainabilityPageLink = 'sustainability_page_link',
  EnterDonationsFromWebMenu = 'enter_donations_from_web_menu',
  EnterDonationFromSettings = 'enter_donations_from_settings',
  NavigationMenuToggle = 'navigation_menu_toggle',
  CatalogSearchResultsRanking = 'catalog_search_results_ranking',
  SessionsChangePassword = 'sessions_change_password',
  SessionsLogOut = 'sessions_log_out',
  ClosetPromotionHelp = 'spotlight_information',
  HelpCenterSearchResult = 'help_center_search_result',
  Verify = 'verify',
  DidntReceiveEmail = 'didnt_receive_email',
  PayWithBlikCode = 'pay_with_blik_code',
  Start = 'start',
  OfflineVerificationCheckbox = 'check_hvf_verification',
  ElectronicsVerificationCheckbox = 'check_electronics_verification',
  OfflineVerificationLearnMoreLink = 'how_hvf_verification_works',
  ElectronicsVerificationLearnMoreLink = 'how_electronics_verification_works',
  OfflineVerificationTrackedShippingLearnMoreLink = 'authenticity_tracked_shipping_learn_more',
  FilterSold = 'filter_sold',
  FilterDrafts = 'filter_drafts',
  FilterHidden = 'filter_hidden',
  FilterReserved = 'filter_reserved',
  FilterActive = 'filter_active',
  PhysicalAuthenticityBuyer = 'physical_auth_buyer',
  PhysicalAuthenticitySeller = 'physical_auth_seller',
  ElectronicsVerificationBuyer = 'electronics_verification_buyer',
  ElectronicsVerificationSeller = 'electronics_verification_seller',
  OfflineVerificationHowItWorks = 'physical_auth_how_it_works',
  OfflineVerificationLearnMore = 'physical_auth_learn_more',
  OfflineVerificationGotIt = 'physical_auth_got_it',
  ElectronicsVerificationLearnMore = 'electronics_verification_learn_more',
  ElectronicsVerificationGotIt = 'electronics_verification_got_it',
  ElectronicsVerificationHowItWorks = 'electronics_verification_how_it_works',
  ShippingDiscountDetails = 'shipping_discount_details',
  ManageItems = 'manage_items',
  SaveOrdering = 'save_ordering',
  PromoteItem = 'promote_item',
  Close = 'close',
  ConfirmContactUs = 'confirm_contact_us',
  MessageReply = 'message_reply',
  SkipTerms = 'skip_terms',
  AcceptTerms = 'accept_terms',
  RedirectTerms = 'redirect_terms',
  SeeCompensationInformation = 'see_compensation_information',
  SeeDropoffPointsInformation = 'see_dropoff_points_information',
  CarrierPreferencesToggle = 'carrier_preferences_toggle',
  CloseCarrierDisableModal = 'close_carrier_disable_modal',
  SatisfactionSurveySend = 'satisfaction_survey_send',
  AddItems = 'add_items',
  ProceedToCheckout = 'proceed_to_checkout',
  ApplyChanges = 'apply_changes',
  AskSellerButton = 'write_message',
  CloseAd = 'close_ad',
  ClosetPromotionDynamicPricingInfo = 'dynamic_pricing_info',
  EditItem = 'edit_item',
  DeleteItem = 'delete_item',
  HideItem = 'hide_item',
  CarrierTermsAndConditions = 'carrier_terms_and_conditions',
  FeedbackAllReviewsFilter = 'all_reviews',
  FeedbackMemberReviewsFilter = 'member_reviews',
  FeedbackAutoReviewsFilter = 'automatic_reviews',
  ProcessingListingStatusModalFaqLink = 'processing_status_modal_faq_link',
  CtaPromptContinue = 'cta_prompt_continue',
  CtaPromptImageContinue = 'cta_prompt_image_continue',
  WalletBalanceInfo = 'wallet_balance_info',
  CvvRequestInfo = 'cvv_request_info',
  CvvRequestPay = 'cvv_request_pay',
  CvvRequestCancel = 'cvv_request_cancel',
  PushUpLearnMore = 'push_up_learn_more',
  FollowedUserSeeAllItems = 'followed_user_see_all_items',
  ListerBadge = 'lister_badge',
  ReplierBadge = 'replier_badge',
  CloseBadgeInfo = 'close_badge_info',
  ProfileMessage = 'profile_message',
  FeedbackTab = 'feedback_tab',
  ProfileTab = 'profile_tab',
  AboutTab = 'about_tab',
  Followers = 'followers',
  Following = 'following',
  Follow = 'follow',
  AskSeller = 'ask_seller',
  PhotosMetadataNote = 'photos_metadata_note',
  VasInfo = 'vas_info',
  AddressSearchBar = 'address_search_bar',
  DropOffPointsMapLink = 'drop_off_points_map_link',
  DropOffPointDetails = 'drop_off_point_details',
  TaxpayersCheckTaxRules = 'taxpayers_check_tax_rules',
  PriceInformation = 'price_information',
  SeeDropOffPoints = 'see_drop_off_points',
  TaxRuleItem = 'tax_rule_item',
  TaxpayersDac7Banner = 'taxpayers_dac7_banner',
  CtaRedirect = 'cta_redirect',
  DropOffPoint = 'drop_off_point',
  CvvFieldInfo = 'cvv_field_info',
  TaxpayersBalanceBlockModal = 'taxpayers_balance_block_modal',
  TaxpayersSalesBlockModal = 'taxpayers_sales_block_modal',
  CobrandedDropdown = 'co_branded_drop_down',
  CobrandedModalSelection = 'co_branded_modal_selection',
  CobrandedModalInfo = 'co_branded_modal_info',
  TaxpayerReportAccordion = 'taxpayers_report_expand',
  TaxpayerOpenReportHistory = 'taxpayers_open_report_history',
  TaxpayerOpenReport = 'taxpayers_open_report',
  HelpCenterLink = 'help_center_link',
  TaxpayersDac7SpecialVerificationBanner = 'taxpayers_dac7_special_verification_banner',
  FinishSpecialVerification = 'finish_special_verification',
  BundleStories = 'bundle_stories',
  CloseBundleLearning = 'close_bundle_learning',
  ItemBoxContextMenu = 'itembox_context_menu',
  ItemBoxUserProfileLink = 'itembox_user_profile_link',
  UserShortInfoCell = 'user_short_info_cell',
  ViewAllUserItems = 'view_all_user_items',
  ViewAllUserItemsTitleCta = 'view_all_user_items_title_cta',
  ViewAllUserItemsCardCta = 'view_all_user_items_card_cta',
  MoreSimilarItemsTitleCta = 'more_similar_items_title_cta',
  MoreSimilarItemsCardCta = 'more_similar_items_card_cta',
  DislikeTheBrand = 'dislike_the_brand',
  LinkDropoffMap = 'link_dropoff_map',
  TaxpayersTaxRulesVideo = 'taxpayers_tax_rules_video',
  StartSpecialVerification = 'start_special_verification',
  InfoAboutDac7 = 'info_about_dac7',
  ManufacturerCredentials = 'manufacturer_credentials',
  LabelingAndMarkingInfo = 'labeling_and_marking_info',
  ManufacturerFaq = 'producer_responsibility_info',
  RemoveContributorConfirm = 'remove_contributor_confirm',
  RemoveContributorCancel = 'remove_contributor_cancel',
  AcceptAccountSharingInvitation = 'accept_account_sharing_invitation',
  CancelAccountSharingInvitation = 'cancel_account_sharing_invitation',
  FixTaxpayersReport = 'fix_taxpayers_report',
  ContactUs = 'contact_us',
  ListingIncreaseBanner = 'listing_increase_banner',
  CloseListingIncreaseBanner = 'close_listing_increase_banner',
  DiscountInfo = 'discount_info',
  FaqFirstListModal = 'faq_first_list_modal',
  CloseFirstListModal = 'close_first_list_modal',
  UploadFirstListModal = 'upload_first_list_modal',
  MoreInformation = 'more_information',
  ConfirmSubmitRequestReturn = 'confirm_submit_request_return',
  AddToReport = 'add_to_report',
  PreviewReport = 'preview_report',
  CancelReport = 'cancel_report',
  ItemDiscountsRefundInformation = 'item_discounts_refund_information',
  BpfRefundInformation = 'bpf_refund_information',
  EstimatedRefundInformation = 'estimated_refund_information',
  CloseSelectTypeModal = 'close_select_type_modal',
  SelectStatus = 'select_status',
  Pep = 'pep',
  NotPep = 'not_pep',
  PepRelative = 'pep_associate',
  SwitchToLogin = 'switch_to_login',
  SwitchToEmailLogin = 'switch_to_email_login',
  SwitchToRegistration = 'switch_to_registration',
  SwitchToEmailRegistration = 'switch_to_email_registration',
  PoliciesCheckbox = 'policies_checkbox',
  NewsletterCheckbox = 'newsletter_checkbox',
  RegisterWithEmail = 'register_with_email',
  RegisterWithSocial = 'register_with_social',
  SocialLoginWithPassword = 'social_login_with_password',
  HavingTrouble = 'having_trouble',
  ForgotPassword = 'forgot_password',
  PrivacyPolicy = 'privacy_policy',
  TermsAndConditions = 'terms_and_conditions',
  ExploreFeed = 'explore_feed',
  TaxpayerForms = 'taxpayer_forms',
  TaxpayerViewForm = 'view_form',
  IdentityFormContinue = 'identity_verification_continue',
  BillingAddressFormContinue = 'billing_address_verification_continue',
  BusinessInformationFormContinue = 'business_information_verification_continue',
  BusinessTinFormContinue = 'business_tin_verification_continue',
  BusinessVatNumberFormContinue = 'business_vat_number_verification_continue',
  PlaceOfBirthFormContinue = 'place_of_birth_verification_continue',
  TinFormContinue = 'tin_verification_continue',
  VatNumberFormContinue = 'vat_number_verification_continue',
  TaxpayersSpecialVerificationBalanceBlockModal = 'taxpayers_special_verification_balance_block_modal',
  ExtendShippingDeadline = 'extend_shipping_deadline',
  TaxpayersSelectTin = 'select_tin',
  TaxpayersSelectPlaceOfBirth = 'select_place_of_birth',
  CancelShippingDeadlineExtension = 'cancel_shipping_deadline_extension',
  BundleDiscountsSettings = 'bundle_discount_settings',
  DeclineShippingDeadlineExtension = 'decline_shipping_deadline_extension',
  AgreeExtendShippingDeadline = 'agree_extend_shipping_deadline',
  SoldOrders = 'sold_orders',
  BoughtOrders = 'bought_orders',
  AllOrders = 'all_orders',
  InProgressOrders = 'in_progress_orders',
  CancelledOrders = 'cancelled_orders',
  CompletedOrders = 'completed_orders',
  Order = 'order',
  HelpCenter = 'help_center',
  CookieSettings = 'cookie_settings',
  Profile = 'profile',
  Settings = 'settings',
  Customize = 'customize',
  Balance = 'balance',
  AboutVinted = 'about_vinted',
  ItemVerificationInfo = 'item_verification_fee_info',
  RecentSearchTab = 'recent_search',
  SubscribedSearchTab = 'subscribed_search',
  CpReminder = 'cp_reminder',
  CloseShippingInfoBanner = 'close_shipping_info_banner',
  FilterByBrand = 'filter_by_brand',
  NewMessageButton = 'new_message',
  SellerFeedbackTitle = 'seller_feedback_title',
  ViewAllFeedbacks = 'view_all_feedbacks',
  LearnHowModal = 'learn_how_modal',
  ReadMoreFeedback = 'read_more_feedback',
  ReadMore = 'read_more',
  ItemAttribute = 'item_attribute',
  SellerFeedbackUserInfoCell = 'seller_feedback_user_info_cell',
  SellerInsights = 'seller_insights',
  SellerInsightsToggle = 'seller_insights_toggle',
  SellerInsightsSeeMore = 'seller_insights_see_more',
  SellerInsightsInfo = 'seller_insights_info',
  SellerInsightsTab = 'insights_tab',
  PersonalInformationModalChangeResponse = 'personal_information_modal_change_response',
  PersonalInformationModalSendAnyway = 'personal_information_modal_send_anyway',
  PromotionalListingBanner = 'promotional_listing_banner',
  ReportReasonSelect = 'report_reason_select',
  IvsGuidelineBanner = 'ivs_guideline_banner',
  CancelCarrierChangeRequest = 'cancel_carrier_change_request',
  RequestCarrierChange = 'request_carrier_change',
  DeclineCarrierChangeRequest = 'decline_carrier_change_request',
  ProductCardInfluencerLandingPage = 'product_card_influencer_landing_page',
  PersonalInformationModalClose = 'personal_information_modal_close',
  PersonalInformationModalLearnMore = 'personal_information_modal_learn_more',
  LearnMoreLeavingModal = 'learn_more_leaving_modal',
  StayOnVintedLeavingModal = 'stay_on_vinted_leaving_modal',
  AcceptCarrierChangeRequest = 'accept_carrier_change_request',
  FirstTimeListerEducationSeeTips = 'first_time_lister_education_see_tips',
  FirstTimeListerEducationShowHelpCenter = 'first_time_lister_education_show_help_center',
  FirstTimeListerEducationClose = 'first_time_lister_education_close',
  ComplaintResolution = 'complaint_resolution',
  OfferPartialRefund = 'offer_partial_refund',
  PartialRefundOfferAccept = 'partial_refund_offer_accept',
  PartialRefundOfferDecline = 'partial_refund_offer_decline',
  PartialRefundOfferCancel = 'partial_refund_offer_cancel',
  CheckInbox = 'check_inbox',
  HorizontalItemsBlockHeaderCta = 'horizontal_items_block_header_cta',
  PhoneNumber = 'phone_number',
  BeyondFashionNewListerBanner = 'beyond_fashion_new_lister_banner',
  PickupPoint = 'pickup_point',
  CurrentLocation = 'current_location',
  ChoosePickupPoint = 'choose_pickup_point',
  GetPickupPointDirections = 'get_pickup_point_directions',
  PickupPointSearchClick = 'pickup_point_search_click',
  PickupPointSearchResult = 'pickup_point_search_result',
  ShippingOption = 'shipping_option',
  ChooseHomeDeliveryOption = 'choose_home_delivery_option',
  PickupPointMap = 'pickup_point_map',
  PickupPointInfo = 'pickup_point_info',
  PickupType = 'pickup_type',
  EscrowFeeCalculationInfo = 'escrow_fee_calculation_info',
  ViewOrdersInProgress = 'view_orders_in_progress',
}
