import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { call, select, take } from 'typed-redux-saga'

import { MEMBER_PROFILE_URL, ROOT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { ItemAfterUploadActions, PromotionStorageKeys } from 'constants/item-upload'
import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { transformItemEditDto, transformSizeGroupDtos } from 'data/transformers'
import { navigateToPage, scrollToElementById } from '_libs/utils/window'
import { tracker } from '_libs/common/tracker'
import {
  itemUploadStartEventExtra,
  itemUploadSubmitFail,
  itemUploadSubmitSuccess,
} from '_libs/common/event-tracker/events'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import {
  CompleteItemArgs,
  CreateItemArgs,
  CreateItemDraftArgs,
  ItemEditResp,
  ResponseError,
  SubmitDraftResp,
  SubmitItemResp,
  UpdateItemArgs,
  UpdateItemDraftArgs,
} from 'types/api'
import { GtmTrackableItemDto } from 'types/dtos'
import { UiState as UiStateEnum } from 'constants/ui'
import { getFieldErrors } from 'pages/ItemUpload/utils/errors'

import * as statelessActions from './actions'
import * as transformers from './transformers'
import * as selectors from './selectors'
import {
  REQUEST_DELAY,
  WITHOUT_BRAND_ID,
  FieldName,
  IsbnValidity,
  ItemUploadFailReason,
  ItemStatus,
} from './constants'
import { actions } from './slice'
import { setItemIdToStorage, setPromotionsAfterItemUploadToStorage } from './helpers'

export function* fetchItem({
  payload: {
    id,
    tempUuid,
    setItemStatus,
    isMultipleSizeGroupsABTestEnabled,
    setUiState,
    fetchCategoryAttributes,
    setSelectedDynamicAttributeValues,
  },
}: ReturnType<typeof statelessActions.fetchItemRequest>) {
  const isNewCreateAndEditItemEnabled = yield* select(
    getIsFeatureSwitchEnabled('item_upload_create_and_edit_item_new_web'),
  )

  if (!id) {
    const sessionResponse = yield* call(api.getSessionDefaults)
    if ('errors' in sessionResponse) return

    yield put(
      actions.setCurrency({ currency: sessionResponse.session_defaults_configuration.currency }),
    )

    yield call(setUiState, UiStateEnum.Success)
    yield call(
      tracker.track,
      itemUploadStartEventExtra({ tempUuid, screenName: Screen.ItemUpload }),
    )

    return
  }

  let additionalHeaders = {}
  if (isMultipleSizeGroupsABTestEnabled) {
    additionalHeaders = { 'X-Enable-Multiple-Size-Groups': 'true' }
  }

  let response: ItemEditResp | ResponseError<unknown>

  if (isNewCreateAndEditItemEnabled) {
    response = yield* call(api.getItemEdit, { id, additionalHeaders })
  } else {
    response = yield* call(api.getItemEditDeprecated, { id, additionalHeaders })
  }

  if ('errors' in response) {
    yield call(setUiState, UiStateEnum.Failure)

    return
  }

  const item = transformItemEditDto(response.item)

  yield put(actions.setInitialItemData({ item }))

  const itemStatus = item.isDraft ? ItemStatus.DraftEdit : ItemStatus.Edit
  yield call(setItemStatus, itemStatus)

  yield put(actions.setCurrency({ currency: item.currency }))

  yield call(fetchCategoryAttributes, item.catalogId, itemStatus)
  yield call(setSelectedDynamicAttributeValues, item.itemAttributes)
  yield call(setUiState, UiStateEnum.Success)
  yield call(
    tracker.track,
    itemUploadStartEventExtra({
      tempUuid,
      screenName: item.isDraft ? Screen.ItemDraft : Screen.ItemEdit,
    }),
  )
}

export function* selectCatalog({ payload }: ReturnType<typeof statelessActions.selectCatalog>) {
  const {
    fieldErrors,
    setFieldErrors,
    removeFieldError,
    setAuthenticityModalContent,
    requestAuthenticityModal,
    currentCatalog,
    newCatalog,
    fetchCategoryAttributes,
  } = payload

  if (!newCatalog) return

  if (newCatalog.restrictedToStatusId) {
    yield put(actions.setStatusId({ id: null, itemStatus: payload.itemStatus, removeFieldError }))
  }

  if (!newCatalog.isIsbnInputShown) {
    yield put(
      actions.setIsbn({
        isbn: null,
        validity: IsbnValidity.Unvalidated,
        fieldErrors,
        setFieldErrors,
        removeFieldError,
      }),
    )
  }

  if (!newCatalog.isBrandSelectShown) {
    yield put(
      actions.setBrand({
        id: WITHOUT_BRAND_ID,
        title: null,
        isLuxury: false,
        itemStatus: payload.itemStatus,
        removeFieldError,
      }),
    )
  }

  if (currentCatalog) {
    if (currentCatalog.sizeGroupId !== newCatalog.sizeGroupId) {
      yield put(actions.setSizeId({ id: null, itemStatus: payload.itemStatus, removeFieldError }))
    }
  }

  yield put(
    actions.setCatalogId({
      id: payload.catalogId,
      itemStatus: payload.itemStatus,
      removeFieldError,
    }),
  )

  yield call(fetchCategoryAttributes, payload.catalogId, payload.itemStatus)

  const brandIsLuxury = yield* select(selectors.getIsBrandLuxury)

  yield call(setAuthenticityModalContent, null)

  if (brandIsLuxury) {
    yield call(requestAuthenticityModal, { modalDataOnly: true, newCatalogId: payload.catalogId })
  }
}

export function* fetchSizeGroupsByCatalog() {
  const { catalogId } = yield* select(selectors.selectAttributes)

  if (!catalogId) return

  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)
  const response = yield* call(api.getSizeGroupsByCatalog, catalogId, isMultipleSizeGroupsEnabled)

  if ('errors' in response) return

  const transformedDto = yield* call(transformSizeGroupDtos, response.size_groups)

  yield put(actions.setSizeGroups({ sizeGroups: transformedDto.sizeGroups }))
  yield put(actions.setSizes({ sizes: transformedDto.sizes }))
}

export function* fetchBookDetails({ payload }: ReturnType<typeof actions.setIsbn>) {
  if (payload.validity === IsbnValidity.Unvalidated) return

  if (payload.validity === IsbnValidity.Invalid || !payload.isbn) {
    yield call(payload.removeFieldError, FieldName.Isbn)

    return
  }

  yield put(actions.setIsbnPendingState())
  yield delay(REQUEST_DELAY)

  const response = yield* call(api.getBookDetails, { isbn: payload.isbn })

  if ('errors' in response) {
    yield call(payload.setFieldErrors, {
      byName: { ...payload.fieldErrors.byName, [FieldName.Isbn]: response.message },
      names: [...payload.fieldErrors.names, FieldName.Isbn],
    })

    return
  }

  const bookDetails = transformers.transformBookDetails(response)

  yield put(actions.setBookDetails({ bookDetails }))
}

export function* handleNewItemIncompleteTaxAddressResponse({
  meta,
  payload: {
    user,
    tempUuid,
    itemStatus,
    screenName,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    showEVSModal,
    showIVSModal,
    assignedPhotos,
    enableForm,
    disableForm,
    dynamicAttributes,
  },
}: Pick<ReturnType<typeof statelessActions.submitItem>, 'meta' | 'payload'>) {
  yield put(actions.showMissingPostalCode())
  const action = yield* take(actions.hideMissingPostalCode)

  if (!action.payload?.resubmitItem) return

  yield put(
    statelessActions.submitItem({
      user,
      additionalHeaders,
      tempUuid,
      itemStatus,
      screenName,
      saveAsDraft: meta.saveAsDraft,
      isItemBumped: meta.isItemBumped,
      trackListingToGoogleTagManager,
      trackListingToBraze,
      setFieldErrors,
      showEVSModal,
      showIVSModal,
      assignedPhotos,
      enableForm,
      disableForm,
      dynamicAttributes,
    }),
  )
}

export function* handleItemSubmitResponseCode({ responseCode, meta, payload }) {
  switch (responseCode) {
    case ResponseCode.IncompleteTaxAddress:
      yield* call(handleNewItemIncompleteTaxAddressResponse, { meta, payload })
      break
    case ResponseCode.PhotoMinimumCountRequired:
      yield put(actions.setIsLuxuryItemModalOpen({ isOpen: true }))
      break
    default:
      break
  }
}

export function* submitItem({ meta, payload }: ReturnType<typeof statelessActions.submitItem>) {
  const {
    user,
    tempUuid,
    itemStatus,
    screenName,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    showEVSModal,
    showIVSModal,
    assignedPhotos,
    enableForm,
    disableForm,
    dynamicAttributes,
  } = payload

  yield call(disableForm)

  let response: SubmitItemResp | SubmitDraftResp | ResponseError

  const isNewCreateAndEditItemEnabled = yield* select(
    getIsFeatureSwitchEnabled('item_upload_create_and_edit_item_new_web'),
  )
  const isNewCreateAndEditDraftEnabled = yield* select(
    getIsFeatureSwitchEnabled('item_upload_create_and_edit_draft_new_web'),
  )
  const isNewItemCompletionEnabled = yield* select(
    getIsFeatureSwitchEnabled('item_upload_item_completion_new_web'),
  )
  const itemAttributes = yield* select(selectors.selectAttributes)
  const currency = yield* select(selectors.getCurrency)
  const itemDto = yield* call(
    transformers.transformItemAttributesToItemUploadDto,
    itemAttributes,
    assignedPhotos,
    dynamicAttributes,
    currency,
    tempUuid,
  )

  const { isItemBumped, saveAsDraft } = meta

  if (saveAsDraft) {
    if (itemDto.id) {
      const updateItemDraftArgs: UpdateItemDraftArgs = {
        draft: itemDto,
        feedbackId: itemAttributes.feedbackId,
        additionalHeaders,
      }

      if (isNewCreateAndEditDraftEnabled) {
        response = yield* call(api.updateItemDraft, updateItemDraftArgs)
      } else {
        response = yield* call(api.updateItemDraftDeprecated, updateItemDraftArgs)
      }
    } else {
      const createItemDraftArgs: CreateItemDraftArgs = {
        draft: itemDto,
        feedbackId: itemAttributes.feedbackId,
        additionalHeaders,
      }

      if (isNewCreateAndEditDraftEnabled) {
        response = yield* call(api.createItemDraft, createItemDraftArgs)
      } else {
        response = yield* call(api.createItemDraftDeprecated, createItemDraftArgs)
      }
    }
  } else if (itemStatus === ItemStatus.DraftEdit && itemDto.id) {
    const completeItemArgs: CompleteItemArgs = {
      draft: itemDto,
      feedbackId: itemAttributes.feedbackId,
      additionalHeaders,
    }

    if (isNewItemCompletionEnabled) {
      response = yield* call(api.completeItem, { ...completeItemArgs, isItemBumped })
    } else {
      response = yield* call(api.completeItemDeprecated, completeItemArgs)
    }

    if (
      (isItemBumped && !isNewItemCompletionEnabled) ||
      ('item' in response &&
        response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout))
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  } else if (itemDto.id) {
    const updateItemArgs: UpdateItemArgs = {
      item: itemDto,
      feedbackId: itemAttributes.feedbackId,
      additionalHeaders,
    }

    if (isNewCreateAndEditItemEnabled) {
      response = yield* call(api.updateItem, { ...updateItemArgs, isItemBumped })
    } else {
      response = yield* call(api.updateItemDeprecated, updateItemArgs)
    }

    if (
      (isItemBumped && !isNewCreateAndEditItemEnabled) ||
      ('item' in response &&
        response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout))
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    }
  } else {
    const createItemArgs: CreateItemArgs = {
      item: itemDto,
      feedbackId: itemAttributes.feedbackId,
      additionalHeaders,
    }

    if (isNewCreateAndEditItemEnabled) {
      response = yield* call(api.createItem, { ...createItemArgs, isItemBumped })
    } else {
      response = yield* call(api.createItemDeprecated, createItemArgs)
    }

    if (
      (isItemBumped && !isNewCreateAndEditItemEnabled) ||
      ('item' in response &&
        response.after_upload_actions?.includes(ItemAfterUploadActions.ShowBumpsCheckout))
    ) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowBumped)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  }

  if (!itemAttributes.feedbackId) {
    setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowFeedback)
  }

  if (!response) {
    yield call(enableForm)

    return
  }

  if ('errors' in response) {
    yield call(setFieldErrors, getFieldErrors(response.errors))
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    yield* call(scrollToElementById, response.errors[0]!?.field)
    yield call(enableForm)

    tracker.track(
      itemUploadSubmitFail({
        reason: ItemUploadFailReason.ValidationError,
        validationErrors: response.errors.map(error => error.value),
        screen: screenName,
        tempUuid,
      }),
    )

    yield call(handleItemSubmitResponseCode, {
      responseCode: response.code,
      meta,
      payload,
    })

    return
  }

  let redirectUrl = ROOT_URL
  let itemId = itemDto.id

  if ('item' in response) {
    const { item } = response
    const isItemEdit = itemStatus === ItemStatus.Edit
    const isNewListing = !saveAsDraft && !isItemEdit

    if (isNewListing) {
      const gtmTrackableItem: GtmTrackableItemDto = {
        id: item.id,
        catalog_id: itemAttributes.catalogId,
        currency,
        price: { amount: itemAttributes.price?.toString() || '', currency_code: currency },
        title: itemAttributes.title,
        user: { email: user.email, external_id: user.externalId, id: user.id },
      }

      yield call(trackListingToGoogleTagManager, gtmTrackableItem)
      yield call(trackListingToBraze, { itemId: item.id, userExternalId: user.externalId })
    }

    redirectUrl = MEMBER_PROFILE_URL(user.id)
    itemId = item.id
  } else if ('draft' in response) {
    redirectUrl = MEMBER_PROFILE_URL(user.id)
    itemId = response.draft.id
  }

  tracker.track(
    itemUploadSubmitSuccess({
      itemId: itemId || undefined,
      tempUuid,
      screen: screenName,
    }),
  )

  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowIVSModal)) {
    if (itemId) setItemIdToStorage(itemId)
    yield* call(showIVSModal)

    return
  }

  if (response.after_upload_actions?.includes(ItemAfterUploadActions.ShowEVSModal)) {
    if (itemId) setItemIdToStorage(itemId)
    yield* call(showEVSModal)

    return
  }

  if (itemId) setItemIdToStorage(itemId)

  yield* call(navigateToPage, redirectUrl)
}

export function* fetchItemSuggestions({
  payload,
}: ReturnType<typeof statelessActions.fetchItemSuggestionsRequest>) {
  const isKillswitchEnabled = yield* select(
    getIsFeatureSwitchEnabled('killswitch_item_upload_suggestions_web'),
  )

  if (isKillswitchEnabled) return

  yield delay(REQUEST_DELAY)

  const { title, description, catalogId } = yield* select(selectors.selectAttributes)
  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)

  const args = {
    title,
    description,
    catalogId,
    photoIds: payload.photoIds,
    isMultipleSizeGroupsEnabled,
  }

  const response = yield* call(api.getItemSuggestions, args)

  if ('errors' in response) return

  const { colors, sizes, brands, catalogs } = yield* call(
    transformers.transformItemSuggestions,
    response,
  )

  yield put(actions.setSuggestions({ colors, sizes, brands, catalogs }))
}

export default function* saga() {
  yield takeLatest(statelessActions.fetchItemSuggestionsRequest, fetchItemSuggestions)

  yield takeLatest(statelessActions.fetchItemRequest, fetchItem)

  yield takeEvery(statelessActions.selectCatalog, selectCatalog)

  yield takeEvery(statelessActions.fetchSizeGroupsByCatalog, fetchSizeGroupsByCatalog)

  yield takeEvery(statelessActions.submitItem, submitItem)

  yield takeLatest(actions.setIsbn, fetchBookDetails)
}
