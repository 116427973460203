import { omitBy, isNil } from 'lodash'

import { EntryType } from 'constants/photo-tip'
import { AlertType } from 'constants/admin'
import { API_BASE_PATH, CURRENT_USER_API_ENDPOINT } from 'constants/paths'
import { EducationType } from 'constants/education'
import { ReportReasonCatalogSystem } from 'constants/report-reason'
import { VasEntryPoint } from 'constants/vas-entry-point'

import * as apiTypes from 'types/api'

import ApiClient, { interceptors } from '_libs/common/api-client'
import ClientCookieManager from '_libs/common/cookie-manager/cookie-manager-client'
import { cookiesDataByName } from '_libs/common/cookie-manager/cookies-data'

import {
  BusinessAddressDto,
  BusinessRegistrationDto,
  WalletConversionErrorDto,
  FeedEventsDto,
  EprInfoDto,
  PromoBoxResp,
  RefundEstimateDto,
  BannersDto,
  DataExportListDto,
  RefundOfferCreationDetailsDto,
  RefundOfferIdDto,
} from 'types/dtos'

import * as requests from './requests'
import * as transformers from './transformers/args'
import * as authInterceptors from './authentication/interceptors'

const multipleSizeGroupsHeader = {
  'X-Enable-Multiple-Size-Groups': 'true',
}

const moneyObjectHeader = {
  'X-Money-Object': 'true',
}

const noCacheHeader = {
  'Cache-Control': 'no-cache',
}

const cookies = new ClientCookieManager()

const baseURL = API_BASE_PATH

const api = new ApiClient({ baseURL }, [
  interceptors.csrfTokenInterceptor,
  interceptors.errorInterceptor,
  authInterceptors.tokenRefreshInterceptor,
  interceptors.localeInterceptor,
  interceptors.phoneVerificationInterceptor,
  interceptors.twoFAInterceptor,
  interceptors.anonIdInterceptor,
  cookies.get(cookiesDataByName.supports_webp)
    ? interceptors.appendAcceptWebPHeaderInterceptor
    : undefined,
])

export const getColors = () => api.get<apiTypes.ColorsResp>('/colors')

export const getCatalogs = requests.getCatalogsRequest(api)

export const getSizeGroups = () => api.get<apiTypes.GetSizeGroupsResp>('/size_groups')

export const getSizeGroupsByCatalog = (
  catalogId: number,
  isMultipleSizeGroupsEnabled: boolean | undefined,
) =>
  api.get<apiTypes.GetSizeGroupsResp>('/size_groups', {
    params: { catalog_ids: catalogId },
    headers: isMultipleSizeGroupsEnabled ? multipleSizeGroupsHeader : {},
  })

export const getVideoGameRatings = () =>
  api.get<apiTypes.GetVideoGameRatingsResp>('/video_game_ratings')

export const getModelAttributeConfiguration = ({
  catalogId,
  brandId,
}: apiTypes.getUploadFormModelsArgs) =>
  api.get<apiTypes.GetModelAttributeConfigurationResp>(
    '/item_upload/model_attribute_configuration',
    {
      params: {
        catalog_id: catalogId,
        brand_id: brandId,
      },
    },
  )

export const getUploadFormModels = ({ catalogId, brandId }: apiTypes.getUploadFormModelsArgs) =>
  api.get<apiTypes.GetUploadFormModelsResp>('item_upload/models', {
    params: {
      catalog_id: catalogId,
      brand_id: brandId,
    },
  })

export const getUploadFormSearchModels = ({
  catalogId,
  brandId,
  searchText,
}: apiTypes.getUploadFormSearchModelsArgs) =>
  api.get<apiTypes.GetUploadFormModelsResp>('item_upload/models_search', {
    params: {
      catalog_id: catalogId,
      brand_id: brandId,
      search_text: searchText,
    },
  })

export const getItemUploadConfiguration = () =>
  api.get<apiTypes.ItemUploadConfigurationResp>('/items/configuration')

export const completeItemDeprecated = ({
  draft,
  feedbackId,
  additionalHeaders,
}: apiTypes.CompleteItemArgs) =>
  api.post<apiTypes.SubmitItemResp>(
    `items/drafts/${draft.id}/completion`,
    { draft, feedback_id: feedbackId },
    {
      headers: {
        ...moneyObjectHeader,
        ...additionalHeaders,
      },
    },
  )

export const completeItem = ({
  draft,
  feedbackId,
  additionalHeaders,
  isItemBumped,
}: apiTypes.CompleteItemArgs) =>
  api.post<apiTypes.SubmitItemResp>(
    `/item_upload/drafts/${draft.id}/completion`,
    { draft, feedback_id: feedbackId, push_up: isItemBumped },
    {
      headers: {
        ...moneyObjectHeader,
        ...additionalHeaders,
      },
    },
  )

export const createItemDraftDeprecated = ({
  draft,
  feedbackId,
  additionalHeaders,
}: apiTypes.CreateItemDraftArgs) =>
  api.post<apiTypes.SubmitDraftResp>(
    '/items/drafts',
    { draft, feedback_id: feedbackId },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const createItemDraft = ({
  draft,
  feedbackId,
  additionalHeaders,
}: apiTypes.CreateItemDraftArgs) =>
  api.post<apiTypes.SubmitDraftResp>(
    '/item_upload/drafts',
    { draft, feedback_id: feedbackId },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const confirmComplaintResolved = (conversationId: number | string) =>
  api.patch<apiTypes.ConversationResp>(
    `/conversations/${conversationId}/complaint/confirm_resolved`,
  )

export const declineComplaintResolved = (conversationId: number | string) =>
  api.patch<apiTypes.ConversationResp>(`/conversations/${conversationId}/complaint/refuse_resolved`)

export const updateItemDraftDeprecated = ({
  draft,
  feedbackId,
  additionalHeaders,
}: apiTypes.UpdateItemDraftArgs) =>
  api.put<apiTypes.SubmitDraftResp>(
    `/items/drafts/${draft.id}`,
    { draft, feedback_id: feedbackId },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const updateItemDraft = ({
  draft,
  feedbackId,
  additionalHeaders,
}: apiTypes.UpdateItemDraftArgs) =>
  api.put<apiTypes.SubmitDraftResp>(
    `/item_upload/drafts/${draft.id}`,
    { draft, feedback_id: feedbackId },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const deleteItem = (id: number) => api.post(`/items/${id}/delete`)

export const deleteItemDraftDeprecated = (id: number) => api.delete(`/items/drafts/${id}`)

export const deleteItemDraft = (id: number) => api.delete(`/item_upload/drafts/${id}`)

export const getItemAuthenticityModal = ({
  catalogId,
  itemId,
  force = false,
  modalDataOnly = false,
}: apiTypes.GetItemAuthenticityModalArgs) =>
  api.get<apiTypes.ItemAuthenticityModalResp>('/items/authenticity_modals', {
    params: {
      catalog_id: catalogId,
      force,
      item_id: itemId,
      modal_data_only: modalDataOnly,
    },
  })

export const getSystemConfiguration = requests.getSystemConfigurationRequest(api)

// This request function is only created for update_item_price_after_accepted_offer AB test and won't be scaled
export const updateItemPrice = ({ itemId, itemPrice }: { itemId: number; itemPrice: number }) =>
  api.put(
    `/items/${itemId}`,
    {
      item: { price: itemPrice },
      feedback_id: null,
    },
    {
      headers: moneyObjectHeader,
    },
  )

export const updateItemDeprecated = ({
  item,
  feedbackId,
  additionalHeaders,
}: apiTypes.UpdateItemArgs) =>
  api.put<apiTypes.SubmitItemResp>(
    `/items/${item.id}`,
    {
      item,
      feedback_id: feedbackId,
    },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const updateItem = ({
  item,
  feedbackId,
  additionalHeaders,
  isItemBumped,
}: apiTypes.UpdateItemArgs) =>
  api.put<apiTypes.SubmitItemResp>(
    `/item_upload/items/${item.id}`,
    { item, feedback_id: feedbackId, push_up: isItemBumped },
    {
      headers: {
        ...additionalHeaders,
        ...moneyObjectHeader,
      },
    },
  )

export const modifyItemVisibility = (itemId: number, isHidden: boolean) =>
  api.put<apiTypes.ApiResponse>(`/items/${itemId}/is_hidden`, {
    is_hidden: isHidden,
  })

export const createItemDeprecated = ({
  item,
  feedbackId,
  additionalHeaders,
}: apiTypes.CreateItemArgs) =>
  api.post<apiTypes.SubmitItemResp>(
    '/items',
    { item, feedback_id: feedbackId },
    {
      headers: {
        'X-Upload-Form': 'true',
        ...moneyObjectHeader,
        ...additionalHeaders,
      },
    },
  )

export const createItem = ({
  item,
  feedbackId,
  additionalHeaders,
  isItemBumped,
}: apiTypes.CreateItemArgs) =>
  api.post<apiTypes.SubmitItemResp>(
    '/item_upload/items',
    { item, feedback_id: feedbackId, push_up: isItemBumped },
    {
      headers: {
        'X-Upload-Form': 'true',
        ...moneyObjectHeader,
        ...additionalHeaders,
      },
    },
  )

export const getCatalogItems = (args: apiTypes.GetCatalogItemsArgs) =>
  api.get<apiTypes.GetCatalogItemsResp>('/catalog/items', {
    params: {
      ...transformers.getCatalogItemsArgsToParams(args),
      search_by_image_uuid: args.searchByImageUuid,
    },
    headers: moneyObjectHeader,
  })

export const getCatalogInitializers = (args: apiTypes.GetCatalogInitializerArgs) =>
  api.get<apiTypes.GetCatalogInitializersResp>('/catalog/initializers', {
    params: omitBy(args, isNil),
    headers: moneyObjectHeader,
  })

export const getCatalogFacetedCategories = (args: apiTypes.GetCatalogFacetedCategoriesArgs) =>
  api.get<apiTypes.GetCatalogFacetedCategoriesResp>('/catalog/faceted_categories', {
    params: transformers.getCatalogFacetedCategoriesArgsToParams(args),
  })

export const getCatalogFilters = (args: apiTypes.GetCatalogFiltersArgs) =>
  api.get<apiTypes.GetCatalogFiltersResp>('/catalog/filters', {
    params: transformers.getCatalogFiltersArgsToParams(args),
  })

export const getCatalogDefaultFilters = (args: apiTypes.GetCatalogDefaultFiltersArgs) =>
  api.get<apiTypes.GetCatalogDefaultFiltersResp>('/catalog/filters/defaults', {
    params: transformers.getCatalogDefaultFiltersArgsToParams(args),
  })

export const getCatalogSearchedFilters = (args: apiTypes.GetCatalogSearchedFiltersArgs) =>
  api.get<apiTypes.GetCatalogSearchedFiltersResp>('/catalog/filters/search', {
    params: transformers.getCatalogSearchedFiltersArgsToParams(args),
  })

export const getPhotoTips = (entryType = EntryType.Default) =>
  api.get<apiTypes.PhotoTipsResp>('/photo_tips', {
    params: { entry_type: entryType },
  })

export const getPhotoTip = (id: number) => api.get<apiTypes.PhotoTipResp>(`/photo_tips/${id}`)

export const getCountries = () => api.get<apiTypes.CountriesResp>('/countries')

export const getCountriesSelection = () =>
  api.get<apiTypes.CountriesSelectionResp>('/country_selection')

export const searchCities = ({ countryId, searchText }: apiTypes.CitiesSearchArgs) =>
  api.get<apiTypes.CitiesSearchResp>(`/countries/${countryId}/cities`, {
    params: { search_text: searchText || '' },
  })

export const getBrands = ({ keyword, includeAllBrands }: apiTypes.GetBrandsArgs) =>
  api.get<apiTypes.BrandsResp>('/brands', {
    params: omitBy({ keyword, all_brands: includeAllBrands || null }, isNil),
  })

export const dislikeBrand = (itemId: number) => api.post('/brands/dislike', { item_id: itemId })

export const getPopularBrands = ({ letter }: apiTypes.GetPopularBrandsArgs = {}) =>
  api.get<apiTypes.BrandsResp>('/brands/popular', {
    params: {
      letter,
    },
  })

export const getBrandById = (brandId: number) => api.get<apiTypes.BrandResp>(`/brands/${brandId}`)

export const getBrandsFollowedByUser = (userId: number) =>
  api.get<apiTypes.BrandsResp>(`/users/${userId}/brands`)

export const sendHelpCenterFeedback = (args: apiTypes.HelpCenterFeedbackArgs) =>
  api.post('/help_center/feedbacks', transformers.sendHelpCenterFeedbackArgsToParams(args))

export const getShowItemUploadFeedback = () =>
  api.get<apiTypes.GetShowItemUploadFeedbackResp>('/items/show_upload_feedback')

export const getItemUploadFeedbackRatings = () =>
  api.get<apiTypes.GetItemUploadFeedbackRatingsResp>('/items/upload_feedback')

export const sendItemUploadFeedback = (args: apiTypes.SendItemUploadFeedbackArgs) =>
  api.put<apiTypes.SendItemUploadFeedbackResp>(
    '/items/upload_feedback',
    transformers.sendItemUploadFeedbackArgsToParams(args),
  )

export const sendNpsSurveyResponse = ({
  userId,
  score,
  comment,
  incomplete,
}: apiTypes.SendNpsSurveyResponseArgs) =>
  api.post(`/users/${userId}/nps`, {
    score,
    comment,
    incomplete,
  })

export const deleteNpsSurvey = ({ userId }: apiTypes.DeleteNpsSurveyArgs) =>
  api.delete(`/users/${userId}/nps`)

export const completeTransaction = (id: string | number) =>
  api.put(`/transactions/${id}/complete`, undefined, { headers: moneyObjectHeader })

export const deleteUser = (userId: string | number, notes: string | null | undefined) =>
  api.delete(`/users/${userId}`, {
    params: {
      delete_account_notes: notes,
    },
  })

export const getUserById = ({ id, localize = false }: apiTypes.GetUserByIdArgs) =>
  api.get<apiTypes.UserResp>(`/users/${id}`, { params: { localize } })

export const getFeedEvents = (args: apiTypes.GetFeedEventsArgs) =>
  api.get<FeedEventsDto>(`/feed/events/${args.tabName}`, {
    params: {
      max_score: args.maxScore,
      item_count: args.itemCount,
      homepage_session_id: args.homepageSessionId,
    },
  })

export const getHomepageBatch = (index: number, args: apiTypes.GetHomepageBatchArgs) =>
  api.get<apiTypes.HomepageBlocksResp>(`/homepage/batch/${args.tabName}/${index}`, {
    params: {
      homepage_session_id: args.homepageSessionId,
    },
  })

export const getHomepageTab = (args: apiTypes.GetHomepageTabPageArgs) =>
  api.get<apiTypes.HomepageTabResp>(`/homepage/${args.tabName}`, {
    params: {
      next_page_token: args.nextPageToken,
      homepage_session_id: args.homepageSessionId,
      version: 3,
    },
  })

export const getHomepageReference = (name: string, args: apiTypes.GetHomepageReferenceArgs) =>
  api.get<apiTypes.HomepageBlocksResp>(`/homepage/blocks/${name}`, {
    params: {
      homepage_session_id: args.homepageSessionId,
    },
  })

export const getPromoBox = () => api.get<PromoBoxResp>('/homepage/promo_box')

export const prepareClosetPromotionOrder = () =>
  api.post<apiTypes.PromotedClosetOrderResp>('/promoted_closets/orders/prepare', undefined, {
    headers: moneyObjectHeader,
  })

export const getClosetPromotions = (args: apiTypes.GetClosetPromotionArgs) =>
  api.get<apiTypes.PromotedClosetsListResp>('/promoted_closets', {
    params: transformers.getClosetPromotionsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getUserItems = (args: apiTypes.GetUserItemsArgs) =>
  api.get<apiTypes.GetUserItemsResp>(`/users/${args.userId}/items`, {
    params: transformers.getUserItemsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getWardrobeItems = (args: apiTypes.GetUserItemsArgs) =>
  api.get<apiTypes.GetWardrobeItemsResp>(`/wardrobe/${args.userId}/items`, {
    params: transformers.getUserItemsArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getUserBundleItems = ({
  userId,
  selectedItemId,
  currentPage,
  perPage,
}: apiTypes.GetUserBundleItemsArgs) =>
  api.get<apiTypes.UserBundleItemsResp>(`/users/${userId}/bundle_items`, {
    params: { page: currentPage + 1, per_page: perPage, selected_item_id: selectedItemId },
    headers: moneyObjectHeader,
  })

type createBundleSummaryArgs = {
  itemIds: Array<number>
  sellerId: number
}

export const createBundleSummary = ({ itemIds, sellerId }: createBundleSummaryArgs) =>
  api.post<apiTypes.BundleSummaryResp>(
    '/bundle_summary',
    {
      item_ids: itemIds,
      seller_id: sellerId,
    },
    {
      headers: moneyObjectHeader,
    },
  )

export const getBundleOrderReview = ({ itemIds, sellerId }: apiTypes.BundleOrderReviewArgs) =>
  api.post<apiTypes.GetBundleOrderReviewResp>('/bundle_order_review', {
    item_ids: itemIds,
    seller_id: sellerId,
  })

export const createBundleTransaction = ({
  selectedItemIds,
  bundleTrackingId,
  bundleShippingType,
}: apiTypes.CreateBundleTransactionArgs) =>
  api.post<apiTypes.CreateBundleTransactionResp>(
    '/bundles',
    {
      item_ids: selectedItemIds,
      bundle_id: bundleTrackingId,
      bundle_shipping_type: bundleShippingType,
    },
    { headers: moneyObjectHeader },
  )

export const editBundleTransaction = ({
  transactionId,
  selectedItemIds,
  bundleTrackingId,
  bundleShippingType,
}: apiTypes.EditBundleTransactionArgs) =>
  api.put<apiTypes.CreateBundleTransactionResp>(
    `/transactions/${transactionId}/bundle`,
    {
      item_ids: selectedItemIds,
      bundle_id: bundleTrackingId,
      bundle_shipping_type: bundleShippingType,
    },
    { headers: moneyObjectHeader },
  )

export const updateUserBundleDiscounts = (args: apiTypes.UpdateUserBundleDiscountsArgs) =>
  api.put(
    `/users/${args.userId}/multitier_bundle_discount`,
    transformers.updateUserBundleDiscountsArgsToParams(args),
  )

export const getSortedSavedSearches = (userId: number) =>
  api.get<apiTypes.GetSortedSavedSearchesResp>(`/users/${userId}/searches/sorted`, {
    headers: moneyObjectHeader,
  })

export const getSavedSearch = ({ userId, id }: apiTypes.GetSavedSearchArgs) =>
  api.get<apiTypes.GetSavedSearchResp>(`/users/${userId}/searches/${id}`, {
    headers: moneyObjectHeader,
  })

export const createSavedSearch = ({ userId, search }: apiTypes.CreateSavedSearchArgs) =>
  api.post<apiTypes.SavedSearchResp>(
    `/users/${userId}/searches`,
    {
      search,
    },
    { headers: moneyObjectHeader },
  )

export const updateSavedSearch = ({
  id,
  userId,
  search,
  keepLastVisitTime,
}: apiTypes.UpdateSavedSearchArgs) =>
  api.put<apiTypes.SavedSearchResp>(
    `/users/${userId}/searches/${id}`,
    {
      search,
      keep_last_visit_time: !!keepLastVisitTime,
    },
    { headers: moneyObjectHeader },
  )

export const getSearchSuggestions = ({ query }: apiTypes.GetSearchSuggestionsArgs) =>
  api.get<apiTypes.SearchSuggestionsResp>('/search_suggestions', {
    params: { query },
  })

export const getInboxConversations = ({
  currentPage,
  perPage,
}: apiTypes.GetInboxConversationsArgs) =>
  api.get<apiTypes.InboxConversationsResp>('/inbox', {
    params: omitBy(
      {
        page: currentPage,
        per_page: perPage,
      },
      isNil,
    ),
  })

export const getInboxListItem = ({ conversationId }: apiTypes.GetInboxListItemArgs) =>
  api.get<apiTypes.InboxListItemResp>(`/inbox/${conversationId}`)

export const setConversationAsRead = ({ conversationId }: apiTypes.SetConversationAsReadArgs) =>
  api.put<apiTypes.ApiResponse>(`/conversations/${conversationId}/mark_as_read`)

export const translateConversation = ({
  conversationId,
  translate,
}: apiTypes.TranslateConversationArgs) =>
  api.put<apiTypes.TranslateConversationResp>(`/conversations/${conversationId}/translation`, {
    translate,
  })

export const getUploadAnotherItemTip = (itemId: number) =>
  api.get<apiTypes.UploadAnotherItemTipResp>('/item_upload/upload_another_item_tip', {
    params: { item_id: itemId },
  })

export const getBanners = ({ disableCache = false }: apiTypes.GetBannersArgs = {}) =>
  api.get<apiTypes.BannersResp>('/banners', {
    headers: disableCache ? noCacheHeader : {},
  })

export const getSellerBadges = () => api.get<apiTypes.SellerBadgesResp>('/seller_badges')

export const getSellerBadgesList = (userId: number) =>
  api.get<apiTypes.SellerBadgesListResp>(`/seller_badges_list/${userId}`)

export const getItem = (args: apiTypes.GetItemArgs) =>
  api.get<apiTypes.ItemResp>(`/items/${args.id}`, {
    params: { localize: args.localize || false },
    headers: moneyObjectHeader,
  })

export const getReserveItemDetails = ({ id }: apiTypes.GetReserveItemDetailsArgs) =>
  api.get<apiTypes.ReserveItemDetailsResp>(`/reserve_item_details/${id}`)

export const getMarkAsSoldItemDetails = ({ id }: apiTypes.GetMarkAsSoldItemDetailsArgs) =>
  api.get<apiTypes.MarkAsSoldItemDetailsResp>(`/mark_as_sold_item_details/${id}`)

export const getPluginsByFeature = ({
  itemId,
  feature,
  params,
}: apiTypes.GetPluginsByFeatureArgs) =>
  api.get<apiTypes.PluginsByFeatureResponse>(`/items/${itemId}/plugins/${feature}`, { params })

export const getItemsByPluginName = ({ itemId, name }: apiTypes.GetItemsByPluginNameArgs) =>
  api.get<apiTypes.ItemsByPluginNameResponse>(`/items/${itemId}/plugins/items`, {
    params: { name },
  })

export const getItemEditDeprecated = ({ id, additionalHeaders }: apiTypes.GetItemEditArgs) =>
  api.get<apiTypes.ItemEditResp>(`/item_edits/${id}`, { headers: { ...additionalHeaders } })

export const getItemEdit = ({ id, additionalHeaders }: apiTypes.GetItemEditArgs) =>
  api.get<apiTypes.ItemEditResp>(`/item_upload/items/${id}`, { headers: { ...additionalHeaders } })

export const getPackageSizes = ({ catalogId }: apiTypes.GetPackageSizesArgs) =>
  api.get<apiTypes.PackageSizesResp>(`/catalogs/${catalogId}/package_sizes`)

export const getCustomShipmentConfig = () =>
  api.get<apiTypes.CustomShipmentConfigResp>('/configurations/custom_shipment', {
    headers: moneyObjectHeader,
  })

export const getTransactionPackageSizes = ({
  transactionId,
}: apiTypes.GetTransactionPackageSizesArgs) =>
  api.get<apiTypes.PackageSizesResp>(`/transactions/${transactionId}/package_sizes`)

export const selectTransactionPackageSize = ({
  transactionId,
  packageSizeId,
  customShipmentPrice,
}: apiTypes.SelectTransactionPackageSizeArgs) =>
  api.put(`/transactions/${transactionId}/package_size`, {
    package_size_id: packageSizeId,
    custom_shipment_price: customShipmentPrice,
  })

export const getLanguages = requests.getLanguagesRequest(api)

export const getCountryLanguages = (countryId: number) =>
  api.get<apiTypes.GetCountryLanguagesResp>(`countries/${countryId}/languages`)

export const updateUserLanguage = ({ userId, locale }: apiTypes.UpdateUserLanguageArgs) =>
  api.put(`users/${userId}/language_settings/?locale=${locale}`)

export const postReply = ({ conversationId, body, photoTempUuids }: apiTypes.PostReplyArgs) =>
  api.post<apiTypes.ConversationResp, apiTypes.PostReplyNewError>(
    `conversations/${conversationId}/replies`,
    {
      reply: { body, photo_temp_uuids: photoTempUuids },
    },
  )

export const createConversationThread = ({
  recipientId,
  photoTempUuids,
  itemId,
}: apiTypes.CreateNewConversationArgs) =>
  api.post<apiTypes.CreateConversationResp, apiTypes.CreateConversationError>('/conversations', {
    opposite_user_id: recipientId,
    photo_temp_uuids: photoTempUuids,
    item_id: itemId,
  })

export const createItemConversationThread = ({
  itemId,
  initiator,
  receiverId,
}: apiTypes.CreateItemConversationArgs) =>
  api.post<apiTypes.CreateItemConversationResp>('/conversations', {
    initiator,
    item_id: itemId,
    opposite_user_id: receiverId,
  })

export const toggleUserFavourite = ({ entityId }: apiTypes.ToggleUserFavouriteArgs) =>
  api.post('/user_favourites/toggle', {
    type: 'item',
    user_favourites: [entityId],
  })

export const toggleBrandFollow = ({ brandId }: apiTypes.ToggleBrandFollowArgs) =>
  api.post<apiTypes.ToggleBrandFollowResp>('/brands/toggle_follow', {
    brand_id: brandId,
  })

export const toggleUserFollow = ({ userId }: apiTypes.ToggleUserFollowArgs) =>
  api.post<apiTypes.ToggleUserFollowResp>('/followed_users/toggle', { user_id: userId })

export const getFaqEntries = () => api.get<apiTypes.FaqEntriesResp>('/faq_entries')

export const getFaqNotLoggedInEntries = () =>
  api.get<apiTypes.FaqNotLoggedInEntriesResp>('/faq_entries/not_logged_in')

export const getFaqEntryByType = requests.getFaqEntryByCodeRequest(api)

export const getFaqEntryById = requests.getFaqEntryByIdRequest(api)

export const searchFaqEntries = requests.getFaqEntriesSearchRequest(api)

export const getFaqEntryRecommendedFaqs = (faqId: string, accessChannel: string, locale?: string) =>
  api.get<apiTypes.FaqEntryRecommendedFaqsResp>(
    `faq_entries/${faqId}/recommended_faqs?access_channel=${accessChannel}${
      locale ? `&locale=${locale}` : ''
    }`,
  )

export const getFaqEntryRelatedFaqs = (faqId: string, accessChannel: string, locale?: string) =>
  api.get<apiTypes.FaqEntryRelatedFaqsResp>(
    `faq_entries/${faqId}/related_faqs?access_channel=${accessChannel}${
      locale ? `&locale=${locale}` : ''
    }`,
  )

export const getInfoBox = () => api.get<apiTypes.InfoBoxResp>('faq_entries/faq_infobox')

export const requestEmailChange = (userId: number) =>
  api.put(`/users/${userId}/request_email_change`)

export const getUserFeedback = ({
  userId,
  filter,
  page,
  perPage,
  feedbackFilterType,
}: apiTypes.GetUserFeedbackArgs) =>
  api.get<apiTypes.FeedbackListResp>('/user_feedbacks', {
    params: omitBy(
      {
        user_id: userId,
        filter,
        page,
        per_page: perPage,
        by: feedbackFilterType,
      },
      isNil,
    ),
  })

export const deleteFeedback = ({ feedbackId }: apiTypes.DeleteFeedbackArgs) =>
  api.delete(`/user_feedbacks/${feedbackId}`)

export const editFeedbackComment = ({ feedbackId, comment }: apiTypes.EditFeedbackCommentArgs) =>
  api.put<apiTypes.FeedbackCommentResp>(`/user_feedbacks/${feedbackId}/comment`, {
    comment,
  })

export const deleteFeedbackComment = ({ feedbackId }: apiTypes.DeleteFeedbackCommentArgs) =>
  api.put<apiTypes.FeedbackCommentResp>(`/user_feedbacks/${feedbackId}/comment`, { comment: null })

export const getFeedback = ({ feedbackId, localize }: apiTypes.GetFeedbackArgs) =>
  api.get<apiTypes.FeedbackResp>(`/user_feedbacks/${feedbackId}`, {
    params: { localize },
  })

export const createUserFeedback = ({
  userId,
  feedback,
  rating,
  transactionId,
}: apiTypes.CreateFeedbackArgs) =>
  api.post<apiTypes.FeedbackResp>('/user_feedbacks', {
    user_id: userId,
    feedback,
    rating,
    transaction_id: transactionId,
  })

export const editUserFeedback = ({
  feedbackId,
  feedback,
  rating,
  feedbackRate,
}: apiTypes.EditUserFeedbackArgs) =>
  api.patch<apiTypes.FeedbackResp>(`/user_feedbacks/${feedbackId}`, {
    feedback,
    rating,
    feedback_rate: feedbackRate,
  })

export const changeEmail = ({ id, email, code, fingerprint }: apiTypes.ChangeEmailArgs) =>
  api.put(`/users/${id}/save_email`, { email, code, fingerprint })

export const getUserNotifications = ({ page, perPage }: apiTypes.GetUserNotificationsArgs) =>
  api.get<apiTypes.GetUserNotificationsResp>('/notifications', {
    params: {
      page,
      per_page: perPage,
    },
  })

export const sendTwoFactorCode = ({
  userId,
  id,
  code,
  fingerprint,
}: apiTypes.TwoFactorSubmitArgs) =>
  api.put(`/users/${userId}/user_2fa/${id}`, {
    code,
    fingerprint,
  })

export const resendTwoFactorCode = ({
  userId,
  id,
  code,
  fingerprint,
}: apiTypes.TwoFactorSubmitArgs) =>
  api.post<apiTypes.TwoFactorResendResp>(`/users/${userId}/user_2fa/${id}/resend`, {
    code,
    fingerprint,
  })

export const resendLoginTwoFactorCode = ({
  controlCode,
  code,
  fingerprint,
}: apiTypes.TwoFactorLoginSubmitArgs) =>
  api.post<apiTypes.TwoFactorResendResp>(`/user_login_2fa/${controlCode}/resend`, {
    code,
    fingerprint,
  })

export const getPhotos = ({ type, photoEntryId, tempUuid }: apiTypes.GetPhotosArgs) =>
  api.get<apiTypes.GetPhotosResp>('/photos', {
    params: { type, photo_entry_id: photoEntryId, temp_uuid: tempUuid },
  })

export const createPhoto = (photo: FormData) => api.post<apiTypes.CreatePhotoResp>('/photos', photo)

export const setBannerAsSeen = ({ type, name }: apiTypes.SetBannerAsSeenArgs) =>
  api.put(`/banners/${type}/seen`, { name })

export const changeExistingEmail = ({ email, userId }: apiTypes.ChangeExistingEmailArgs) =>
  api.put(`/users/${userId}/change_email`, { email })

export const getCreditCards = () => api.get<apiTypes.GetCreditCardsResp>('/payments/credit_cards')

export const getCreditCard = ({ cardId }: apiTypes.CreditCardArgs) =>
  api.get<apiTypes.GetCreditCardResp>(`/payments/credit_cards/${cardId}`)

export const deleteCreditCard = ({ cardId }: apiTypes.CreditCardArgs) =>
  api.delete(`/payments/credit_cards/${cardId}`)

export const deletePhotoSuspicion = (photoId: number) =>
  api.delete(`/user_msg_photos/${photoId}/suspicion`)

export const getCheckoutConfiguration = () =>
  api.get<apiTypes.CheckoutConfigurationResp>('/configurations/checkout')

export const getFavouriteItems = ({ page, perPage, userId }: apiTypes.GetFavouriteItemsArgs) =>
  api.get<apiTypes.FavouriteItemsResp>(`/users/${userId}/items/favourites`, {
    params: {
      page,
      per_page: perPage,
      return_minimised_item_dto: 1,
      return_sold_as_boolean: true,
    },
    headers: moneyObjectHeader,
  })

export const getHomepageBlock = (params: apiTypes.GetHomepageBlockArgs) =>
  api.get<apiTypes.HomepageBlockResp>('homepage/block', {
    params,
  })

export const getHomepageShuffleCardSuggestions = ({
  cardId,
  blockName,
}: apiTypes.GetHomepageShuffleCardSuggestionsArgs) =>
  api.get<apiTypes.HomepageShuffleCardSuggestionsResp>('homepage/shuffle_card', {
    params: {
      card_id: cardId,
      block_name: blockName,
    },
  })

export const updateCurrentUserRealName = ({
  realName,
  target,
}: apiTypes.UpdateCurrentUserRealNameArgs) =>
  api.put('/users/real_name', {
    user: {
      real_name: realName,
    },
    target,
  })

export const getOfflineVerificationEligibilityCriteria = (catalogId: string) =>
  api.get<apiTypes.GetOfflineVerificationEligibiltyCriteriaResp>(
    `/offline_verification/criteria/${catalogId}`,
  )

export const offlineVerificationEligibilityCheck = (
  itemAttributes: Array<{
    field_name: string
    value: string | number | Array<number> | Record<string, number>
  }>,
) =>
  api.post<apiTypes.OfflineVerificationElgibilityCheckResp>('/offline_verification/eligibility', {
    item_attributes: itemAttributes,
  })

export const getIsSecondDayLister = () =>
  api.get<apiTypes.GetTrackerAttributesResp>('/tracker_attributes', {
    params: {
      type: 'second_day_lister',
    },
  })

export const getIsPreviousLister = () =>
  api.get<apiTypes.GetTrackerAttributesResp>('/tracker_attributes', {
    params: {
      type: 'previous_lister',
    },
  })

export const getItemPriceSuggestions = ({
  brandId,
  statusId,
  catalogId,
}: apiTypes.GetItemPriceSuggestionsArgs) =>
  api.get<apiTypes.PriceSuggestionsResp>('/item_price_suggestions', {
    params: {
      brand_id: brandId,
      status_id: statusId,
      catalog_id: catalogId,
    },
  })

export const getBumpOptions = ({ itemIds }: apiTypes.GetBumpOptionsArgs) =>
  api.get<apiTypes.BumpOptionResp>('/push_ups/options', {
    params: { item_ids: itemIds },
    headers: moneyObjectHeader,
  })

export const prepareBumpOrder = (
  itemIds: Array<number>,
  effectiveDays: number,
  international?: boolean,
) =>
  api.post<apiTypes.PrepareBumpOrderResp>(
    '/push_ups/orders',
    {
      item_ids: itemIds,
      effective_days: effectiveDays,
      international,
    },
    { headers: moneyObjectHeader },
  )

export const getBumpMinimumPrice = () =>
  api.get<apiTypes.BumpMinimumPriceResp>('/push_ups/minimum_price', {
    headers: moneyObjectHeader,
  })

export const getUserSizes = () => api.get<apiTypes.UserSizesResp>('/user_sizes')

export const updateUserSizes = (sizeIds: Array<number>) =>
  api.post('/user_sizes', {
    size_ids: sizeIds,
  })

export const getSimilarSoldItemsDeprecated = ({
  brandId,
  statusId,
  catalogId,
}: apiTypes.GetSimilarSoldItemsArgs) =>
  api.get<apiTypes.GetSimilarSoldItemsRespDeprecated>('/items/similar_sold_items', {
    params: {
      brand_id: brandId,
      status_id: statusId,
      catalog_id: catalogId,
    },
    headers: moneyObjectHeader,
  })

export const getSimilarSoldItems = ({
  brandId,
  statusId,
  catalogId,
}: apiTypes.GetSimilarSoldItemsArgs) =>
  api.get<apiTypes.GetSimilarSoldItemsResp>('/item_upload/items/similar_sold_items', {
    params: { brand_id: brandId, status_id: statusId, catalog_id: catalogId },
    headers: moneyObjectHeader,
  })

export const getTransaction = ({ id }: apiTypes.GetTransactionArgs) =>
  api.get<apiTypes.GetTransactionResp>(`/transactions/${id}`, { headers: moneyObjectHeader })

export const getCheckoutData = (transactionId: number) =>
  api.get<apiTypes.UpdateCheckoutDataResp>(`/transactions/${transactionId}/checkout`, {
    headers: moneyObjectHeader,
  })

export const updateCheckoutData = (
  transactionId: number,
  args: apiTypes.UpdateCheckoutDataArgs | null,
) =>
  api.put<apiTypes.UpdateCheckoutDataResp>(
    `/transactions/${transactionId}/checkout`,
    args && transformers.updateCheckoutDataArgsToParams(args),
    { headers: moneyObjectHeader },
  )

export const initiateSingleCheckout = ({ id, type }: apiTypes.InitiateSingleCheckoutArgs) =>
  api.post<apiTypes.SingleCheckoutResp>('/purchases/checkout/build', {
    purchase_items: [{ id: Number(id), type }],
  })

export const getSingleCheckoutData = (id: string) =>
  api.get<apiTypes.SingleCheckoutResp>(`/purchases/${id}/checkout`)

export const updateSingleCheckoutData = (
  id: string,
  args: apiTypes.UpdateSingleCheckoutArgs | undefined,
) =>
  api.put<apiTypes.SingleCheckoutResp>(
    `/purchases/${id}/checkout`,
    args && transformers.updateSingleCheckoutDataArgsToParams(args),
  )

export const refreshSingleCheckoutPurchase = (id: string) =>
  api.put<apiTypes.SingleCheckoutResp>(`/purchases/${id}/checkout`, { components: [] })

export const initiateSingleCheckoutPayment = (
  id: string,
  args: apiTypes.InitiateSingleCheckoutPaymentArgs,
) =>
  api.post<apiTypes.SingleCheckoutPaymentResp>(
    `purchases/${id}/checkout/payment`,
    transformers.singleCheckoutPaymentInitiationArgsToParams(args),
  )

export const getSingleCheckoutPayment = (id: string) =>
  api.get<apiTypes.SingleCheckoutPaymentResp>(`purchases/${id}/checkout/payment`)

export const updateSingleCheckoutPaymentData = (
  id: string,
  args: apiTypes.UpdateSingleCheckoutPaymentDataArgs,
) =>
  api.put<apiTypes.SingleCheckoutPaymentResp>(
    `purchases/${id}/checkout/payment`,
    transformers.escrowPaymentDataUpdateArgsToParams(args),
  )

export const updateSingleCheckoutPaymentFailure = (id: string) =>
  api.post<apiTypes.ApiResponse>(`purchases/${id}/checkout/payment/failure`)

export const getPaymentMethods = (id: string) =>
  api.get<apiTypes.PayInMethodsResp>(`/purchases/${id}/checkout/payment_methods`)

export const getEscrowPayment = (transactionId: number) =>
  api.get<apiTypes.EscrowPaymentResp>(`/transactions/${transactionId}/checkout/payment`)

export const initiateEscrowPayment = (
  transactionId: number,
  args: apiTypes.InitiateEscrowPaymentArgs,
) =>
  api.post<apiTypes.EscrowPaymentResp>(
    `/transactions/${transactionId}/checkout/payment`,
    transformers.escrowPaymentInitiationArgsToParams(args),
  )

export const updateEscrowPaymentData = (
  transactionId: number,
  args: apiTypes.UpdateEscrowPaymentDataArgs,
) =>
  api.put<apiTypes.EscrowPaymentResp>(
    `/transactions/${transactionId}/checkout/payment`,
    transformers.escrowPaymentDataUpdateArgsToParams(args),
  )

export const getBookDetails = ({ isbn }: apiTypes.GetBookDetailsArgs) =>
  api.get<apiTypes.BookDetailsResp>('/book_details', {
    params: {
      isbn,
    },
  })

export const resolveComplaint = (id: string) => api.post(`/complaints/${id}/resolve`)

export const getUserItemFacets = ({ userId }: apiTypes.GetUserItemFacetsArgs) =>
  api.get<apiTypes.ItemFacetsResp>(`/users/${userId}/item_facets`)

export const reuploadItem = (id: number) =>
  api.post<apiTypes.ReuploadItemResp>(`/transactions/${id}/reupload`)

export const confirmCancellationAgreement = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/cancellation_agreement/confirm`)

export const declineCancellationAgreement = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/cancellation_agreement/decline`)

export const markAsDelivered = (shipmentId: number) =>
  api.put(`/shipments/${shipmentId}/mark_as_delivered`)

export const getOfflineVerificationFeedback = (transactionId: number) =>
  api.get<apiTypes.GetOfflineVerificationFeedbackResp>(
    `/transactions/${transactionId}/verification_feedbacks`,
  )

export const sendOfflineVerificationFeedback = (
  args: apiTypes.SendOfflineVerificationFeedbackArgs,
) =>
  api.put(
    `/transactions/${args.transactionId}/verification_feedbacks`,
    transformers.sendOfflineVerificationFeedbackArgsToParams(args),
  )

export const getCurrentUser = requests.getCurrentUserRequest(api)

export const updateCurrentUser = (args: apiTypes.UpdateCurrentUserArgs) =>
  api.put<apiTypes.UserResp>(
    CURRENT_USER_API_ENDPOINT,
    transformers.updateCurrentUserArgsToParams(args),
  )

export const updateCurrentUserSettings = (args: apiTypes.UpdateCurrentUserSettingsArgs) =>
  api.put<apiTypes.UserResp>(CURRENT_USER_API_ENDPOINT, {
    user: {
      user_settings: transformers.updateCurrentUserSettingsArgsToParams(args),
    },
  })

export const updateBusinessAccountProfileDetails = (
  args: apiTypes.UpdateBusinessAccountProfileDetailsArgs,
) =>
  api.put<apiTypes.BusinessAccountResp>(
    `/business_accounts/${args.businessAccountId}/profile`,
    transformers.updateBusinessAccountProfileDetailsArgsToParams(args),
  )

export const updateBusinessAccountRepresentativeDetails = ({
  identityDocumentExpirationDate,
  email,
}: apiTypes.UpdateBusinessAccountRepresentativeDetailsArgs) =>
  api.put('/business_accounts_dsa/representatives', {
    identity_document_expiration_date: identityDocumentExpirationDate,
    email,
  })

export const getBusinessAccountRepresentativeDetails = () =>
  api.get<apiTypes.GetBusinessAccountRepresentativeDetailsResp>(
    '/business_accounts_dsa/representatives',
  )

export const updateEprUin = ({ uinId, uinNumber }: apiTypes.UpdateEprUinArgs) =>
  api.put(`/business_accounts/epr_uins/${uinId}`, {
    epr_uin: {
      uin: uinNumber,
    },
  })

export const deleteEprUin = (uinId: number | string) =>
  api.delete(`/business_accounts/epr_uins/${uinId}`)

export const markAsShipped = ({ transactionId, proofPhotoUuid }: apiTypes.MarkAsShippedArgs) =>
  api.put(`/transactions/${transactionId}/shipment/mark_as_shipped`, {
    proof_photo_uuid: proofPhotoUuid,
  })

export const partiesHaveMet = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/parties_have_met`)

export const getCities = (args: apiTypes.FetchCitiesArgs) =>
  api.get<apiTypes.CitiesResp>('/countries/cities', {
    params: {
      country_id: args.countryId,
      search_text: args.searchText,
    },
  })

export const getSddEducationInfo = () =>
  api.get<apiTypes.KycEducationInfoResp>('/payments/identity_simplified/education')

export const getSddFormConfiguration = () =>
  api.get<apiTypes.SddFormConfigurationResp>('/payments/identity_simplified')

export const getSddStatus = () =>
  api.get<apiTypes.SddStatusResp>('payments/identity_simplified/status')

export const submitSimplifiedPaymentsIdentity = (
  args: apiTypes.SubmitSimplifiedPaymentsIdentityArgs,
) =>
  api.post<apiTypes.SddMethodsConfigurationResp>('/payments/identity_simplified', {
    payments_identity_simplified: args,
  })

export const submitVerificationMethod = (id: string) =>
  api.post<apiTypes.SddMethodSelectionResp>('payments/identity_simplified/start_verification', {
    method: { id },
  })

export const getPaymentsIdentityFormConfiguration = () =>
  api.get<apiTypes.PaymentsIdentityFormConfigurationResp>('/payments/identity/edit')

export const getBusinessAccountsTermsOfUseContent = () =>
  api.get<apiTypes.BusinessAccountsTermsOfUseResp>('/pro_terms_of_use')

export const getPrivateBetaTermsOfUseContent = () =>
  api.get<apiTypes.BusinessAccountsTermsOfUseResp>('/private_beta_terms_of_use')

export const getProTermsAndConditionsContent = () =>
  api.get<apiTypes.BusinessAccountsTermsOfUseResp>('/pro_terms_and_conditions')

export const getProIntegrationsTermsAndConditionsContent = () =>
  api.get<apiTypes.BusinessAccountsTermsOfUseResp>('/pro_integrations_terms_and_conditions')

export const deleteConversationSuspicion = (threadId: number | string) =>
  api.delete(`/msg_threads/${threadId}/suspicion`)

export const getComplaint = (id: string) => api.get<apiTypes.ComplaintResp>(`/complaints/${id}`)

export const getSnadComplaint = (transactionId: number) =>
  api.get<apiTypes.SnadComplaintResp>('/complaints/snad', {
    params: { transaction_id: transactionId },
  })

export const getRefundOfferCreationDetails = (complaintId: number) =>
  api.get<RefundOfferCreationDetailsDto>(`/complaints/${complaintId}/refund_offer_creation_details`)

export const getRefundOfferId = (complaintId: number) =>
  api.get<RefundOfferIdDto>(`/complaints/${complaintId}/refund_offer_id`)

export const sendRefundOffer = ({ complaintId, refundOffer }: apiTypes.SendRefundOfferArgs) =>
  api.post<apiTypes.SendRefundOfferResp>('/refund_offers', {
    complaint_id: complaintId,
    refund_offer: { amount: refundOffer.amount, currency_code: refundOffer.currencyCode },
  })

export const handleRefundOffer = ({ refundOfferId, action }: apiTypes.HandleRefundOfferAction) =>
  api.post(`/refund_offers/${refundOfferId}/${action}`)

export const getRefundOfferEstimateDetails = (refundOfferId: number) =>
  api.get<apiTypes.GetRefundOfferEstimateDetailsResp>(
    `/refund_offers/${refundOfferId}/refund_estimate`,
  )

export const getSecuritySettings = (userId: number) =>
  api.get<apiTypes.SecuritySettingsResp>(`/users/${userId}/security`)

export const setVoluntary2FA = (userId: number, isVoluntary2FA: boolean) =>
  api.put(`users/${userId}/security/voluntary_2fa`, {
    is_voluntary_2fa: isVoluntary2FA,
  })

export const createUser2FA = (args: apiTypes.CreateUser2FAArgs) =>
  api.post<apiTypes.CreateUser2FAResp>(`/users/${args.userId}/user_2fa`, {
    entity_id: args.entityId,
    type: args.entityType,
    fingerprint: args.fingerprint,
  })

export const submitPaymentsIdentity = (args: apiTypes.SubmitPaymentsIdentityArgs) =>
  api.put<apiTypes.PaymentsIdentityFormConfigurationResp>('/payments/identity', {
    payments_identity: transformers.submitPaymentsIdentityArgsToParams(args),
  })

export const addAddress = (args: apiTypes.AddUserAddressArgs) =>
  api.post<apiTypes.UserAddressResp>('/user_addresses', {
    user_address: transformers.addUserAddressArgsToParams(args),
    is_single_checkout: args.isSingleCheckout,
  })

export const getTaxAddress = () =>
  api.get<apiTypes.GetTaxAddressResp>('/user_addresses/missing_info')

export const addShippingAddress = (args: apiTypes.AddShippingAddressArgs) =>
  api.post<apiTypes.UserAddressResp>(`/transactions/${args.transactionId}/shipping_destination`, {
    user_address: transformers.addShippingAddressArgsToParams(args),
  })

export const getDefaultBillingAddress = () =>
  api.get<apiTypes.UserAddressResp>('/user_addresses/default_billing_address')

export const addUserAddressesMissingInfo = (
  user_address: apiTypes.AddUserAddressesMissingInfoArgs,
) =>
  api.post<apiTypes.UserAddressResp>('/user_addresses/missing_info', {
    user_address,
  })

export const getDefaultShippingAddress = () =>
  api.get<apiTypes.DefaultShippingAddressResp>('/user_addresses/default_shipping_address')

export const getInfoBanner = ({ screen, params }: apiTypes.GetInfoBannerArgs) =>
  api.get<apiTypes.InfoBannerResp>(`/info_banners/${screen}`, { params })

export const createPhoneNumber = (userId: number, phoneNumber: string) =>
  api.post<apiTypes.NewPhoneNumberResp>(`/users/${userId}/phone_number`, {
    phone_number: {
      number: phoneNumber,
    },
  })

export const changePhoneNumber = ({
  userId,
  currentNumber,
  newNumber,
  fingerprint,
}: apiTypes.ChangePhoneNumberArgs) =>
  api.post<apiTypes.NewPhoneNumberResp>(`/users/${userId}/phone_number/change`, {
    current_phone: currentNumber,
    new_phone: newNumber,
    fingerprint,
  })

export const verifyPhoneNumber = ({
  userId,
  code,
  phoneNumber,
  fingerprint,
}: {
  userId: number
  code: string
  phoneNumber: string
  fingerprint: string
}) =>
  api.put(`/users/${userId}/phone_number/verify`, {
    code,
    phone_number: phoneNumber,
    fingerprint,
  })

export const getReferralRegistrations = () =>
  api.get<apiTypes.ReferralRegistrationsResp>('/referrals/registrations')

export const getReferralInviteInstructions = () =>
  api.get<apiTypes.ReferralInviteInstructionsResp>('/referrals/invite_instructions')

export const getReferralShare = () => api.get<apiTypes.ReferralShareResp>('/referrals/share/new')

export const getAppLinks = (url: string) =>
  api.get<apiTypes.AppLinksResponse>('/app_links', {
    params: {
      ids: url,
    },
  })

export const getCountryBounds = (countryId: number) =>
  api.get<apiTypes.CountryBoundsResponse>(`/countries/${countryId}/bounds`)

export const getWalletBalance = (userId: number) =>
  api.get<apiTypes.WalletBalanceResponse>(`users/${userId}/balance`, { headers: moneyObjectHeader })

export const getItemSuggestions = ({
  title,
  description,
  catalogId,
  photoIds,
  isMultipleSizeGroupsEnabled,
}: apiTypes.GetItemSuggestionsArgs) =>
  api.get<apiTypes.ItemSuggestionsResponse>('items/suggestions', {
    params: {
      title,
      description,
      catalog_id: catalogId,
      photo_ids: photoIds.join(','),
    },
    headers: isMultipleSizeGroupsEnabled ? multipleSizeGroupsHeader : {},
  })

export const getTrackingJourneySummary = ({
  transactionId,
}: apiTypes.GetTrackingJourneySummaryArgs) =>
  api.get<apiTypes.TrackingJourneySummaryResponse>(
    `transactions/${transactionId}/shipment/journey_summary`,
  )

export const getShipmentInstructions = ({ transactionId }: apiTypes.GetShipmentInstructionsArgs) =>
  api.get<apiTypes.ShipmentInstructionsResponse>(
    `transactions/${transactionId}/shipment_instructions`,
    { headers: moneyObjectHeader },
  )

export const getPostalCodeConfiguration = ({ code, countryId }: apiTypes.PostCodeArgs) =>
  api.get<apiTypes.PostCodeResponse>(`postal_codes/${code}`, {
    params: {
      country_id: countryId,
    },
  })

export const orderShippingLabel = ({
  sellerAddressId,
  transactionId,
  pickUpDate,
  digitalLabelType,
  dropOffTypeKey,
}: apiTypes.OrderShippingLabelArgs) =>
  api.put<apiTypes.OrderShippingLabelResp>(`transactions/${transactionId}/shipment/order`, {
    seller_address_id: sellerAddressId,
    pick_up_date: pickUpDate,
    digital_label_type: digitalLabelType,
    drop_off_type_key: dropOffTypeKey,
  })

export const linkFacebook = (accessToken: string) =>
  api.post('facebook_user/link', {
    fb_access_token: accessToken,
  })

export const unlinkFacebook = () => api.delete('facebook_user')

export const linkGoogle = (idToken: string) =>
  api.post('google_user/link', {
    id_token: idToken,
  })

export const unlinkGoogle = () => api.delete('google_user')

export const createPaymentsAccount = (account: apiTypes.CreatePaymentsAccountArgs) =>
  api.post<apiTypes.PaymentsAccountResp>(
    `/users/${account.userId}/payments_account`,
    transformers.createPaymentsAccountArgsToParams(account),
  )

export const startOnboarding = (userId: number) =>
  api.put(`/users/${userId}/payments_account/start_onboarding`)

export const getPaymentsAccount = (userId: number) =>
  api.get<apiTypes.PaymentsAccountResp>(`/users/${userId}/payments_account`)

export const getCurrentWalletInvoices = () =>
  api.get<apiTypes.WalletInvoicesResp>('/wallet/invoices/current', {
    headers: moneyObjectHeader,
  })

export const getHistoricalWalletInvoices = (year: number, month: number) =>
  api.get<apiTypes.WalletInvoicesResp>(`/wallet/invoices/${year}/${month}`, {
    headers: moneyObjectHeader,
  })

export const createPayout = (args: apiTypes.CreatePayoutArgs) =>
  api.post<apiTypes.CreatePayoutResp>(
    `/users/${args.userId}/payouts`,
    transformers.createPayoutArgsToParams(args),
  )

export const getPayoutConfiguration = (userId: number) =>
  api.get<apiTypes.GetPayoutConfigurationResp>(`/users/${userId}/payouts/new`)

export const getPayout = (userId: number, payoutId: number) =>
  api.get<apiTypes.PayoutResp>(`/users/${userId}/payouts/${payoutId}`)

export const getRefund = (transactionId: number) =>
  api.get<apiTypes.RefundResp>(`/transactions/${transactionId}/refund`)

export const getBankAccountRegistration = () =>
  api.post<apiTypes.BankAccountRegistration>('/payments/bank_account_registrations/prepare')

export const getBankAccounts = () => api.get<apiTypes.BankAccountsResp>('/bank_accounts')

export const addBankAccount = (bankAccount: apiTypes.AddBankAccountArgs, fingerprint: string) =>
  api.post<apiTypes.AddBankAccountResponse>('/bank_accounts', {
    bank_account: transformers.addBankAccountArgsToParams(bankAccount),
    fingerprint,
  })

export const deleteBankAccount = ({
  bankAccountId,
  fingerprint,
}: {
  bankAccountId: number
  fingerprint: string
}) => api.delete(`/bank_accounts/${bankAccountId}?fingerprint=${fingerprint}`)

export const validateUser = ({ user }: apiTypes.ValidateUserArgs) =>
  api.post('/users/validations', { user })

export const validateBusinessUboAddress = (business_ubo_address: BusinessAddressDto) =>
  api.post<apiTypes.BusinessUboAddressResp>('/user_addresses/validate_business_ubo_address', {
    business_ubo_address,
  })

export const validateBusinessRegistration = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts/validate', registration)

export const validateBusinessRegistrationDsa = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts_dsa/validate', registration)

export const submitBusinessRegistration = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts', registration)

export const submitBusinessRegistrationDsa = (registration: BusinessRegistrationDto) =>
  api.post('/business_accounts_dsa', registration)

export const deleteBusinessRegistration = (userId: number) =>
  api.delete(`/business_accounts/${userId}`)

export const addEprInfo = (eprInfo: EprInfoDto) => api.post('/business_accounts/epr_uins', eprInfo)

export const getEprCategories = () =>
  api.get<apiTypes.EprCategoriesResp>('/business_accounts/epr_uins/categories')

export const getEprCountries = () =>
  api.get<apiTypes.EprCountriesResp>('/business_accounts/epr_uins/allowed_countries')

export const getEprUinList = () => api.get<apiTypes.EprUinListResp>('/business_accounts/epr_uins')

export const getBusinessAccountPolicies = ({
  businessAccountId,
}: apiTypes.GetBusinessAccountPoliciesArgs) =>
  api.get<apiTypes.BusinessAccountPoliciesResp>(`/business_accounts/${businessAccountId}/terms`)

export const setBusinessAccountPolicies = (args: apiTypes.SetBusinessAccountPoliciesArgs) =>
  api.post<apiTypes.BusinessAccountPoliciesResp>(
    `/business_accounts/${args.businessAccountId}/terms`,
    transformers.setBusinessAccountPoliciesArgsToParams(args),
  )

export const getBusinessAccountAddresses = ({
  businessAccountId,
}: apiTypes.GetBusinessAccountAddressesArgs) =>
  api.get<apiTypes.BusinessAccountAddressesResp>(
    `/business_accounts/${businessAccountId}/addresses`,
  )

export const setBusinessAccountAddresses = ({
  businessAccountId,
  ...args
}: apiTypes.SetBusinessAccountAddressesArgs) =>
  api.post<apiTypes.BusinessAccountAddressesResp>(
    `/business_accounts/${businessAccountId}/addresses`,
    transformers.setBusinessAccountAddressesArgsToParams(args),
  )

export const enterTrackingCode = ({
  transactionId,
  trackingCode,
}: apiTypes.EnterTrackingCodeArgs) =>
  api.put<apiTypes.EnterTrackingCodeResp>(
    `/transactions/${transactionId}/shipment/enter_tracking_code`,
    {
      id: transactionId,
      tracking_code: trackingCode,
    },
  )

export const getWorldCountries = () => api.get<apiTypes.WorldCountriesResp>('/world_countries')

export const dismissBanner = (type: keyof BannersDto) => api.post(`/banners/${type}/dismissed`)

export const getItemUploadConditions = ({ catalogId }: apiTypes.ItemUploadConditionsArgs) =>
  api.get<apiTypes.ItemUploadConditionsResp>('/item_upload/conditions', {
    params: { catalog_id: catalogId },
  })

export const getFeedbackProblemOptions = (userId: number, conversationId: number | string) =>
  api.get<apiTypes.FeedbackProblemOptionsResp>(
    `/users/${userId}/msg_threads/${conversationId}/problem_options`,
  )

export const sendFeedbackProblemFeedback = (args: apiTypes.SendFeedbackProblemFeedbackArgs) =>
  api.post<apiTypes.ApiResponse>(
    `/users/${args.userId}/msg_threads/${args.conversationId}/problem_options`,
    {
      problem_option_id: args.problemOptionId,
      message: args.message,
      help_center_session_id: args.helpCenterSessionId,
    },
  )

export const getConversationFeedbackRatingOptions = (
  userId: number,
  conversationId: number | string,
) =>
  api.get<apiTypes.FeedbackRatingOptionsResp>(
    `/users/${userId}/msg_threads/${conversationId}/feedback_ratings`,
  )

export const updateFeedbackRatingOptions = (
  userId: number,
  conversationId: number | string,
  args: apiTypes.UpdateFeedbackRatingOptionsArgs,
) =>
  api.put<apiTypes.ApiResponse>(
    `/users/${userId}/msg_threads/${conversationId}/feedback_ratings`,
    transformers.updateFeedbackRatingOptionsArgsToParams(args),
  )

export const getPayInMethods = (userId: number) =>
  api.get<apiTypes.PayInMethodsResp>(`/users/${userId}/pay_in_methods`)

export const getTransactionPayInMethods = (transactionId: number) =>
  api.get<apiTypes.PayInMethodsResp>(`/transactions/${transactionId}/pay_in_methods`)

export const getExtraServicePayInMethods = () =>
  api.get<apiTypes.PayInMethodsResp>('/extra_services/pay_in_methods')

export const getBusinessAccountInvoiceInstructions = ({
  transactionId,
}: apiTypes.GetBusinessAccountInvoiceInstructionsArgs) =>
  api.get<apiTypes.BusinessAccountInvoiceInstructionsResp>(
    `transactions/${transactionId}/business_account_invoice_instructions`,
  )

export const prepareCreditCardRegistration = ({
  name,
  cardBrand,
}: apiTypes.PrepareCreditCardRegistrationArgs) =>
  api.post<apiTypes.PrepareCreditCardRegistrationResp>(
    '/payments/credit_card_registrations/prepare',
    {
      credit_card_registration: {
        name,
        card_brand: cardBrand,
      },
    },
  )

export const getUserPermissions = requests.getUserPermissionsRequest(api)

export const registerCreditCard = (args: apiTypes.RegisterCreditCardArgs) =>
  api.post<apiTypes.RegisterCreditCardResp>(
    '/payments/credit_cards',
    transformers.registerCreditCardArgsToParams(args),
  )

export const authenticateCreditCard = (args: apiTypes.AuthenticateCreditCardArgs) =>
  api.put<apiTypes.RegisterCreditCardResp>(
    '/payments/credit_cards/authentication_details',
    transformers.authenticateCreditCardArgsToParams(args),
  )
export const getCreditCardRegistration = () =>
  api.get<apiTypes.CreditCardRegistrationResp>('/payments/credit_card_registrations/new')

export const getCreditCardRegistrationBrands = (bin: string) =>
  api.get<apiTypes.CreditCardRegistrationBrandsResp>('/payments/credit_card_registrations/brands', {
    params: { bin },
  })

export const getUsers = ({ perPage, searchText, page, order, itemId }: apiTypes.GetUsersArgs) =>
  api.get<apiTypes.UsersResp>('/users', {
    params: {
      page,
      order,
      per_page: perPage,
      search_text: searchText,
      order_by_buyer_for_item: itemId,
    },
  })

export const getUserFollowers = ({ userId, perPage, page }: apiTypes.GetFollowUsersArgs) =>
  api.get<apiTypes.UsersResp>(`users/${userId}/followers`, {
    params: { per_page: perPage, page },
  })

export const getFollowedUsers = ({ userId, perPage, page }: apiTypes.GetFollowUsersArgs) =>
  api.get<apiTypes.UsersResp>(`users/${userId}/followed_users`, {
    params: { per_page: perPage, page },
  })

export const deleteConversation = (id: number | string) => api.delete(`/conversations/${id}`)

export async function getRecentUsers(keyword?: string) {
  return api.get<apiTypes.RecentUsersResp>('/help_center/recent_users', {
    params: { keyword },
  })
}

export const getRecentTransactions = (count?: number) =>
  api.get<apiTypes.RecentTransactionsResp>('help_center/recent_transactions', {
    params: { count },
    headers: moneyObjectHeader,
  })

export const getRecentItems = () =>
  api.get<apiTypes.RecentItemsResp>('help_center/recent_items', { headers: moneyObjectHeader })

export const getMyOrders = (args: apiTypes.GetMyOrdersArgs) =>
  api.get<apiTypes.MyOrdersResp>('my_orders', {
    params: transformers.getMyOrdersArgsToParams(args),
    headers: moneyObjectHeader,
  })

export const getProfileUserInfo = (userId: number) =>
  api.get<apiTypes.ProfileUserInfoResp>(`users/${userId}/profile`)

export const getTransactionCancellationReasons = (id: number) =>
  api.get<apiTypes.TransactionCancellationReasonsResp>(`/transactions/${id}/cancellation_reasons`)

export const cancelTransaction = ({
  id,
  reasonId,
  userExplanation,
}: apiTypes.CancelTransactionArgs) =>
  api.put(`/transactions/${id}/cancel`, {
    reason: {
      id: reasonId,
      user_explanation: userExplanation,
    },
  })

export const createSupportTicket = (args: apiTypes.CreateSupportTicketArgs) =>
  api.post<apiTypes.SubmitTicketResp, apiTypes.ResponseError>(
    '/help_center/support_tickets',
    transformers.getCreateSupportTicketArgs(args),
  )

export const createComplaint = (args: apiTypes.CreateComplaintArgs) =>
  api.post<apiTypes.SubmitComplaintResp, apiTypes.ResponseError>(
    '/complaints',
    transformers.getCreateComplaintArgs(args),
  )

export const getIsProofGatheringAllowed = (transactionId: number) =>
  api.get<apiTypes.ProofGatheringAllowedResp>(
    `/help_center/proof_gathering_allowed?transaction_id=${transactionId}`,
  )

export const createSupportTicketWithProofGathering = (
  args: apiTypes.CreateSupportTicketWithProofGatheringArgs,
) =>
  api.post<apiTypes.SubmitTicketWithProofGatheringResp, apiTypes.ResponseError>(
    '/help_center/support_tickets/proof_gathering',
    transformers.getCreateSupportTicketsWithProofGatheringArgs(args),
  )
export const getLegalEntity = (legalCode: string) =>
  api.get<apiTypes.LegalEntityResp>('/business_accounts/legal_entity', {
    params: {
      legal_code: legalCode,
    },
  })

export const getLiveChatAvailability = (
  transactionId: number,
): Promise<apiTypes.LiveChatAvailabilityResp | apiTypes.ResponseError> =>
  api.get<apiTypes.LiveChatAvailabilityResp, apiTypes.ResponseError>(
    `/help_center/live_chat_status?transaction_id=${transactionId}`,
  )

export const createLiveChatTicket = (args: apiTypes.createLiveChatTicketArgs) =>
  api.post<apiTypes.CreateLiveChatTicketResp, apiTypes.ResponseError>(
    '/help_center/live_chat_ticket',
    transformers.createLiveChatTicketArgs(args),
  )

export const updateBusinessAccountBankDetails = ({
  bankAccountOwnerName,
  bankAccountNumber,
}: apiTypes.updateBusinessAccountBankDetailsArgs) =>
  api.put('/business_accounts_dsa', {
    bank_account_number: bankAccountNumber,
    bank_account_owner_name: bankAccountOwnerName,
  })

export const reserveItem = ({ id, buyerId }: apiTypes.ReserveItemArgs) =>
  api.post(`/items/${id}/reservation`, {
    to_whom_id: buyerId,
  })

export const getNotificationSettings = (userId: number, type: 'email' | 'mobile' = 'email') =>
  api.get<apiTypes.NotificationSettingsResp>(`/users/${userId}/notification_settings`, {
    params: { type },
  })

export const updateNotificationSettings = (
  userId: number,
  settings: Record<string, boolean | number>,
) =>
  api.put(`/users/${userId}/notification_settings`, { notification_settings: { email: settings } })

export const convertBusinessWallet = () =>
  api.post<apiTypes.WalletConversionStatusResp, WalletConversionErrorDto>(
    '/business_accounts/conversion',
  )

export const getWalletConversionStatus = requests.getWalletConversionStatusRequest(api)

export const getCarrierPreferences = (userId: number) =>
  api.get<apiTypes.CarrierPreferencesResp>(`/users/${userId}/carrier_preferences`)

export const updateCarrierPreference = ({
  userId,
  carrierId,
  enabled,
}: apiTypes.UpdateCarrierPreferenceArgs) =>
  api.put<apiTypes.CarrierPreferenceResp>(`/users/${userId}/carriers/${carrierId}/preference`, {
    carrier_preference: { enabled },
  })

export const getCarrierSettingsFeedbackConfiguration = () =>
  api.get<apiTypes.GetCarrierSettingsFeedbackConfigurationResp>('/carrier_preferences/feedback')
export const getDigitalLabel = ({ transactionId }: apiTypes.GetDigitalLabelArgs) =>
  api.get<apiTypes.DigitalLabelResp>(`/transactions/${transactionId}/shipment/digital_label`)

export const getShippingContactConfiguration = () =>
  api.get<apiTypes.ShippingContactConfigurationResp>('/configurations/shipping_contact')

export const saveTransactionShippingContact = (args: apiTypes.SaveTransactionShippingContactArgs) =>
  api.post<apiTypes.ApiResponse>(`/transactions/${args.transactionId}/shipping_contact`, {
    save_for_later: args.saveForLater,
    shipping_contact: omitBy(
      {
        buyer_phone_number: args.buyerPhoneNumber,
        seller_phone_number: args.sellerPhoneNumber,
      },
      isNil,
    ),
  })

export const saveShippingOrderContact = (args: apiTypes.SaveShippingOrderContactArgs) =>
  api.post<apiTypes.ApiResponse>(`/shipping_orders/${args.shippingOrderId}/shipping_contact`, {
    save_for_later: args.saveForLater,
    receiver_phone_number: args.receiverPhoneNumber,
    sender_phone_number: args.senderPhoneNumber,
  })

export const deleteTransactionShippingContact = (transactionId: number) =>
  api.delete<apiTypes.ApiResponse>(`/transactions/${transactionId}/shipping_contact`)

export const deleteShippingOrderContact = (shippingOrderId: string) =>
  api.delete<apiTypes.ApiResponse>(`/shipping_orders/${shippingOrderId}/shipping_contact`)

export const getOfferRequestOptions = ({
  itemPrice,
  sellerId,
}: apiTypes.GetOfferRequestOptionsArgs) =>
  api.post<apiTypes.GetOfferRequestOptionsResp>('/offers/request_options', {
    price: {
      amount: itemPrice.amount,
      currency_code: itemPrice.currencyCode,
    },
    seller_id: sellerId,
  })

export const createOfferRequest = ({ price, currency, transactionId }: apiTypes.CreateOfferArgs) =>
  api.post<apiTypes.CreateOfferRequestResp>(`/transactions/${transactionId}/offer_requests`, {
    offer_request: {
      price,
      currency,
    },
  })

export const createOffer = ({ transactionId, price, currency }: apiTypes.CreateOfferArgs) =>
  api.post<apiTypes.CreateOfferResp>(`/transactions/${transactionId}/offers`, {
    offer: {
      price,
      currency,
    },
  })

export const getDataExportStatus = (userId: number) =>
  api.get<apiTypes.DataExportStatusResp>(`/users/${userId}/personal_data_export`)

export const requestDataExport = ({
  userId,
  fingerprint,
}: {
  userId: number
  fingerprint: string
}) => api.post(`/users/${userId}/personal_data_export`, { fingerprint })

export const confirmDataExport = ({ userId, code }: apiTypes.ConfirmDataExportArgs) =>
  api.post(`/users/${userId}/personal_data_export/confirmation`, { code })

export const createAdminAlert = (args: apiTypes.CreateAdminAlertArgs) =>
  api.post(`/users/${args.userId}/admin_alerts`, transformers.createAdminAlertArgsToParams(args))

export const getReportReasons = (alertType: AlertType) =>
  api.get<apiTypes.ReportReasonsResp>(`/report_reasons/${alertType}`)

export const acceptOffer = ({ transactionId, offerRequestId }: apiTypes.AcceptOfferArgs) =>
  api.put(`/transactions/${transactionId}/offer_requests/${offerRequestId}/accept`)

export const rejectOffer = ({ transactionId, offerRequestId }: apiTypes.RejectOfferArgs) =>
  api.put(`/transactions/${transactionId}/offer_requests/${offerRequestId}/reject`)

export const sendProductFeedback = (args: apiTypes.SendProductFeedbackArgs) =>
  api.post('/app_feedbacks', { ...args, user_id: args.userId })

export const deleteMessage = ({ conversationId, messageId }: apiTypes.DeleteMessageNewArgs) =>
  api.delete(`conversations/${conversationId}/replies/${messageId}`)

export const deletePhotoMessage = ({
  conversationId,
  messageId,
  photoId,
}: apiTypes.DeletePhotoMessageNewArgs) =>
  api.delete(`/conversations/${conversationId}/replies/${messageId}/photos/${photoId}`)

export const postLikeMessage = ({
  conversationId,
  messageId,
  reactionType,
}: apiTypes.PostLikeMessageArgs) =>
  api.post<apiTypes.PostLikeMessageResp>(
    `conversations/${conversationId}/replies/${messageId}/reactions`,
    {
      type: reactionType,
    },
  )

export const deleteLikeMessage = ({
  conversationId,
  messageId,
  reactionId,
}: apiTypes.DeleteLikeMessageArgs) =>
  api.delete(`conversations/${conversationId}/replies/${messageId}/reactions/${reactionId}`)

export const postLikeMessagePhoto = ({
  conversationId,
  messageId,
  photoId,
  reactionType,
}: apiTypes.PostLikeMessagePhotoArgs) =>
  api.post<apiTypes.PostLikeMessagePhotoResp>(
    `conversations/${conversationId}/replies/${messageId}/photos/${photoId}/photo_reactions`,
    {
      type: reactionType,
    },
  )

export const deleteLikeMessagePhoto = ({
  conversationId,
  messageId,
  photoId,
  reactionId,
}: apiTypes.DeleteLikeMessagePhotoArgs) =>
  api.delete(
    `conversations/${conversationId}/replies/${messageId}/photos/${photoId}/photo_reactions/${reactionId}`,
  )

export const getKycEducationInfo = () =>
  api.get<apiTypes.KycEducationInfoResp>('/payments/identity/education')

export const getBankStatementEducation = () =>
  api.get<apiTypes.BankStatementEducationResp>('/payments/identity/educations/bank_statement')

export const getKycPhotoTipType = () =>
  api.get<apiTypes.getKycPhotoTipTypeResp>('/payments/identity/educations/photo_tips')

export const markEducationAsRead = (type: EducationType) => api.post('/user/education', { type })

export const getClosetPromotionPerformance = () =>
  api.get<apiTypes.ClosetPromoPerfrmanceResponse>('promoted_closets/performances')

export const getItemPerformance = (itemId: number) =>
  api.get<apiTypes.ItemPerformanceResponse>(`items/${itemId}/performance`)

export const getBrazeEventProperties = ({
  eventName,
  modelId,
}: apiTypes.GetBrazeEventPropertiesArgs) =>
  api.get<apiTypes.GetBrazeEventPropertiesResp>('/external_crm/event_payload', {
    params: {
      event_name: eventName,
      model_id: modelId,
    },
  })

export const getRequestReturnDetails = (transactionId: number) =>
  api.get<apiTypes.GetRequestReturnDetailsResp>(
    `/transactions/${transactionId}/shipment/request_return_details`,
  )

export const createReturnShipment = (transactionId: number) =>
  api.post(`/transactions/${transactionId}/shipment/return_shipment`)

export const createComplaintEscalation = (args: apiTypes.CreateComplaintEscalationArgs) =>
  api.post(`/complaints/${args.complaintId}/escalate`, {
    english_allowed: args.englishResponseAllowed,
    help_center_session_id: args.helpCenterSessionId,
  })

export const sendIntellectualPropertyInfringement = (
  formData: apiTypes.SendIntellectualPropertyInfringementArgs,
) =>
  api.post(
    '/brand_complaints',
    transformers.sendIntellectualPropertyInfringementArgsToParams(formData),
  )

export const uploadReportAttachment = (file: File) => {
  const data = new FormData()
  data.append('file', file)

  return api.post<apiTypes.UploadReportAttachmentResp>('/brand_complaints/attachments', data)
}

export const getUserStats = (userId: number) =>
  api.get<apiTypes.GetUserStatsResp>(`/users/${userId}/stats`, {
    headers: moneyObjectHeader,
  })

export const getUserBuyCount = () =>
  api.get<apiTypes.GetUserBuyCountResp>('/tracker_attributes', {
    params: {
      type: 'buy_debit_transactions',
    },
  })

export const getClosetPromotionPricing = () =>
  api.get<apiTypes.ClosetPromotionPricingResp>('/promoted_closets/pricing')

export const downloadShippingLabel = (transactionId: number) =>
  api.get<apiTypes.DownloadShippingLabelResp>(`/transactions/${transactionId}/shipment/pdf_label`)

export const blockUser = ({ currentUserId, blockedUserId }: apiTypes.UserBlockArgs) =>
  api.post(`/users/${currentUserId}/user_hates`, { hated_user_id: blockedUserId })

export const unblockUser = ({ currentUserId, blockedUserId }: apiTypes.UserBlockArgs) =>
  api.delete(`/users/${currentUserId}/user_hates`, { params: { hated_user_id: blockedUserId } })

export const getDirectDonationConfiguration = () =>
  api.get<apiTypes.GetDirectDonationConfiguratioResp>('/direct_donations/orders/new')

export const createDirectDonationOrder = (args: apiTypes.CreateDirectDonationOrderArgs) =>
  api.post<apiTypes.CreateDirectDonationOrderResp>(
    '/direct_donations/orders/prepare',
    transformers.createDirectDonationOrderArgsToParams(args),
    { headers: moneyObjectHeader },
  )

export const getDirectDonationOrder = (orderId: number) =>
  api.get<apiTypes.GetDirectDonationOrderResp>(`direct_donations/orders/${orderId}`, {
    headers: moneyObjectHeader,
  })

export const getNearbyShippingOptions = (args: apiTypes.GetNearbyShippingOptionsArgs) =>
  api.get<apiTypes.NearbyShippingOptionsResp>(
    `/transactions/${args.transactionId}/nearby_shipping_options`,
    {
      params: transformers.getNearbyShipmentOptionsArgsToParams(args),
      headers: moneyObjectHeader,
    },
  )

export const getNearbyPickupPoints = (args: apiTypes.GetNearbyPickupPointsArgs) =>
  api.get<apiTypes.NearbyPickupPointsResp>(
    `/shipping_orders/${args.shippingOrderId}/nearby_pickup_points`,
    {
      params: transformers.getNearbyPickupPointsArgsToParams(args),
      headers: moneyObjectHeader,
    },
  )

export const getPackageShippingOptions = ({
  packageSizeId,
  serviceType,
}: apiTypes.GetPackageShippingOptionsArgs) =>
  api.get<apiTypes.GetPackageShippingOptionsResp>(
    `/package_sizes/${packageSizeId}/shipping_options`,
    {
      params: {
        offline_verification_service: serviceType,
      },
    },
  )

export const getAdsPlacements = (args: apiTypes.GetAdsPlacementsArgs) =>
  requests.getAdsPlacementsRequest(api)(args)

export const getAdsSegments = (args: apiTypes.GetAdsSegmentsArgs) =>
  requests.getAdsSegmentsRequest(api)(args)

export const getShipmentItems = (transactionId: number) =>
  api.get<apiTypes.GetShipmentItemsResp>(`/transactions/${transactionId}/shipment/items`)

export const getReturnShippingOptions = (transactionId: number) =>
  api.get<apiTypes.GetReturnShippingOptionsResp>(
    `/transactions/${transactionId}/shipment/return_shipping_options`,
  )

export const getDeliveryTypes = (transactionId: number) =>
  api.get<apiTypes.DeliveryTypesResp>(`/transactions/${transactionId}/delivery_types`)

export const createCustomReturnShipment = ({
  transactionId,
  returnShippingOption,
}: apiTypes.CreateCustomReturnShipmentArgs) =>
  api.post(`/transactions/${transactionId}/shipment/return_shipping_option`, {
    return_shipping_option: returnShippingOption,
  })

export const getFundraiserConfiguration = () =>
  api.get<apiTypes.GetFundraiserResponse>('/fundraiser')

export const getCharities = () => api.get<apiTypes.GetCharitiesResponse>('/charities')

export const updateFundraiser = ({ percentage, charityCode }: apiTypes.UpdateFundraiserArgs) =>
  api.put<apiTypes.GetFundraiserResponse>('/fundraiser', {
    fundraiser: {
      charity_code: charityCode,
      percentage,
    },
  })

export const getItemShippingDetails = (itemId: number) =>
  api.get<apiTypes.GetShippingDetailsResp>(`/items/${itemId}/shipping_details`, {
    headers: moneyObjectHeader,
  })

export const deleteFundraiser = () => api.delete('/fundraiser')

export const getBrazeAuthToken = () => api.get<apiTypes.GetBrazeAuthTokenResp>('/external_crm/jwts')

export const getConversation = requests.getConversationRequest(api)

export const getNationalities = () => api.get<apiTypes.GetNationalitiesResp>('/nationalities')

export const markAsSold = ({
  itemId,
  buyerId,
  closingAction,
  price,
  currency,
}: apiTypes.MarkAsSoldArgs) =>
  api.post<apiTypes.MarkAsSoldResp>(
    `/items/${itemId}/mark_as_sold`,
    {
      receiver_id: buyerId,
      closing_action: closingAction,
      price,
      currency,
    },
    { headers: moneyObjectHeader },
  )

export const markAsUnreserved = ({ itemId }: { itemId: number }) =>
  api.delete(`items/${itemId}/reservation`)

export const getFaqEntryFeedbackData = (faqId: number) =>
  api.get<apiTypes.FaqEntryFeedbackResp>(`/faq_entries/${faqId}/feedback`)

export const updateFaqEntryFeedbackForm = (args: apiTypes.UpdateFaqEntryFeedbackArgs) =>
  api.patch(
    `/faq_entries/${args.faqId}/feedback`,
    transformers.updateFaqEntryFeedbackArgsToParams(args),
  )

export const getVerificationPrompt = requests.getVerificationPromptRequest(api)

export const dismissVerificationPrompt = (userId: number) =>
  api.delete(`/users/${userId}/verifications/prompt`)

export const sendEmailVerificationCode = ({
  userId,
  email,
}: apiTypes.SendEmailVerificationCodeArgs) =>
  api.post<apiTypes.SendEmailVerificationCodeResp>(`/users/${userId}/verifications/email_code`, {
    email,
  })

export const validateEmailVerificationCode = ({
  userId,
  code,
}: apiTypes.ValidateEmailVerificationCodeArgs) =>
  api.put(`/users/${userId}/verifications/email_code`, {
    code,
  })

export const markAsDeliveredToSender = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/mark_as_return_to_sender_completed`)

export const confirmTransactionInstructions = (transactionId: number) =>
  api.put(`/transactions/${transactionId}/shipment/confirm_instructions`)

export const getUserSessions = (userId: number) =>
  api.get<apiTypes.GetUserSessionsResp>(`users/${userId}/sessions`)

export const deleteUserSession = ({
  userId,
  sessionId,
  fingerprint,
}: {
  userId: number
  sessionId: number
  fingerprint: string
}) => api.delete(`/users/${userId}/sessions/${sessionId}?fingerprint=${fingerprint}`)

export const getSessionDefaults = () =>
  api.get<apiTypes.GetSessionDefaultsResp>('/configurations/session_defaults')

export const reserveTransactionItems = (transactionId: number, reserved: boolean) =>
  api.put(`/transactions/${transactionId}/reservation`, { reserved })

export const getShippingInstructions = (transactionId: number) =>
  api.get<apiTypes.GetShippingInstructionsResp>(
    `/transactions/${transactionId}/shipping_instructions`,
  )

export const disableUserHoliday = (userId: number) => api.delete(`/users/${userId}/holiday`)

export const getGoogleIdToken = ({
  code,
  state,
  action,
  isLocalRedirectEnabled,
}: apiTypes.GetGoogleIdTokenArgs) =>
  api.get<apiTypes.GetGoogleIdTokenResp>('/google_user/token', {
    params: {
      code,
      state,
      take_action: action,
      next_local_redirect: isLocalRedirectEnabled,
    },
  })

export const getTaxpayerEducation = () =>
  api.get<apiTypes.TaxpayerEducationResp>('/taxpayers/education')

export const getTaxpayerFormConfiguration = (countryCode: string) =>
  api.get<apiTypes.TaxpayerFormConfigurationResp>(`/taxpayers/form/${countryCode}`)

export const changePassword = ({
  userId,
  currentPassword,
  newPassword,
  fingerprint,
}: apiTypes.ChangePasswordArgs) =>
  api.put(`/users/${userId}/password`, {
    old_password: currentPassword,
    password: newPassword,
    fingerprint,
  })

export const changeForgotPassword = ({
  code,
  password,
  passwordConfirmation,
  fingerprint,
}: apiTypes.ChangeForgotPasswordArgs) =>
  api.post('/users/change_forgot_password', {
    code,
    password,
    password_confirmation: passwordConfirmation,
    fingerprint,
  })

export const getForgotPasswordConfirmationCode = ({
  code,
}: apiTypes.GetForgotPasswordConfirmationCodeArgs) =>
  api.get<apiTypes.GetForgotPasswordConfirmationCodeResp>('/users/forgot_password', {
    params: { code },
  })

export const getCategoryAttributes = (categoryId: number) =>
  api.get<apiTypes.GetCategoryAttributesResp>('/item_upload/catalog_attributes', {
    params: { catalog_id: categoryId },
  })

export const searchCategoryAttributeOptions = (code: string, keyword: string | null | undefined) =>
  api.post<apiTypes.SearchCategoryAttributeOptionsResp>('/item_upload/catalog_attributes/search', {
    code,
    keyword,
  })

export const updateTaxpayerFormData = (args: apiTypes.UpdateTaxpayerFormDataArgs) =>
  api.put<apiTypes.ApiResponse>(
    '/taxpayers/form',
    transformers.updateTaxpayerFormDataArgsToParams(args),
  )

export const getTaxpayerFormStatus = () =>
  api.get<apiTypes.TaxpayerFormStatusResp>('/taxpayers/form/status')

export const prepareReturnLabelOrder = ({
  transactionId,
  pickupPointCode,
  packageTypeId,
  addressId,
}) =>
  api.post<apiTypes.ReturnLabelOrderResp>('/return_label/orders/prepare', {
    transaction_id: transactionId,
    pickup_point_code: pickupPointCode,
    package_type_id: packageTypeId,
    address_id: addressId,
  })

export const getUserObligations = (userId: number) =>
  api.get<apiTypes.GetUserObligationsResp>(`/users/${userId}/obligations`)

export const checkIsAlertsGeneratorBusy = () =>
  api.get<apiTypes.GetIsAlertsGeneratorBusyResp>('/admin/alert_generators/is_busy')

export const generateAlertsFromSearchParams = ({
  searchParams,
  reportReason,
}: {
  searchParams: Record<string, string | Array<string> | undefined>
  reportReason: ReportReasonCatalogSystem | undefined
}) =>
  api.post('/admin/alert_generators/execute', {
    item_search_params: searchParams,
    report_reason_code: reportReason,
  })

export const getWalletIncomeSettings = () =>
  api.get<apiTypes.GetIncomeSettingsResp>('/wallet/income_reports/settings')

export const getInvoicesAvailableDates = () =>
  api.get<apiTypes.GetInvoicesAvailableDatesResp>('/invoices/available_dates')

export const getInvoicesByDate = ({ year, month }: { year: string; month: string }) =>
  api.get<apiTypes.GetInvoicesByDateResp>(`/invoices/${year}/${month}`)

export const getSatisfactionSurvey = (userId: number, transactionId?: string) =>
  api.get<apiTypes.GetSatisfactionSurveyResp>(
    `/users/${userId}/satisfaction_survey?transaction_id=${transactionId || ''}`,
  )

export const getFeedbackSummary = (userId: number) =>
  api.get<apiTypes.GetFeedbackSummaryResp>(`/user_feedbacks/summary?user_id=${userId}`)

export const skipTermsAndConditions = () => api.post('/terms/skip')

export const acceptTermsAndConditions = (id: number) => api.put(`/users/${id}/agree_terms`)

export const saveSatisfactionSurveyAnswers = ({
  userId,
  transactionId,
  answers,
}: apiTypes.SaveSatisfactionSurveyAnswersArgs) =>
  api.post(`/users/${userId}/satisfaction_survey?transaction_id=${transactionId || ''}`, {
    answers,
    comment: answers.comment,
  })

export const getInfoBoardPosts = () => api.get<apiTypes.GetInfoBoardPostsResp>('/info_posts')

export const getTermsAndConditions = () =>
  api.get<apiTypes.GetTermsAndConditionsResp>('/terms_and_conditions')

export const getInfoBoardPost = (postId: number) =>
  api.get<apiTypes.GetInfoBoardPostResp>(`/info_posts/${postId}`)

export const getNotificationLandings = (code: string) =>
  api.get<apiTypes.GetNotificationLandingsResp>(`/notification_landings/${code}`)

export const getIsSatisfactionSurveyEnabled = ({
  userId,
  transactionId,
}: {
  userId: number
  transactionId?: number
}) =>
  api.get<apiTypes.GetIsSatisfactionSurveyEnabledResp>(
    `/users/${userId}/satisfaction_survey/enabled?transaction_id=${transactionId || ''}`,
  )

export const getSimilarItemsById = ({ itemId }: { itemId: number }) =>
  api.get<apiTypes.GetSimilarItemsByIdResp>(`/items/${itemId}/similar_items`, {
    headers: moneyObjectHeader,
  })

export const getTermsAndConditionsContent = () =>
  api.get<apiTypes.GetTermsAndConditionsContentResp>('/terms_and_conditions/content')

export const getPrivacyPolicy = () => api.get<apiTypes.GetPrivacyPolicyResp>('/privacy_policy')

export const getIsFundraiserEnabled = () =>
  api.get<apiTypes.GetIsFundraiserEnabledResp>('/fundraiser/enabled')

export const getItemBreadcrumbs = ({ catalogId, brandId }: apiTypes.ItemBreadcrumbsArgs) =>
  api.get<apiTypes.GetItemBreadcrumbsResp>(`/catalogs/${catalogId}/breadcrumbs`, {
    params: { brand_id: brandId },
  })

export const getNearbyDropOffPoints = (args: apiTypes.GetNearbyDropOffPointsArgs) =>
  api.get<apiTypes.NearbyDropOffPointResp>(`/shipments/${args.shipmentId}/nearby_drop_off_points`, {
    params: transformers.addNearbyDropOffPointArgsToParams(args),
  })

export const createDsaNoticeAppeal = (args: apiTypes.CreateDsaNoticeAppealArgs) =>
  api.post<apiTypes.CreateDsaNoticeAppealResp>(
    '/dsa/appeals/submit',
    transformers.getDsaNoticeCreateAppealArgs(args),
  )

export const createDsaReporterAppeal = (args: apiTypes.CreateDsaNoticeAppealArgs) =>
  api.post<apiTypes.CreateDsaNoticeAppealResp>(
    '/dsa/reporter_appeals/submit',
    transformers.getDsaNoticeCreateAppealArgs(args),
  )

export const createDsaNtdAppeal = (args: apiTypes.CreateDsaNtdAppealArgs) =>
  api.post('/dsa/ntd_appeals/submit', transformers.getCreateDsaNtdAppealArgs(args))

export const getTaxRules = (countryId: string, variant?: string) =>
  api.get<apiTypes.GetTaxRulesResp>(`/taxpayers/tax_rules/${countryId}?variant=${variant || ''}`)

export const getInSiteSeoContent = ({ contentType, key, value }: apiTypes.SeoContentArgs) =>
  api.get<apiTypes.InSiteSeoContentResp>(`/cms/${contentType}`, {
    params: { key, value },
  })

export const getReferrer = () => api.get<apiTypes.GetReferrerResp>('/referrals/referrer')

export const postAdvertisingLeadForm = (args: apiTypes.PostAdvertisingLeadFormArgs) =>
  api.post('/ads/lead', transformers.postAdvertisingLeadFormArgsToParams(args))

export const getSellerOfferOptions = ({ transactionId }: apiTypes.GetSellerOfferOptionsArgs) =>
  api.get<apiTypes.GetSellerOfferOptionsResp>(
    `/transactions/${transactionId}/offers/seller_options`,
  )

export const getIconsExplanation = (itemId: number) =>
  api.get<apiTypes.GetIconsExplanationResp>(`items/${itemId}/badges`)

export const markFirstTimeListerEducationAsRead = () =>
  api.delete('conversation_safety/education/first_time_listers')

export const dismissTaxpayerRestrictionModal = () =>
  api.post('/taxpayers/banners/taxpayer_banner/dismiss_modal')

export const dismissTaxpayerRestrictionBanner = () =>
  api.post('/taxpayers/banners/taxpayer_banner/dismiss')

export const dismissTaxpayerFillFormModal = () =>
  api.post('/taxpayers/banners/taxpayer_banner/mark_redirected')

export const getTaxpayersSpecialVerificationDeadline = () =>
  api.get<apiTypes.GetTaxpayersSpecialVerificationDeadlineResp>(
    '/taxpayers/special_verification/deadline',
  )
export const getTaxpayersSpecialVerificationEducation = () =>
  api.get<apiTypes.TaxpayerEducationResp>('/taxpayers/special_verification/education')

export const getTaxpayersSpecialVerificationFormConfiguration = () =>
  api.get<apiTypes.GetTaxpayersSpecialVerificationFormConfigurationResp>(
    '/taxpayers/special_verification/configuration',
  )

export const validateTaxpayersSpecialVerificationFormFields = (
  args: apiTypes.ValidateSpecialVerificationFormArgs,
) =>
  api.post('/taxpayers/special_verification/validation', {
    fields: args,
  })

export const submitTaxpayersSpecialVerificationForm = (
  args: apiTypes.SubmitTaxpayersSpecialVerificationFormArgs,
) =>
  api.post(
    '/taxpayers/special_verification',
    transformers.submitTaxpayersSpecialVerificationFormArgsToParams(args),
    {
      headers: args.x_thumbprint ? { 'X-Thumbprint': args.x_thumbprint } : {},
    },
  )

export const startMigrationWithId = (migrationCode: string) =>
  api.post('/acquisitions/migrations', {
    migration_code: migrationCode,
  })

export const validateMigrationId = (migrationCode: string) =>
  api.get<apiTypes.GetMigrationStatusResp>(
    `acquisitions/migrations/availability?migration_code=${migrationCode}`,
  )

export const inviteStaff = (email: string) =>
  api.post<apiTypes.InviteStaffResp>('secondary_users/invitation', { email })

export const getAccountStaff = () => api.get<apiTypes.GetAccountStaffResp>('secondary_users/staff')

export const deleteAccountStaffInvitation = (invitationId: number) =>
  api.delete(`secondary_users/staff/${invitationId}`)

export const acceptAccountInvitation = (token: string) =>
  api.post('/secondary_users/invitation/accept', { token })

export const getStaffAccountAssertion = (targetUserId: number) =>
  api.post<apiTypes.GetStaffAccountAssertionResp>('secondary_users/assertions', {
    target_user_id: targetUserId,
  })

export const getDownloadLabelUrl = (shipmentId: number) =>
  api.get<apiTypes.GetDownloadLabelUrlResp>(`shipments/${shipmentId}/label_url`)

export const getItemEscrowFees = ({ itemId }: { itemId: number }) =>
  api.get<apiTypes.GetItemEscrowFeesResp>(`/items/${itemId}/escrow_fees`)

export const getTransactionEscrowFees = ({ transactionId }: { transactionId: number }) =>
  api.get<apiTypes.GetItemEscrowFeesResp>(`/transactions/${transactionId}/escrow_fees`)

export const getComplaintCreationDetails = (transactionId: number) =>
  api.get<apiTypes.GetComplaintCreationDetailsResp>(
    `transactions/${transactionId}/complaint_creation_details`,
  )

export const getVasEntryPoints = ({ bannerNames }: { bannerNames: Array<VasEntryPoint> }) =>
  api.get<apiTypes.GetVasEntryPointsResp>('/vas_entry_points', {
    params: { names: bannerNames },
  })

export const getRefundEstimate = (transactionId: number, orderItemIds: string) =>
  api.get<RefundEstimateDto>(`transactions/${transactionId}/refund_estimate`, {
    params: { order_item_ids: orderItemIds },
  })

export const getIsNewComplaintViewEnabled = (faqEntryId: number) =>
  api.get<apiTypes.GetIsNewComplaintViewResp>(`faq_entries/${faqEntryId}/complaint_view`)

export const updateShippingDeadlineExtension = (
  args: apiTypes.UpdateShippingDeadlineExtensionArgs,
) =>
  api.patch(`/shipments/${args.shipmentId}/deadline_extension_status`, {
    status: args.status,
  })

export const getDeadlineExtensionOptions = (shipmentId: number) =>
  api.get<apiTypes.GetDeadlineExtensionOptionsResp>(
    `shipments/${shipmentId}/deadline_extension_options`,
  )

export const createShippingDeadlineExtension = (
  args: apiTypes.CreateShippingDeadlineExtensionArgs,
) =>
  api.post(`shipments/${args.shipmentId}/deadline_extension`, {
    deadline_extension: {
      key: args.key,
      reason: args.reason,
    },
  })

export const validateTaxpayerForm = (args: apiTypes.ValidateTaxpayerFormArgs) =>
  api.post('/taxpayers/form/validate', { taxpayer: args })

export const getCollectionDates = (shipmentId: number) =>
  api.get<apiTypes.GetCollectionDatesResp>(`shipments/${shipmentId}/collection_dates`)

export const getPriceEstimateWithFees = (args: apiTypes.GetPriceEstimateWithFeesArgs) =>
  api.post<apiTypes.GetPriceEstimateWithFeesResponse>('offer/estimate_with_fees', args)

export const getLabelOptions = (shipmentId: number) =>
  api.get<apiTypes.GetLabelOptionsResp>(`shipments/${shipmentId}/label_options`)

export const getTwoFADetails = (twoFaId: string) =>
  api.get<apiTypes.GetTwoFADetailsResp>(`user_verifier/${twoFaId}/details`)

export const createProIntegrationsApplication = (
  data: apiTypes.CreateProIntegrationsApplicationArgs,
) => api.post('/vpi/application', data)

export const resendTwoFactorVerifierCode = (id: string) =>
  api.post<apiTypes.TwoFactorResendResp>(`/user_verifier/${id}/resend`)

export const getTaxpayerNavigationInfo = () =>
  api.get<apiTypes.GetTaxpayerNavigationInfoResp>('/taxpayers/navigation')

export const getTaxpayerCenterInfo = () =>
  api.get<apiTypes.GetTaxpayerCenterConfigResp>('/taxpayers/center')

export const getSellerInsights = () =>
  api.get<apiTypes.GetSellerInsightsResp>('/seller_insights/closet_stats')

export const getSellerInsightsPhotoRecommendations = () =>
  api.get<apiTypes.GetSellerInsightsPhotoRecommendationsResp>(
    '/seller_insights/recommendations/add_photos',
  )

export const getSustainabilityImpact = () =>
  api.get<apiTypes.GetSustainabilityImpactResp>('/seller_insights/sustainability_impacts')

export const getHelpCenterLinksForMigration = () =>
  api.get<apiTypes.GetHelpCenterLinkForUserMigrationResp>('/acquisitions/help')

export const getSellerFilters = () =>
  api.get<apiTypes.GetSellerFiltersResp>('/closet/seller_filters')

export const exposeVasBannerLock = () => api.post('/vas_entry_points/expose_locked_ab_test')

export const getDataExportList = (userId: number) =>
  api.get<DataExportListDto>(`users/${userId}/personal_data_export/list`)

export const getDataExportLink = (userId: number, hash: string) =>
  api.get<apiTypes.GetDataExportLinkResp>(
    `users/${userId}/personal_data_export/download?hash=${hash}`,
  )

export const getAvailableCarriers = ({
  shippingOrderId,
  selectedDeliveryType,
  shipmentId,
}: apiTypes.GetAvailableCarriersArgs) =>
  api.get<apiTypes.GetAvailableCarriersResp>(
    `shipping_orders/${shippingOrderId}/available_rates?delivery_type=${selectedDeliveryType}&shipment_id=${shipmentId}&exclude_current_rate=true`,
  )

export const getAvailablePickupPoints = ({
  shippingOrderId,
  countryCode,
  latitude,
  longitude,
  shipmentId,
  rateRootUuid,
}: apiTypes.GetAvailablePickupPointsArgs) =>
  api.get<apiTypes.GetAvailablePickupPointsResp>(
    `shipping_orders/${shippingOrderId}/nearby_pickup_points?country_code=${countryCode}&latitude=${latitude}&longitude=${longitude}&shipment_id=${shipmentId}&rate_root_uuid=${rateRootUuid}`,
  )

export const getDeliveryOptionChangeRequestDetails = (shipmentId: number) =>
  api.get<apiTypes.GetDeliveryOptionChangeRequestDetailsResp>(
    `shipments/${shipmentId}/delivery_option_change_request`,
  )

export const getDeliveryType = (shipmentId: number) =>
  api.get<apiTypes.getDeliveryTypeResp>(`shipments/${shipmentId}`)

export const createDeliveryOptionChangeRequest = ({
  shipmentId,
  rootRateUuid,
  reason,
}: apiTypes.CreateDeliveryOptionChangeRequestArgs) =>
  api.post(`shipments/${shipmentId}/delivery_option_change_request`, {
    root_rate_uuid: rootRateUuid,
    reason,
  })

export const updateDeliveryOptionChangeRequest = (
  args: apiTypes.UpdateDeliveryOptionChangeRequestArgs,
) =>
  api.patch(`/shipments/${args.shipmentId}/delivery_option_change_request`, {
    status: args.status,
    pickup_point_code: args.pickupPointCode,
  })

export const getShipmentDetails = (shipmentId: number) =>
  api.get<apiTypes.GetShipmentDetailsResp>(`/shipments/${shipmentId}`)

export const moderateFeedback = ({
  feedbackId,
  userId,
  otherUserId,
}: apiTypes.ModerateFeedbackArgs) =>
  api.post('/notices/feedbacks', {
    feedback_id: feedbackId,
    user_id: userId,
    other_user_id: otherUserId,
  })

export const getPublicPhotos = ({ itemIds, photoSize }: apiTypes.GetPublicPhotosArgs) =>
  api.get<apiTypes.GetPublicPhotosResp>('/items/public_photos', {
    params: {
      item_ids: itemIds,
      photo_size: photoSize,
    },
  })

export const getBumpableItems = ({ currentPage, perPage }: apiTypes.GetBumpableItemsArgs) =>
  api.get<apiTypes.GetBumpableItemsResp>('/bumps/bumpable_items', {
    params: { per_page: perPage, page: currentPage },
  })

export const getBumpsRecommendedItems = () =>
  api.get<apiTypes.GetBumpsRecommendedItemsResp>('/bumps/recommended_items')
