import { createAction } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import { Screen } from 'constants/tracking/screens'
import { FieldErrorType } from 'pages/ItemUpload/types'
import { AssignedPhotoDto, GtmTrackableItemDto } from 'types/dtos'
import { ItemAttributeModel, ItemAuthenticityModalModel, UserModel } from 'types/models'
import { UiState as UiStateEnum } from 'constants/ui'

import { FieldName, ItemStatus, stateName } from './constants'
import { CatalogSelectType, DynamicAttribute } from './types'

export const fetchItemSuggestionsRequest = createAction<{
  photoIds: Array<number>
}>(`${stateName}/fetchItemSuggestionsRequest`)

export const fetchItemRequest = createAction<{
  id: number | null
  tempUuid: string
  setItemStatus: Dispatch<SetStateAction<ItemStatus>>
  isMultipleSizeGroupsABTestEnabled: boolean
  setUiState: Dispatch<SetStateAction<UiStateEnum>>
  fetchCategoryAttributes: (catalogId: number | null, itemStatus: ItemStatus) => void
  setSelectedDynamicAttributeValues: (selectedValues: Array<ItemAttributeModel> | undefined) => void
}>(`${stateName}/fetchItemRequest`)

type SubmitItemActionArgs = {
  tempUuid: string
  user: Pick<UserModel, 'externalId' | 'email' | 'id'>
  itemStatus: ItemStatus
  screenName: Screen
  saveAsDraft: boolean
  additionalHeaders: { [key: string]: string }
  isItemBumped: boolean
  trackListingToGoogleTagManager: (item: GtmTrackableItemDto) => void
  trackListingToBraze: (args: {
    itemId: number | undefined
    userExternalId: string | undefined | null
  }) => void
  setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
  showIVSModal: () => void
  showEVSModal: () => void
  assignedPhotos: Array<AssignedPhotoDto>
  enableForm: () => void
  disableForm: () => void
  dynamicAttributes: Array<DynamicAttribute>
}

export const submitItem = createAction(
  `${stateName}/submitItem`,
  ({
    user,
    tempUuid,
    itemStatus,
    screenName,
    saveAsDraft,
    isItemBumped,
    additionalHeaders,
    trackListingToGoogleTagManager,
    trackListingToBraze,
    setFieldErrors,
    showIVSModal,
    showEVSModal,
    assignedPhotos,
    enableForm,
    disableForm,
    dynamicAttributes,
  }: SubmitItemActionArgs) => ({
    payload: {
      user,
      tempUuid,
      itemStatus,
      screenName,
      additionalHeaders,
      trackListingToGoogleTagManager,
      trackListingToBraze,
      setFieldErrors,
      showIVSModal,
      showEVSModal,
      assignedPhotos,
      enableForm,
      disableForm,
      dynamicAttributes,
    },
    meta: {
      saveAsDraft,
      isItemBumped,
    },
  }),
)

export const selectCatalog = createAction<{
  catalogId: number
  newCatalog: CatalogSelectType | null
  currentCatalog: CatalogSelectType | null | undefined
  itemStatus: ItemStatus
  fieldErrors: FieldErrorType
  setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
  removeFieldError: (fieldName: FieldName) => void
  setAuthenticityModalContent: Dispatch<SetStateAction<ItemAuthenticityModalModel | null>>
  requestAuthenticityModal: ({
    force,
    modalDataOnly,
    newCatalogId,
  }: {
    force?: boolean
    modalDataOnly?: boolean
    newCatalogId?: number | null
  }) => void
  fetchCategoryAttributes: (catalogId: number | null, itemStatus: ItemStatus) => void
}>(`${stateName}/selectCatalog`)

export const fetchSizeGroupsByCatalog = createAction(`${stateName}/fetchSizeGroupsByCatalog`)

export const fetchPackageSizesRequest = createAction(`${stateName}/fetchPackageSizesRequest`)
