import { takeEvery, put, takeLatest, apply } from 'redux-saga/effects'
import { select, call } from 'typed-redux-saga'

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '_libs/utils/localStorage'
import * as api from 'data/api'
import { HttpStatus, ResponseCode } from 'data/api/response-codes'

import { tracker } from '_libs/common/tracker'
import { userKybSuccessEvent } from '_libs/common/event-tracker/events'

import * as sessionSelectors from 'state/session/selectors'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { BusinessRegistrationModel } from 'types/models'
import { SubmitPaymentsIdentityArgs } from 'types/api'

import { UiState } from 'constants/ui'
import { LEGAL_CODE_FIELD } from 'constants/business-accounts'

import {
  transformBusinessRegistrationToDto,
  transformKybData,
  transformLegalEntity,
  transformPaymentsIdentityConfiguration,
  transformPaymentsIdentityUbo,
} from './transformers'

import * as selectors from './selectors'
import { initialState, actions } from './slice'
import * as statelessActions from './actions'
import { REGISTRATION_FORM_STORAGE_KEY, BusinessDetailsField, KybFieldName } from './constants'

export function* loadRegistrationDefaultValues() {
  const currentUser = yield* select(sessionSelectors.getUser)
  const storageData = getLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY)

  if (!storageData && !currentUser) return

  if (storageData) {
    let registration: BusinessRegistrationModel

    try {
      registration = JSON.parse(storageData)
    } catch (exception) {
      registration = { ...initialState.registration }
    }

    yield put(actions.storeRegistration(registration))
  }

  if (currentUser) {
    yield put(actions.setInitialValuesFromUserDto(currentUser))
  }
}

export function* clearRegistrationDefaultValues() {
  removeLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY)
  yield put(actions.clearRegistration())
}

export function* submitRegistration({
  payload: { registrationFields },
  meta: { successCallback },
}: ReturnType<typeof statelessActions.submitRegistration>) {
  const isDsaFlowEnabled = yield* select(
    getIsFeatureSwitchEnabled('dsa_business_account_registration'),
  )

  yield put(actions.setUiState(UiState.Pending))
  yield put(actions.storeRegistration(registrationFields))

  const registration = yield* select(selectors.getRegistration)

  setLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY, JSON.stringify(registration))

  const response = yield* call(
    isDsaFlowEnabled ? api.submitBusinessRegistrationDsa : api.submitBusinessRegistration,
    transformBusinessRegistrationToDto(registration),
  )

  if ('errors' in response) {
    yield put(actions.setUiState(UiState.Failure))
    yield put(actions.setErrors(response.errors))

    if (
      response.code === ResponseCode.ServerError ||
      response.status === HttpStatus.InternalError ||
      response.status === HttpStatus.TooManyRequests ||
      response.status === HttpStatus.UnprocessableEntity
    ) {
      yield put(actions.setShowGeneralError())
    }

    return
  }

  yield put(actions.setUiState(UiState.Success))
  yield put(actions.clearErrors())

  successCallback()
  removeLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY)
}

export function* updateRegistration({
  payload: { registrationFields },
  meta: { successCallback, validate },
}: ReturnType<typeof statelessActions.updateRegistration>) {
  const isDsaFlowEnabled = yield* select(
    getIsFeatureSwitchEnabled('dsa_business_account_registration'),
  )

  yield put(actions.storeRegistration(registrationFields))

  const registration = yield* select(selectors.getRegistration)

  setLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY, JSON.stringify(registration))

  if (validate) {
    yield put(actions.setUiState(UiState.Pending))

    const response = yield* call(
      isDsaFlowEnabled ? api.validateBusinessRegistrationDsa : api.validateBusinessRegistration,
      transformBusinessRegistrationToDto(registrationFields),
    )

    if ('errors' in response) {
      yield put(actions.setUiState(UiState.Failure))
      yield put(actions.setErrors(response.errors))

      if (
        response.code === ResponseCode.ServerError ||
        response.status === HttpStatus.InternalError ||
        response.status === HttpStatus.TooManyRequests ||
        response.status === HttpStatus.UnprocessableEntity
      ) {
        yield put(actions.setShowGeneralError())
      }

      return
    }
  }

  yield put(actions.setUiState(UiState.Idle))
  yield put(actions.clearErrors())

  successCallback()
}

export function* updateLegalEntity({
  payload: { legalCode },
}: ReturnType<typeof statelessActions.updateLegalEntity>) {
  yield put(actions.setLegalEntityUiState(UiState.Pending))
  yield put(actions.setLegalCode({ legalCode }))

  let registration = yield* select(selectors.getRegistration)
  setLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY, JSON.stringify(registration))

  const response = yield* call(api.getLegalEntity, legalCode)

  if ('errors' in response) {
    yield put(actions.setLegalEntityUiState(UiState.Failure))

    if (response.code === ResponseCode.ValidationError) {
      const errors = response.errors.map(error => ({
        field: error.field === LEGAL_CODE_FIELD ? BusinessDetailsField.LegalCode : error.field,
        value: error.value,
      }))

      yield put(actions.setErrors(errors))
    }

    if (
      response.code === ResponseCode.ServerError ||
      response.status === HttpStatus.InternalError
    ) {
      yield put(actions.setShowGeneralError())
    }

    return
  }

  const legalEntity = transformLegalEntity(response.legal_entity)

  yield put(actions.setLegalEntity(legalEntity))
  yield put(actions.setLegalEntityUiState(UiState.Success))
  yield put(actions.clearErrors())

  registration = yield* select(selectors.getRegistration)
  setLocalStorageItem(REGISTRATION_FORM_STORAGE_KEY, JSON.stringify(registration))
}

export function* fetchConfiguraton() {
  const response = yield* call(api.getPaymentsIdentityFormConfiguration)

  if ('errors' in response) {
    yield put(statelessActions.fetchFormConfigurationFailure())

    return
  }

  const configuration = transformPaymentsIdentityConfiguration(response)

  yield put(actions.setConfiguration({ configuration }))
}

export function* submitKybForm() {
  yield put(actions.setUiState(UiState.Pending))
  let businessAccountId = yield* select(sessionSelectors.getBusinessAccountId)

  if (!businessAccountId) {
    const userInfo = yield* call(api.getCurrentUser)

    if ('errors' in userInfo) {
      yield put(actions.setShowGeneralError())
      yield put(actions.setUiState(UiState.Failure))

      return
    }

    businessAccountId = userInfo.user?.business_account?.id
  }

  if (!businessAccountId) {
    yield put(actions.setShowGeneralError())
    yield put(actions.setUiState(UiState.Failure))

    return
  }

  const businessAddresses = yield* call(api.getBusinessAccountAddresses, { businessAccountId })

  if ('errors' in businessAddresses) {
    yield put(actions.setShowGeneralError())
    yield put(actions.setUiState(UiState.Failure))

    return
  }

  const representativeData = yield* call(api.getBusinessAccountRepresentativeDetails)

  if ('errors' in representativeData) {
    yield put(actions.setShowGeneralError())
    yield put(actions.setUiState(UiState.Failure))

    return
  }

  const kybData = yield* select(selectors.getKybData)

  if (!kybData) {
    yield put(actions.setShowGeneralError())
    yield put(actions.setUiState(UiState.Failure))

    return
  }

  const isLegalRepresentativeUbo = yield* select(selectors.getIsLegalRepresentativeUbo)
  const requiredFields = yield* select(selectors.getRequiredFields)
  const isUboRequired = requiredFields?.includes(KybFieldName.Ubos)
  const isNationalityRequired = requiredFields?.includes(KybFieldName.Nationality)

  const { business_representative } = representativeData
  const { business_address } = businessAddresses.addresses

  let processedKybData: SubmitPaymentsIdentityArgs

  if (
    kybData?.ubos &&
    isLegalRepresentativeUbo &&
    isUboRequired &&
    business_address &&
    business_representative
  ) {
    processedKybData = {
      ...transformKybData(kybData, business_representative, business_address),
      ubos: transformPaymentsIdentityUbo(kybData, business_representative),
    }
  } else {
    if (!business_representative || !business_address) {
      yield put(actions.setShowGeneralError())
      yield put(actions.setUiState(UiState.Failure))

      return
    }
    processedKybData = transformKybData(kybData, business_representative, business_address)
  }

  if (!isNationalityRequired) {
    const { nationality, ...restData } = processedKybData

    processedKybData = restData
  }

  const response = yield* call(api.submitPaymentsIdentity, processedKybData)

  if ('errors' in response) {
    yield put(actions.setErrors(response.errors))

    if (!response.errors.length) yield put(actions.setShowGeneralError())
    yield put(actions.setUiState(UiState.Failure))

    return
  }

  const configuration = transformPaymentsIdentityConfiguration(response)

  yield put(actions.setConfiguration({ configuration }))

  yield put(actions.clearRegistration())

  const kybSuccessEvent = userKybSuccessEvent({
    businessAccountId,
  })

  yield apply(tracker, tracker.track, [kybSuccessEvent])

  yield put(actions.setUiState(UiState.Success))
}

export default function* saga() {
  yield takeEvery(statelessActions.loadRegistrationDefaultValues, loadRegistrationDefaultValues)
  yield takeEvery(statelessActions.clearRegistrationDefaultValues, clearRegistrationDefaultValues)
  yield takeEvery(statelessActions.submitRegistration, submitRegistration)
  yield takeEvery(statelessActions.updateRegistration, updateRegistration)
  yield takeEvery(statelessActions.updateLegalEntity, updateLegalEntity)
  yield takeLatest(statelessActions.fetchFormConfigurationRequest, fetchConfiguraton)
  yield takeLatest(statelessActions.submitKybFormRequest, submitKybForm)
}
