import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import {
  SizeGroupModel,
  BrandModel,
  SizeModel,
  ItemEditModel,
  BookDetailsModel,
} from 'types/models'
import { UiState as UiStateEnum } from 'constants/ui'
import { initialEntityStructure, setNormalizedData } from '_libs/utils/redux'
import { FieldErrorType } from 'pages/ItemUpload/types'

import { AdditionalAttributeModel, DynamicAttributeModel } from 'types/models/dynamic-attribute'

import { stateName, IsbnValidity, ItemStatus, FieldName } from './constants'
import {
  UiState,
  State,
  ItemAttributes,
  ItemFormDataState,
  ColorSelectType,
  DynamicAttribute,
} from './types'

const initialItemAttributes: ItemAttributes = {
  id: null,
  catalogId: null,
  colorIds: [],
  title: '',
  description: '',
  statusId: null,
  sizeId: null,
  price: null,
  isUnisex: false,
  isPromoted: false,
  brandId: null,
  brandTitle: null,
  isbn: null,
  packageSize: {
    id: null,
    custom: {
      domestic: null,
      international: null,
    },
  },
  catalog: {
    attributes: {
      byName: {},
      names: [],
    },
  },
  videoGameRatingId: null,
  alertType: null,
  isBumpChecked: false,
  feedbackId: null,
  measurementLength: null,
  measurementWidth: null,
  manufacturer: null,
  manufacturerLabel: null,
  modelData: null,
}

const initialFormDataState: ItemFormDataState = {
  brands: {
    suggested: initialEntityStructure,
    isLuxury: null,
  },
  colors: {
    suggested: initialEntityStructure,
  },
  catalogs: {
    suggested: [],
  },
  sizes: {
    ...initialEntityStructure,
    suggested: initialEntityStructure,
  },
  sizeGroups: initialEntityStructure,
  dynamicAttributes: {
    byName: {},
  },
  additionalAttributes: {
    byName: {},
  },
  bookDetails: null,
}

const initialUiState: UiState = {
  isLuxuryItemModalOpen: false,
  showMissingPostalCode: false,
  canBumpItem: false,
  dynamicAttributes: {
    uiState: UiStateEnum.Idle,
  },
  isbn: {
    uiState: UiStateEnum.Idle,
  },
}

export const initialState: State = {
  attributes: initialItemAttributes,
  formData: initialFormDataState,
  ui: initialUiState,
  isMultipleSizeGroupsABTestEnabled: false,
  currency: '',
}

const setIsMultipleSizeGroupsABTestEnabled: CaseReducer<
  State,
  PayloadAction<{ isEnabled: boolean }>
> = (draft, action) => {
  const { isEnabled } = action.payload

  draft.isMultipleSizeGroupsABTestEnabled = isEnabled
}

const setInitialItemData: CaseReducer<State, PayloadAction<{ item: ItemEditModel }>> = (
  draft,
  action,
) => {
  const { item } = action.payload

  draft.attributes.id = item.id
  draft.attributes.title = item.title || ''
  draft.attributes.description = item.description || ''
  draft.attributes.isUnisex = item.isUnisex
  draft.attributes.catalogId = item.catalogId
  draft.attributes.statusId = item.statusId
  draft.attributes.sizeId = item.sizeId
  draft.attributes.colorIds = item.colorIds
  draft.attributes.price = item.price
  draft.attributes.brandId = item.brandId
  draft.attributes.brandTitle = item.brand?.title || null
  draft.attributes.isPromoted = item.isPromoted
  draft.attributes.isbn = item.isbn
  draft.attributes.packageSize.id = item.packageSizeId ?? null
  draft.attributes.packageSize.custom.domestic = item.domesticShipmentPrice
  draft.attributes.packageSize.custom.international = item.internationalShipmentPrice
  draft.attributes.videoGameRatingId = item.videoGameRatingId
  draft.attributes.measurementLength = item.measurementLength
  draft.attributes.measurementWidth = item.measurementWidth
  draft.attributes.manufacturer = item.manufacturer
  draft.attributes.manufacturerLabel = item.manufacturerLabel
  draft.attributes.alertType = item.itemAlert?.itemAlertType || null
  draft.attributes.modelData = item.model || null

  draft.formData.brands.isLuxury = item.brand?.isLuxury || null
  draft.formData.bookDetails = item.bookDetails
  draft.ui.canBumpItem = item.canPushUp
}

const setCurrency: CaseReducer<State, PayloadAction<{ currency: string }>> = (draft, action) => {
  draft.currency = action.payload.currency
}

const setStatusId: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    itemStatus: ItemStatus
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.statusId = action.payload.id
  action.payload.removeFieldError(FieldName.Status)
}

const setSizeId: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    itemStatus: ItemStatus
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.sizeId = action.payload.id
  action.payload.removeFieldError(FieldName.Size)
}

const setCatalogId: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    itemStatus: ItemStatus
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.catalogId = action.payload.id
  action.payload.removeFieldError(FieldName.Catalog)
}

const setColorIds: CaseReducer<
  State,
  PayloadAction<{
    ids: Array<number>
    itemStatus: ItemStatus
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  const { ids } = action.payload

  draft.attributes.colorIds = ids
  action.payload.removeFieldError(FieldName.Color)
}

const toggleIsUnisex: CaseReducer<State> = draft => {
  draft.attributes.isUnisex = !draft.attributes.isUnisex
}

const setBrand: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    title: string | null
    isLuxury: boolean
    itemStatus: ItemStatus
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.brandId = action.payload.id
  draft.attributes.brandTitle = action.payload.title
  draft.formData.brands.isLuxury = action.payload.isLuxury
  action.payload.removeFieldError(FieldName.Brand)
}

const setTitle: CaseReducer<
  State,
  PayloadAction<{ title: string; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.title = action.payload.title
  action.payload.removeFieldError(FieldName.Title)
}

const setPrice: CaseReducer<
  State,
  PayloadAction<{ price: number | null; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.price = action.payload.price
  action.payload.removeFieldError(FieldName.Price)
}

const setDescription: CaseReducer<
  State,
  PayloadAction<{ description: string; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, { payload }) => {
  draft.attributes.description = payload.description
  payload.removeFieldError(FieldName.Description)
}

const setModelData: CaseReducer<
  State,
  PayloadAction<{
    name: string
    metadata: Record<string, number>
    removeFieldError: (fieldName: FieldName) => void
  } | null>
> = (draft, action) => {
  draft.attributes.modelData = action.payload
  action.payload?.removeFieldError(FieldName.Model)
}

const setSizeGroups: CaseReducer<State, PayloadAction<{ sizeGroups: Array<SizeGroupModel> }>> = (
  draft,
  action,
) => {
  const { sizeGroups } = action.payload

  setNormalizedData(draft.formData.sizeGroups, sizeGroups)
}

const setIsLuxuryItemModalOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isLuxuryItemModalOpen = action.payload.isOpen
}

const showMissingPostalCode: CaseReducer<State> = draft => {
  draft.ui.showMissingPostalCode = true
}

const hideMissingPostalCode: CaseReducer<
  State,
  PayloadAction<{ resubmitItem: boolean } | undefined>
> = draft => {
  draft.ui.showMissingPostalCode = false
}

const setSizes: CaseReducer<State, PayloadAction<{ sizes: Array<SizeModel> }>> = (
  draft,
  action,
) => {
  const { sizes } = action.payload

  setNormalizedData(draft.formData.sizes, sizes)
}

const setSuggestions: CaseReducer<
  State,
  PayloadAction<{
    colors: Array<ColorSelectType>
    sizes: Array<SizeModel>
    brands: Array<BrandModel>
    catalogs: Array<number>
  }>
> = (draft, action) => {
  const { colors, sizes, brands, catalogs } = action.payload

  draft.formData.catalogs.suggested = catalogs

  setNormalizedData(draft.formData.colors.suggested, colors)
  setNormalizedData(draft.formData.sizes.suggested, sizes)
  setNormalizedData(draft.formData.brands.suggested, brands)
}

const fetchCategoryAttributesSuccess: CaseReducer<
  State,
  PayloadAction<{
    attributes: Array<DynamicAttributeModel>
    additionalAttributes: Array<AdditionalAttributeModel>
  }>
> = (draft, action) => {
  const { attributes, additionalAttributes } = action.payload

  draft.formData.dynamicAttributes.byName = {}
  draft.formData.additionalAttributes.byName = {}

  attributes.forEach(attr => {
    draft.formData.dynamicAttributes.byName[attr.code] = attr
  })

  additionalAttributes.forEach(attr => {
    draft.formData.additionalAttributes.byName[attr.code] = attr
  })

  draft.ui.dynamicAttributes.uiState = UiStateEnum.Success
}

const fetchCategoryAttributesFailure: CaseReducer<State> = draft => {
  draft.ui.dynamicAttributes.uiState = UiStateEnum.Failure
}

const setDynamicAttributesUiState: CaseReducer<State, PayloadAction<{ uiState: UiStateEnum }>> = (
  draft,
  action,
) => {
  const { uiState } = action.payload

  draft.ui.dynamicAttributes.uiState = uiState
}
const setSelectedDynamicAttributeValues: CaseReducer<
  State,
  PayloadAction<{ selectedValues: Array<DynamicAttribute> }>
> = (draft, action) => {
  const { selectedValues } = action.payload

  selectedValues.forEach(value => {
    draft.attributes.catalog.attributes.byName[value.field] = value.value
  })

  const attributeNames = selectedValues.map(value => value.field)

  draft.attributes.catalog.attributes.names = attributeNames
}

const setSelectedDynamicAttributeValue: CaseReducer<
  State,
  PayloadAction<DynamicAttribute & { removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  const { field, value, removeFieldError } = action.payload

  draft.attributes.catalog.attributes.byName[field] = value

  if (draft.attributes.catalog.attributes.names.includes(field)) return

  draft.attributes.catalog.attributes.names.push(field)
  removeFieldError(field as FieldName)
}

const setIsbnPendingState: CaseReducer<State> = draft => {
  draft.ui.isbn.uiState = UiStateEnum.Pending
}

const setIsbn: CaseReducer<
  State,
  PayloadAction<{
    isbn: string | null
    validity: IsbnValidity
    fieldErrors: FieldErrorType
    setFieldErrors: Dispatch<SetStateAction<FieldErrorType>>
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  const { isbn } = action.payload

  draft.attributes.isbn = isbn
  draft.formData.bookDetails = null
  draft.ui.isbn.uiState = UiStateEnum.Idle
  action.payload.removeFieldError(FieldName.Isbn)
}

const setBookDetails: CaseReducer<State, PayloadAction<{ bookDetails: BookDetailsModel }>> = (
  draft,
  action,
) => {
  const { bookDetails } = action.payload

  draft.formData.bookDetails = bookDetails
  draft.ui.isbn.uiState = UiStateEnum.Success
}

const setPackageSizeId: CaseReducer<
  State,
  PayloadAction<{ id: number | null | undefined; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.packageSize.id = action.payload.id ?? null
  action.payload.removeFieldError(FieldName.PackageSize)
}

const setDomesticShippingPrice: CaseReducer<
  State,
  PayloadAction<{
    price: string | null | undefined
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.packageSize.custom.domestic = action.payload.price
  action.payload.removeFieldError(FieldName.DomesticShipmentPrice)
}

const setInternationalShippingPrice: CaseReducer<
  State,
  PayloadAction<{
    price: string | null | undefined
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.packageSize.custom.international = action.payload.price
  action.payload.removeFieldError(FieldName.InternationalShipmentPrice)
}

const setFeedbackId: CaseReducer<State, PayloadAction<{ id: number | null }>> = (draft, action) => {
  const { id } = action.payload

  draft.attributes.feedbackId = id
}

const setVideoGameRatingId: CaseReducer<
  State,
  PayloadAction<{ id: number | null; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.videoGameRatingId = action.payload.id
  action.payload.removeFieldError(FieldName.VideoGameRating)
}

const toggleIsBumpChecked: CaseReducer<State> = draft => {
  draft.attributes.isBumpChecked = !draft.attributes.isBumpChecked
}

const setMeasurementWidth: CaseReducer<
  State,
  PayloadAction<{ width: number | null; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.measurementWidth = action.payload.width
  action.payload.removeFieldError(FieldName.MeasurementWidth)
}

const setMeasurementLength: CaseReducer<
  State,
  PayloadAction<{ length: number | null; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.measurementLength = action.payload.length
  action.payload.removeFieldError(FieldName.MeasurementLength)
}

const setManufacturer: CaseReducer<
  State,
  PayloadAction<{ manufacturer: string | null; removeFieldError: (fieldName: FieldName) => void }>
> = (draft, action) => {
  draft.attributes.manufacturer = action.payload.manufacturer
  action.payload.removeFieldError(FieldName.Manufacturer)
}

const setManufacturerLabel: CaseReducer<
  State,
  PayloadAction<{
    manufacturerLabel: string | null
    removeFieldError: (fieldName: FieldName) => void
  }>
> = (draft, action) => {
  draft.attributes.manufacturerLabel = action.payload.manufacturerLabel
  action.payload.removeFieldError(FieldName.ManufacturerLabel)
}

const itemUploadSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setInitialItemData,
    toggleIsUnisex,
    setCatalogId,
    setColorIds,
    setTitle,
    setDescription,
    setStatusId,
    setSizeId,
    setSizes,
    setPrice,
    setSuggestions,
    setBrand,
    showMissingPostalCode,
    hideMissingPostalCode,
    setIsLuxuryItemModalOpen,
    setSizeGroups,
    fetchCategoryAttributesSuccess,
    fetchCategoryAttributesFailure,
    setDynamicAttributesUiState,
    setSelectedDynamicAttributeValues,
    setSelectedDynamicAttributeValue,
    setIsbn,
    setBookDetails,
    setIsbnPendingState,
    setPackageSizeId,
    setDomesticShippingPrice,
    setInternationalShippingPrice,
    setVideoGameRatingId,
    setMeasurementLength,
    setMeasurementWidth,
    setFeedbackId,
    toggleIsBumpChecked,
    setCurrency,
    setManufacturer,
    setManufacturerLabel,
    setModelData,
    setIsMultipleSizeGroupsABTestEnabled,
  },
})

export const { actions } = itemUploadSlice
export const plug = { [stateName]: itemUploadSlice.reducer }
export default itemUploadSlice.reducer
