import {
  TransactionDto,
  OrderDto,
  BuyerDebitDto,
  ShipmentTrackingDetailsDto,
  UserDto,
} from 'types/dtos'

import {
  OrderModel,
  BuyerDebitModel,
  TransactionModel,
  CurrencyAmountModel,
  TrackingDetailsModel,
  TransactionUserModel,
} from 'types/models'

import { AdditionalTransactionAction } from 'constants/transaction'

import { transformItemDtos } from 'data/transformers/item'
import { transformOfferDto } from 'data/transformers/offer'
import { transformAddress } from 'data/transformers/address'
import { transformShipmentDto } from 'data/transformers/shipment'
import { transformPayInMethodDto } from 'data/transformers/pay-in-method'
import { transformCurrencyConversionDto } from 'data/transformers/currency-conversion'
import { transformUserBundleDiscountDto } from 'data/transformers/bundle-discount'
import {
  transformNullableCurrencyAmountDto,
  transformCurrencyAmountDtoOrString,
  transformCurrencyAmountDto,
} from 'data/transformers/currency-amount'

import { transformPackageSizeDto } from './package-sizes'

export const transformSeller = (seller: UserDto): TransactionUserModel => ({
  id: seller.id,
  takenItemCount: seller.taken_item_count,
  defaultAddress: seller.default_address && transformAddress(seller.default_address),
  bundleDiscount: seller.bundle_discount
    ? transformUserBundleDiscountDto(seller.bundle_discount)
    : null,
  countryCode: seller.country_code,
  business: seller.business,
  closetCountdownEndDate: seller.closet_countdown_end_date,
  login: seller.login,
})

const transformOrderPrice = (buyerDebit: BuyerDebitDto | null): CurrencyAmountModel | null => {
  if (!buyerDebit?.item_price || !buyerDebit?.currency) return null

  return {
    amount: buyerDebit?.item_price,
    currencyCode: buyerDebit?.currency,
  }
}

export const transformOrder = (order: OrderDto, buyerDebit: BuyerDebitDto | null): OrderModel => ({
  items: transformItemDtos(order.items),
  itemIds: order.item_ids,
  creditCardId: buyerDebit?.external_card_code || null,
  title: order.title,
  reserved: order.reserved,
  packageSize: order.package_size ? transformPackageSizeDto(order.package_size) : null,
  price: transformOrderPrice(buyerDebit),
})

export const transformBuyer = (buyer: UserDto): TransactionUserModel => ({
  id: buyer.id,
  takenItemCount: buyer.taken_item_count,
  bundleDiscount: buyer.bundle_discount
    ? transformUserBundleDiscountDto(buyer.bundle_discount)
    : null,
  countryCode: buyer.country_code,
  business: buyer.business,
  defaultAddress: buyer.default_address && transformAddress(buyer.default_address),
  login: buyer.login,
})

export const transformBuyerDebit = (buyerDebit: BuyerDebitDto): BuyerDebitModel => ({
  id: buyerDebit.id,
  creditCardId: buyerDebit.external_card_code,
  offerId: buyerDebit.offer_id,
  status: buyerDebit.status,
  failureReason: buyerDebit.failure_reason,
  itemDiscount: buyerDebit.item_discount,
  itemDiscountType: buyerDebit.item_discount_type,
  itemPrice: buyerDebit.item_price,
  itemPriceSellerShare: buyerDebit.item_price_seller_share,
  protectionFee: buyerDebit.service_fee,
  protectionFeeDiscount: buyerDebit.service_fee_discount,
  protectionFeeWithoutDiscount: buyerDebit.service_fee_without_discount,
  shipmentPrice: buyerDebit.total_shipment_price,
  totalAmount: buyerDebit.total_amount,
  totalAmountWithoutTax: transformCurrencyAmountDtoOrString(
    buyerDebit.total_amount_without_tax,
    buyerDebit.currency,
  ).amount,
  cardAmount: buyerDebit.card_amount,
  discount: buyerDebit.discount,
  marketplaceShare: buyerDebit.marketplace_share,
  sellerShare: buyerDebit.seller_share,
  shipmentDiscount: buyerDebit.shipment_discount,
  payInRedirectUrl: buyerDebit.pay_in_redirect_url,
  payInMethod: buyerDebit.pay_in_method && transformPayInMethodDto(buyerDebit.pay_in_method),
  walletAmount: buyerDebit.wallet_amount,
  payInAmount: buyerDebit.pay_in_amount,
  currency: buyerDebit.currency,
  conversion: buyerDebit.conversion && transformCurrencyConversionDto(buyerDebit.conversion),
  salesTax: buyerDebit.sales_tax ? transformNullableCurrencyAmountDto(buyerDebit.sales_tax) : null,
  isTaxCovered: buyerDebit.tax_covered ?? null,
})

export const transformTransaction = ({
  id,
  buyer_debit_id,
  buyer_debit,
  buyer,
  order,
  shipment,
  available_actions,
  debit_processed_at,
  user_msg_thread_id,
  items_count,
  item_id,
  offer,
  status,
  seller,
  meet_and_pickup,
  status_title,
  user_side,
  buyer_phone_number,
  seller_phone_number,
  transaction_checksum,
  tax_coverage_available,
  item_title,
  suspending_complaint_id,
  offline_verification,
  total_amount_without_tax,
  service_fee,
}: TransactionDto): TransactionModel => ({
  id,
  buyerDebitId: buyer_debit_id,
  order: transformOrder(order, buyer_debit),
  buyer: transformBuyer(buyer),
  buyerDebit: buyer_debit ? transformBuyerDebit(buyer_debit) : null,
  userSide: user_side,
  address: shipment?.to_address ? transformAddress(shipment.to_address) : null,
  shipment: shipment && transformShipmentDto(shipment),
  canMarkAsReceived: available_actions.includes(AdditionalTransactionAction.MarkAsReceived),
  isDebitProcessed: !!debit_processed_at,
  conversationId: user_msg_thread_id,
  availableActions: available_actions,
  itemsCount: items_count,
  itemId: item_id,
  offer: transformOfferDto(offer),
  status,
  seller: seller ? transformSeller(seller) : null,
  isMeetAndPickup: meet_and_pickup,
  statusTitle: status_title,
  buyerPhoneNumber: buyer_phone_number,
  sellerPhoneNumber: seller_phone_number,
  transactionChecksum: transaction_checksum,
  isTaxCoverageAvailable: tax_coverage_available ?? null,
  itemTitle: item_title,
  complaintId: suspending_complaint_id ? suspending_complaint_id.toString() : null,
  offlineVerification: offline_verification,
  totalAmountWithoutTax: transformCurrencyAmountDto(total_amount_without_tax),
  serviceFee: transformCurrencyAmountDto(service_fee),
})

export const transformTransactions = (
  transactions: Array<TransactionDto>,
): Array<TransactionModel> => transactions.map(transformTransaction)

export const transformTrackingDetails = ({
  shipment_tracking_details,
  provider_tracker_url,
}: ShipmentTrackingDetailsDto): TrackingDetailsModel => {
  return {
    trackingDetails: shipment_tracking_details.map(detail => ({
      createdAt: detail.created_at,
      message: detail.message,
      shipmentStatus: detail.shipment_status,
      shipmentStatusLabel: detail.shipment_status_label,
    })),
    providerTrackingUrl: provider_tracker_url,
  }
}
