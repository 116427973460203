'use client'

import { datadogRum } from '@datadog/browser-rum'

import appHealth from '_libs/common/app-health/app-health'
import { serverSide } from '_libs/utils/environment'

export function initialiseMonitoring() {
  if (serverSide) return
  if (process.env.NODE_ENV !== 'production') return

  const PRODUCTION_ENV = 'production'
  const SANDBOX_ENV = 'sandbox'

  // TODO: also check if run in local env to avoid triggering production logs locally
  const version = process.env.NEXT_PUBLIC_RELEASE_VERSION || 'unknown'
  const env = window.location.host.includes(SANDBOX_ENV) ? SANDBOX_ENV : PRODUCTION_ENV
  const ddProxyUrl = 'https://rum-collector.svc.vinted.com/'

  appHealth.initialize(process.env.NEXT_PUBLIC_SERVICE_CLIENT_NAME || 'core-next', version, env)
  datadogRum.init({
    applicationId:
      process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || 'b90722a0-1c29-48b4-af9c-34fffb86338b',
    clientToken:
      process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || 'pub9fd180c090593448102410bf135f044e',
    site: 'datadoghq.eu',
    proxy: ddProxyUrl,
    traceSampleRate: 0,
    // Sampling is done in our proxy server. That is because we want to have 100% sampling for custom metrics
    // This covers the % of sessions that will be sent to our proxy server.
    // We want to have 100% because we use proxy server for client-side metrics, which should have 100% sampling
    // sessionReplaySampleRate is used to indicate the % of sessions that should be sent from the proxy server to Datadog
    sessionSampleRate: env === PRODUCTION_ENV ? 50 : 100,
    // This is used by our proxy server to determine what % of sessions should be sent to Datadog
    // Note that based on our contract we pay less for sessions with replay than for sessions without replay below a certain threshold
    sessionReplaySampleRate: env === PRODUCTION_ENV ? 5 : 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    service: process.env.NEXT_PUBLIC_SERVICE_CLIENT_NAME || 'core-next',
    env,
    version,
    trackingConsent: 'not-granted',
    allowedTracingUrls: [
      url => url.startsWith(window.location.origin) && !url.endsWith('[object%20Request]'),
    ],
  })

  datadogRum.setGlobalContextProperty('imageTag', process.env.NEXT_PUBLIC_IMG_TAG)
}
