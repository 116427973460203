import { plug as conversation } from './conversation/slice'
import { plug as session } from './session/slice'
import { plug as screen } from './screen/slice'
import { plug as banners } from './banners/slice'
import { plug as itemUpload } from './item-upload/slice'
import { plug as featureSwitches } from './feature-switches/slice'
import { plug as checkout } from './checkout'
import { plug as businessAddresses } from './business-addresses/slice'
import { plug as abTests } from './ab-tests/slice'
import { plug as systemConfiguration } from './system-configuration/slice'

export const staticReducers = {
  ...session,
  ...screen,
  ...abTests,
  ...featureSwitches,
}

export const sharedReducers = {
  ...systemConfiguration,
}

// Reducers for core app that are not split by route
export const coreReducers = {
  ...sharedReducers,
  ...conversation,
  ...banners,
  ...itemUpload,
  ...checkout,
  ...businessAddresses,
}

export { plug as businessAccountsReducer } from './business-accounts/slice'
