import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { FeatureSwitchesContext } from '../contexts/FeatureSwitchesProvider'
import { isFeatureEnabled } from '../utils'

const useFeatureSwitch = (feature: string): boolean => {
  const featureSwitches = useContext(FeatureSwitchesContext)

  const isFeatureSwitchRefactorEnabled = useSelector(
    getIsFeatureSwitchEnabled('next_feature_switch_slice_refactor'),
  )

  const featureSwitchFromRedux = useSelector(getIsFeatureSwitchEnabled(feature))

  if (!isFeatureSwitchRefactorEnabled) return featureSwitchFromRedux

  if (!featureSwitches) return false

  return isFeatureEnabled(feature, featureSwitches)
}

export default useFeatureSwitch
