import { createContext } from 'react'
import type { Card, InAppMessage } from '@braze/web-sdk'

import { NullableBrazeStores } from '_libs/common/braze/types/store'
import { DEFAULT_STORES } from '_libs/common/braze/utils/store'
import type { BrazeCardLoggers } from '_libs/common/braze/types/event-loggers'

export type BrazeContextType = {
  cards?: Array<Card> | undefined // TODO: Remove this after refactoring mocking cards in tests
  inAppMessage?: InAppMessage | null // TODO: Remove this after refactoring mocking in-app message in tests
} & BrazeCardLoggers &
  NullableBrazeStores

const defaultBrazeLogFunction = () => Promise.resolve(false)

export const initialBrazeContextValue: BrazeContextType = {
  logCardImpression: defaultBrazeLogFunction,
  logCardClick: defaultBrazeLogFunction,
  logCardDismissal: defaultBrazeLogFunction,
  ...DEFAULT_STORES,
}

const BrazeContext = createContext<BrazeContextType>(initialBrazeContextValue)

export default BrazeContext
