export enum ConversationActionType {
  TrackedShippment = 'tracked_shipping_instructions',
  UntrackedShipment = 'untracked_shipping_instructions',
  CustomShipment = 'custom_shipping_instructions',
  ConfirmPackageSize = 'confirm_package_size',
  ReuploadItem = 'reupload',
  ResolveComplaint = 'resolve_complaint',
  ViewComplaint = 'view_complaint',
  ViewIssueDetails = 'view_issue_details',
  DownloadShippingLabel = 'download_shipping_label',
  CancellationConfirm = 'cancellation_confirm',
  CancellationDecline = 'cancellation_decline',
  TrackShipment = 'track_shipment',
  MarkAsDelivered = 'mark_as_delivered',
  MarkAsShipped = 'mark_as_shipped',
  GetShippingLabel = 'get_shipping_label',
  GoToWallet = 'goto_wallet',
  CompleteTransaction = 'all_is_good',
  CompleteDeliveredTransaction = 'all_is_good_delivered',
  ReportProblem = 'i_have_issues',
  LeaveFeedback = 'leave_feedback',
  UpdateBundle = 'update_bundle',
  WeHaveMet = 'we_have_met',
  ClaimCompensation = 'claim_compensation',
  RequestReturn = 'request_to_return',
  GetReturnShippingLabel = 'get_return_shipping_label',
  ConfirmComplaintResolved = 'confirm_complaint_resolved',
  RefuseComplaintResolved = 'refuse_complaint_resolved',
  ViewDeliveryInstructions = 'view_delivery_instructions',
  OpenQrCode = 'open_qr_code',
  RefundProgress = 'refund_progress',
  NeedHelpSelectChatbotIssue = 'need_help_select_chatbot_issue',
  SelectChatbotIssue = 'select_chatbot_issue',
  ConfirmComplaintResolvedAndLeaveFeedback = 'confirm_complaint_resolved_and_leave_feedback',
  LeaveComplaintFeedback = 'leave_complaint_feedback',
  ActivateBalance = 'activate_balance',
  ReturnOrder = 'return_order',
  MarkAsReturnedToSender = 'complete_return_to_sender',
  AppealTheDecision = 'appeal_the_decision',
  UploadItem = 'upload_item',
  ExtendShippingDeadline = 'extend_shipping_deadline',
  CancelShippingDeadlineExtension = 'cancel_shipping_deadline_extension',
  AgreeExtendShippingDeadline = 'agree_extend_shipping_deadline',
  DeclineShippingDeadlineExtension = 'decline_shipping_deadline_extension',
  PromotedClosetPrecheckout = 'promoted_closet_precheckout',
  PromotedClosetStats = 'promoted_closet_stats',
  CancelChangeDeliveryOptionRequest = 'cancel_change_delivery_option_request',
  AcceptChangeHomeDeliveryOptionRequest = 'accept_change_home_delivery_option_request',
  AcceptChangePickupDeliveryOptionRequest = 'accept_change_pickup_delivery_option_request',
  CancelRefundOffer = 'cancel_refund_offer',
  AcceptRefundOffer = 'accept_refund_offer',
  RejectRefundOffer = 'reject_refund_offer',
  ChooseResolution = 'choose_resolution',
}

export enum ConversationInitiatorType {
  Buy = 'buy',
  Bundle = 'bundle',
  Message = 'message',
  AskSeller = 'ask_seller',
  BuyerOffer = 'buyer_offer',
  MarkAsSold = 'mark_as_sold',
  Reservation = 'reservation',
  SystemMessage = 'system_message',
  BuyerEntersOfferForm = 'buyer_enters_offer_form',
  SellerEntersNotification = 'seller_enters_notification',
}

export enum ConversationMessageType {
  ActionMessage = 'action_message',
  StatusMessage = 'status_message',
  Message = 'message',
  PortalMessage = 'portal_message',
  OfferMessage = 'offer_message',
  OfferRequestMessage = 'offer_request_message',
  ReportSuggestion = 'report_suggestion',
}

export enum ConversationMessageReactionType {
  Heart = 'heart',
}

export enum StatusStyle {
  Gray = 'gray_box',
  Green = 'green_box',
  Red = 'red_box',
  Clear = 'clear_box',
}

export enum StatusIcon {
  Sale = 'sale',
  Shipment = 'shipment',
  Completion = 'completion',
  Failure = 'failure',
}

export enum OfferRequestStatus {
  Pending = 10,
  Accepted = 20,
  Rejected = 30,
  Cancelled = 40,
}

export enum ConversationLinkActions {
  ShipmentTrackingLink = 'react-shipment-tracking-link',
  BuyerDetailsLink = 'buyer-details-link',
  OfflineVerificationInfoLink = 'react-offline-verification-info-link',
  DropOffPointMapLink = 'react-drop-off-point-map-link',
  ReactElectronicsVerificationInfoLink = 'react-electronics-verification-info-link',
}

export enum ComposerType {
  StartConversation = 'start_conversation',
  Reply = 'reply',
}

export enum UserSide {
  Seller = 'seller',
  Buyer = 'buyer',
}

export enum InboxListItemUserSide {
  Seller = 'seller',
  Buyer = 'buyer',
  Unknown = 'unknown',
}

export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_PER_PAGE = 20
export const PER_PAGE_OPTIONS = [20, 50, 100]
export const DEFAULT_PER_PAGE_INDEX = PER_PAGE_OPTIONS.findIndex(
  option => option === DEFAULT_PER_PAGE,
)

export enum GenerateLabelModal {
  GetShippingLabelModal = 'getShippingLabel',
  DropOffTypeSelectionModal = 'dropOffTypeSelection',
  ConfirmationModal = 'confirmation',
  AddressModal = 'address',
  ShippingContactModal = 'shippingContact',
  CourierPickUpDateSelectionModal = 'courierPickUpDateSelection',
  DigitalLabelTypeSelectionModal = 'digitalLabelTypeSelection',
  ReturnTheOrder = 'returnTheOrder',
  ReturnCurrencyConversionModal = 'returnCurrencyConversionModal',
  ShippingDiscountModal = 'shippingDiscountModal',
  DropOffPointMapModal = 'dropOffPointMapModal',
  OpenQrCodeModal = 'openQrCodeModal',
  TaxpayerRestrictionModal = 'taxpayerRestrictionModal',
  ActivateBalanceModal = 'activateBalanceModal',
  EstimatedRefundModal = 'estimatedRefundModal',
  CarrierChangeModal = 'carrierChangeModal',
  UnknownErrorModal = 'unknownErrorModal',
  None = 'none',
}

export enum LabelType {
  LabelTypePdf = 1,
  LabelTypeCode = 2,
  LabelTypeQr = 3,
}

export enum ShipmentLabelType {
  LabelTypePdf = 'pdf',
  LabelTypeQr = 'qr',
  LabelTypeCode = 'code',
}

export enum ModeratedItemAvailabilityStatus {
  Visible = 0,
  Hidden = 1,
  Deleted = 2,
  Expired = 3,
}

export enum DeadlineExtensionStatus {
  Pending = 1,
  Accepted = 10,
  Cancelled = 20,
}

export enum DeliveryOptionChangeRequestStatus {
  Accepted = 10,
  Cancelled = 20,
}

export const CUSTOM_OFFER_PRICE_OPTION_INDEX = 2
