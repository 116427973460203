import { AbTestDto } from 'types/dtos'

import { stateName } from './constants'
import { AllState, State } from './types'

const localState = (state: AllState): State => state[stateName]

export const getAbTestByName =
  (name: string) =>
  (state: AllState): AbTestDto | undefined =>
    localState(state)[name]
