import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { getSystemConfiguration } from 'state/system-configuration/selectors'
import { SystemConfigurationContext } from 'contexts/SystemConfigurationProvider'

const useSystemConfiguration = () => {
  const isSysConfigurationRefactorEnabled = useFeatureSwitch('system_configuration_slice_refactor')
  const systemConfigurationFromRedux = useSelector(getSystemConfiguration)
  const systemConfigurationFromContext = useContext(SystemConfigurationContext)

  if (!isSysConfigurationRefactorEnabled) return systemConfigurationFromRedux

  if (!systemConfigurationFromContext) {
    throw new Error('useSystemConfiguration must be used within a SystemConfigurationProvider')
  }

  return systemConfigurationFromContext
}

export default useSystemConfiguration
