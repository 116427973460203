import { ReactNode } from 'react'

import ErrorContent from './ErrorContent'
import ErrorHeader from './ErrorHeader'

type Props = ComponentProps<typeof ErrorContent> & { children?: ReactNode }

const ErrorPage = ({ children, ...contentProps }: Props) => (
  <div className="fullpage-layout u-background-white u-fill-viewport">
    <ErrorHeader />
    <main className="site">
      <div className="container">
        <ErrorContent {...contentProps} />
        {children}
      </div>
    </main>
  </div>
)

export default ErrorPage
