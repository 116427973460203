export const UPLOADED_ITEM_ID = 'uploaded_item_id'
export const UPLOADED_ITEM_PROMOTIONS = 'uploaded_item_promotions'

export enum PromotionStorageKeys {
  ShowBumped = 'pushed_up_promotion',
  ShowFeedback = 'feedback_promotion',
  ShowUploadAnotherItemTip = 'upload_another_item_tip_promotion',
}

export const BUMPED_ITEM_STORAGE_KEY = 'new_bumped_item_id'
export const SAVED_ITEM_STORAGE_KEY = 'new_saved_item_id'
export const FEEDBACK_ITEM_ID = 'feedback_item_id'
export const SHOW_UPLOAD_ANOTHER_ITEM_TIP = 'show_upload_another_item_tip'

export enum ItemAfterUploadActions {
  ShowUploadAnotherItemTip = 'show_upload_another_item_tip',
  ShowEVSModal = 'show_electronics_verification_modal',
  ShowIVSModal = 'show_item_verification_modal',
  ShowBumpsCheckout = 'show_bumps_checkout',
}
