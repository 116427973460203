import { PackageSizeDto } from 'types/dtos'
import { PackageSizeModel } from 'types/models'

export const transformPackageSizeDto = ({
  code,
  custom,
  education_text,
  format_description,
  id,
  standard,
  title,
  weight_description,
  image,
}: PackageSizeDto): PackageSizeModel => ({
  code,
  custom,
  educationText: education_text,
  formatDescription: format_description,
  id,
  standard,
  title,
  weightDescription: weight_description,
  image,
})
