import { BeyondFashionBannerDto } from 'types/dtos'
import { BeyondFashionBannerModel } from 'types/models'

export const transformBeyondFashionBanner = ({
  user_test_group,
  name,
  image_url,
  dark_image_url,
  title,
  body,
  actions,
}: BeyondFashionBannerDto): BeyondFashionBannerModel => ({
  userTestGroup: user_test_group,
  name,
  imageUrl: image_url,
  darkImageUrl: dark_image_url,
  title,
  body,
  actions,
})
