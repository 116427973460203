import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { FeatureSwitchDto } from '@marketplace-web/shared/feature-switches'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {}

const setFeatureSwitches: CaseReducer<
  State,
  PayloadAction<{ featureSwitches: Array<FeatureSwitchDto> }>
> = (draft, action) => {
  const { featureSwitches } = action.payload

  featureSwitches.forEach(feature => {
    draft[feature.name] = feature.enabled
  })
}

const featureSwitchesSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setFeatureSwitches,
  },
})

export const { actions } = featureSwitchesSlice
export const plug = { [stateName]: featureSwitchesSlice.reducer }
export default featureSwitchesSlice.reducer
