import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import { UiState } from 'constants/ui'
import { ErrorItem } from 'types/api'
import {
  BusinessRegistrationModel,
  LegalEntityModel,
  PaymentIdentityUboModel,
  PaymentsIdentityConfigurationModel,
  PaymentsIdentityFileModel,
} from 'types/models'
import { UserDto } from 'types/dtos'

import { stateName } from './constants'
import { KybFormData, State } from './types'

const initialKybFormData: KybFormData = {
  firstName: null,
  lastName: null,
  birthdate: null,
  address: null,
  ubos: null,
  isLegalRepresentativeUbo: true,
  documents: [],
  identityDocumentExpiryDate: '',
}

export const initialState: State = {
  registration: {
    businessRepresentative: {
      fullName: '',
      firstNames: '',
      lastName: '',
      birthday: '',
      nationality: {
        code: '',
        title: '',
      },
      country: {
        code: '',
        title: '',
      },
    },
    businessDetails: {
      legalCode: '',
      legalName: '',
      entityType: '',
      registrarName: '',
      vat: '',
      isVatDisabled: false,
    },
    addresses: {
      businessAddress: {
        postalCode: '',
        fullPostalCode: '',
        city: '',
        countryId: null,
        line1: '',
        line2: '',
      },
      legalRepresentativeAddress: {
        postalCode: '',
        fullPostalCode: '',
        city: '',
        countryId: null,
        line1: '',
        line2: '',
      },
      isSameLegalRepresentativeAddress: true,
      isBusinessEqualToPersonalAddress: false,
    },
    profile: {
      photoTempUuid: '',
      photoUrl: undefined,
      businessAccountName: '',
      phoneNumber: '',
      isPhoneNumberPersonal: false,
      email: '',
      isEmailPersonal: false,
    },
  },
  uiState: UiState.Idle,
  legalEntityUiState: UiState.Idle,
  errors: [],
  showGeneralError: false,
  entityType: '',
  paymentsIdentityConfiguration: null,
  kybFormData: initialKybFormData,
}

const storeRegistration: CaseReducer<State, PayloadAction<Partial<BusinessRegistrationModel>>> = (
  draft,
  action,
) => {
  Object.keys(action.payload).forEach(key => {
    draft.registration[key] = cloneDeep(action.payload[key])
  })
}

const clearRegistration: CaseReducer<State> = draft => {
  draft.registration = initialState.registration
}

const setUiState: CaseReducer<State, PayloadAction<UiState>> = (draft, action) => {
  draft.uiState = action.payload
}

const setEntityType: CaseReducer<State, PayloadAction<{ entityType: string }>> = (
  draft,
  action,
) => {
  draft.entityType = action.payload.entityType
}

const setErrors: CaseReducer<State, PayloadAction<Array<ErrorItem>>> = (draft, action) => {
  draft.errors = action.payload
}

const clearErrors: CaseReducer<State> = draft => {
  draft.errors = []
  draft.showGeneralError = false
}

const setInitialValuesFromUserDto: CaseReducer<State, PayloadAction<UserDto>> = (draft, action) => {
  const { real_name, birthday, photo } = action.payload

  if (real_name && !draft.registration.businessRepresentative.fullName) {
    draft.registration.businessRepresentative.fullName = real_name
  }

  if (birthday && !draft.registration.businessRepresentative.birthday) {
    draft.registration.businessRepresentative.birthday = birthday
  }

  if (photo?.url && !draft.registration.profile.photoUrl) {
    draft.registration.profile.photoUrl = photo.url
  }
}

const setLegalCode: CaseReducer<State, PayloadAction<{ legalCode: string }>> = (draft, action) => {
  draft.registration.businessDetails.legalCode = action.payload.legalCode
}

const setLegalEntity: CaseReducer<State, PayloadAction<LegalEntityModel>> = (draft, action) => {
  draft.registration.businessDetails.entityType = action.payload.typeLabel
  draft.registration.businessDetails.legalName = action.payload.name
  draft.registration.addresses.businessAddress.postalCode = action.payload.postalCode
  draft.registration.addresses.businessAddress.line1 = action.payload.streetAddressLine1
  draft.registration.addresses.businessAddress.line2 = action.payload.streetAddressLine2
}

const setLegalEntityUiState: CaseReducer<State, PayloadAction<UiState>> = (draft, action) => {
  draft.legalEntityUiState = action.payload
}

const setShowGeneralError: CaseReducer<State> = draft => {
  draft.showGeneralError = true
}

const setConfiguration: CaseReducer<
  State,
  PayloadAction<{ configuration: PaymentsIdentityConfigurationModel }>
> = (draft, action) => {
  const { configuration } = action.payload

  draft.paymentsIdentityConfiguration = configuration

  draft.kybFormData = {
    firstName: '',
    lastName: '',
    birthdate: configuration.birthdate,
    address: configuration.address,
    ubos: null,
    isLegalRepresentativeUbo: draft.kybFormData.isLegalRepresentativeUbo,
    documents: [],
    identityDocumentExpiryDate: draft.kybFormData.identityDocumentExpiryDate,
  }
}

const setKybFormData: CaseReducer<
  State,
  PayloadAction<{
    ubos: Array<PaymentIdentityUboModel> | null
    documents: Array<PaymentsIdentityFileModel> | null
  }>
> = (draft, action) => {
  const { ubos, documents } = action.payload

  draft.kybFormData.firstName = draft.registration.businessRepresentative.firstNames || ''
  draft.kybFormData.lastName = draft.registration.businessRepresentative.lastName || ''
  draft.kybFormData.birthdate = draft.registration.businessRepresentative.birthday || ''
  draft.kybFormData.address = draft.registration.addresses.businessAddress
    ? {
        ...draft.registration.addresses.businessAddress,
        countryId: Number(draft.registration.addresses.businessAddress.countryId) || null,
      }
    : null

  draft.kybFormData.ubos = ubos
  draft.kybFormData.documents = documents
}

const setIsLegalRepresentativeUbo: CaseReducer<
  State,
  PayloadAction<{ isLegalRepresentativeUbo: boolean }>
> = (draft, action) => {
  const { isLegalRepresentativeUbo } = action.payload

  draft.kybFormData.isLegalRepresentativeUbo = isLegalRepresentativeUbo
}

const businessAccountsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    storeRegistration,
    clearRegistration,
    setUiState,
    setEntityType,
    setErrors,
    clearErrors,
    setLegalCode,
    setLegalEntity,
    setLegalEntityUiState,
    setShowGeneralError,
    setInitialValuesFromUserDto,
    setConfiguration,
    setKybFormData,
    setIsLegalRepresentativeUbo,
  },
})

export const { actions } = businessAccountsSlice
export const plug = { [stateName]: businessAccountsSlice.reducer }
export default businessAccountsSlice.reducer
