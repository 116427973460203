import { datadogRum } from '@datadog/browser-rum'

import { MS_PER_SECOND } from 'constants/date'
import { serverSide } from '_libs/utils/environment'
import { logMessage } from '_libs/utils/window'

const getLabels = (labels: Record<string, string | number> | undefined) => {
  const DEFAULT_LABELS = {
    env: window.location.host.includes('sandbox') ? 'sandbox' : 'production',
  }

  return {
    ...DEFAULT_LABELS,
    ...labels,
  }
}

// Assumes that buckets are sorted in ascending order
const generateBuckets = (value: number, buckets: Array<number>) => {
  let isCountSet = false

  return buckets.map(bucket => {
    const fitsBucket = value <= bucket
    const newValue = {
      upper_limit: bucket,
      count: !isCountSet && fitsBucket ? 1 : 0,
    }

    if (fitsBucket) isCountSet = true

    return newValue
  })
}

/**
 * Helpers for tracking client-side metrics. Can be used for SLOs, Grafana dashboards, alerting.
 * All metric names will be automatically prefixed with marketplace_web
 * Important: can only be used in client-side environment. Using the same metric name server-side and client-side will result in having 2 separate metrics.
 * Implemented through Datadog RUM which sends an event to our proxy server, which then forwards the metric to Prometheus.
 */
const clientSideMetrics = {
  /**
   * Counter is a metric that can only go up.
   * @param name - The name of the counter metric. It can be dynamic, but should not have high cardinality, such as user ids.
   * @param labels - Labels that will be applied to the metric in prometheus. Avoid high cardinality labels, such as user ids.
   *
   * @example
   * clientSideMetrics.counter('muted_video_autoplay').increment()
   */
  counter: (name: string, labels?: Record<string, string | number>) => {
    return {
      increment: (value = 1) => {
        if (serverSide) {
          logMessage(`Client side metric ${name} was used server-side`)

          return
        }

        datadogRum.addAction(name, {
          metrics: [
            {
              kind: 'incremental',
              name,
              namespace: 'marketplace_web',
              counter: {
                value,
              },
              tags: getLabels(labels),
            },
          ],
        })
      },
    }
  },
  /**
   * Gauge is a metric that can go up and down.
   * @param name - The name of the gauge metric. It can be dynamic, but should not have high cardinality, such as user ids.
   * @param labels - Labels that will be applied to the metric in prometheus. Avoid high cardinality labels, such as user ids.
   *
   * @example
   * clientSideMetrics.gauge('simultaneous_conversations').set(42)
   */
  gauge: (name: string, labels?: Record<string, string | number>) => {
    return {
      set: (value: number) => {
        if (serverSide) {
          logMessage(`Client side metric ${name} was used server-side`)

          return
        }

        datadogRum.addAction(name, {
          metrics: [
            {
              kind: 'absolute',
              name,
              namespace: 'marketplace_web',
              gauge: {
                value,
              },
              tags: getLabels(labels),
            },
          ],
        })
      },
    }
  },
  /**
   * Histogram is a metric type that counts how many times a value falls into a certain range (buckets)
   * Allows calculating percentiles, averages and other aggregations.
   * Commonly used for measuring duration, e.g. how long a loader is displayed
   * @param name - The name of the histogramic metric. It can be dynamic, but should not have high cardinality, such as user ids.
   * @param labels - Labels that will be applied to the metric in prometheus. Avoid high cardinality labels, such as user ids.
   * @param buckets - Buckets of the histogram. Depending on the buckets, the accuracy of aggregations (such as percentiles) will vary.
   *
   * @example
   * // Measuring how many messages were sent per conversation
   * clientSideMetrics.histogram('message_sent_per_conversation').observe()
   *
   * // Measuring duration of API requests
   * const stopMetricTimer = clientSideMetrics.histogram('home_page_parallel_requests_duration').startTimer()
   * await Promise.all(requests)
   * stopMetricTimer()
   */
  histogram: (
    name: string,
    labels?: Record<string, string | number>,
    buckets = [0.005, 0.01, 0.025, 0.05, 0.1, 0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20],
  ) => {
    return {
      observe: (value: number) => {
        if (serverSide) {
          logMessage(`Client side metric ${name} was used server-side`)

          return
        }

        datadogRum.addAction(name, {
          metrics: [
            {
              kind: 'incremental',
              name,
              namespace: 'marketplace_web',
              histogram: {
                buckets: generateBuckets(value, buckets),
                count: 1,
                sum: value,
              },
              tags: getLabels(labels),
            },
          ],
        })
      },
      /**
       * Start a timer. Calling the returned function will observe the duration in
       * seconds in the histogram.
       * @param value - The value to set.
       * @return Function to invoke when timer should be stopped. The value it returns is the timed duration.
       */
      startTimer: () => {
        const startTime = performance.now()

        return () => {
          if (serverSide) {
            logMessage(`Client side metric ${name} was used server-side`)

            return 0
          }

          const endTime = performance.now()
          const value = (endTime - startTime) / MS_PER_SECOND

          clientSideMetrics.histogram(name, labels).observe(value)

          return value
        }
      },
    }
  },
}

export default clientSideMetrics
