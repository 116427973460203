import { createSelector } from 'reselect'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUser = (state: AllState) => localState(state).user

export const getUserId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.id
}

export const getUserEmail = (state: AllState): string | null => {
  const user = getUser(state)

  if (!user) return null

  return user.email
}

export const getUserCountryCode = createSelector(getUser, user => user?.country_code)

export const getUserCountryIsoCode = createSelector(getUser, user => user?.country_iso_code)

export const getCountryId = (state: AllState): number | null => {
  const user = getUser(state)

  if (!user) return null

  return user.country_id
}

export const getAnonId = (state: AllState) => localState(state).anon_id
export const getTrackingPlatform = (state: AllState) => localState(state).tracking_platform
export const getTrackerDebugPin = (state: AllState) => localState(state).tracker_debug_pin
export const getLanguageCode = (state: AllState) => localState(state).languageCode
export const getIsUserRestrictedByTerms = (state: AllState) => getUser(state)?.restricted_by_terms
export const getUserItemCount = (state: AllState) => getUser(state)?.item_count || 0
export const getUserBundleDiscount = (state: AllState) => getUser(state)?.bundle_discount
export const getIsFundraiserActive = (state: AllState) => !!getUser(state)?.fundraiser?.active
export const getFundraiserPercentage = (state: AllState) => getUser(state)?.fundraiser?.percentage

export const getIsAuthenticated = (state: AllState) => !!getUser(state)
export const getBusinessAccount = (state: AllState) => getUser(state)?.business_account
export const getBusinessAccountId = (state: AllState) => getBusinessAccount(state)?.id
export const getIsBusinessAccount = (state: AllState) => !!getUser(state)?.business

export const getCountryCode = createSelector(localState, ({ countryCode }) => countryCode)

export const getExposee = createSelector(
  [getAnonId, getUserId, getCountryCode],
  (anonId, userId, countryCode) => ({
    anonId,
    userId,
    countryCode,
  }),
)

export const getIsWebView = (state: AllState) => localState(state).isWebView
