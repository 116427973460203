import { createSelector } from 'reselect'

import { entitiesFromStructure } from '_libs/utils/redux'

import { BookDetailStatus, CatalogGroupFieldCodes } from './constants'
import { AllState, CatalogSelectType, DynamicAttribute } from './types'

const select = (state: AllState) => state.itemUpload
const selectUi = (state: AllState) => select(state).ui
const selectFormData = (state: AllState) => select(state).formData
export const selectAttributes = (state: AllState) => select(state).attributes

export const getCatalogSuggestions = (state: AllState) => select(state).formData.catalogs.suggested
export const getCurrency = (state: AllState) => select(state).currency
export const getIsMultipleSizeGroupsABTestEnabled = (state: AllState) =>
  select(state).isMultipleSizeGroupsABTestEnabled

export const getIsBrandLuxury = (state: AllState) => selectFormData(state).brands.isLuxury

export const getBrandTitle = (state: AllState) => selectAttributes(state).brandTitle

export const getSuggestedBrands = createSelector(selectFormData, ({ brands }) =>
  entitiesFromStructure(brands.suggested),
)

export const getSuggestedColors = createSelector(selectFormData, ({ colors }) =>
  entitiesFromStructure(colors.suggested),
)

export const getSuggestedSizes = createSelector(selectFormData, ({ sizes }) =>
  entitiesFromStructure(sizes.suggested),
)

export const getManufacturer = (state: AllState) => select(state).attributes.manufacturer

export const getManufacturerLabel = (state: AllState) => select(state).attributes.manufacturerLabel

export const getIsManufacturerVisible = (state: AllState) =>
  !!select(state).formData.additionalAttributes.byName[CatalogGroupFieldCodes.Manufacturer]

export const getIsManufacturerLabelVisible = (state: AllState) =>
  !!select(state).formData.additionalAttributes.byName[CatalogGroupFieldCodes.ManufacturerLabel]

export function getSize(state: AllState, id: number) {
  const { sizes } = select(state).formData

  return sizes.byId[id]
}

export function getCurrentSize(state: AllState) {
  const { sizeId } = selectAttributes(state)

  if (!sizeId) return null

  return getSize(state, sizeId)
}

export const getIsLuxuryItemModalOpen = (state: AllState) => selectUi(state).isLuxuryItemModalOpen

export const showMissingPostalCode = (state: AllState) => selectUi(state).showMissingPostalCode

export const getSizeGroups = createSelector(selectFormData, ({ sizeGroups }) =>
  entitiesFromStructure(sizeGroups),
)

export function getCurrentSizeGroup(
  state: AllState,
  currentCatalog: CatalogSelectType | null | undefined,
) {
  const { sizeGroups } = select(state).formData

  if (
    currentCatalog?.sizeGroupId === undefined ||
    currentCatalog?.sizeGroupId === null ||
    !sizeGroups
  ) {
    return null
  }

  if (currentCatalog.catalogIds.length !== 0) return null

  return sizeGroups.byId[currentCatalog.sizeGroupId]
}

export function getCurrentSizes(
  state: AllState,
  currentCatalog: CatalogSelectType | null | undefined,
) {
  const sizeGroup = getCurrentSizeGroup(state, currentCatalog)

  if (!sizeGroup) return []

  return sizeGroup.sizes
}

export const getIsPackageSizesVisible = createSelector(
  selectAttributes,
  ({ catalogId }) => !!catalogId,
)

export const getIsbnUiState = createSelector(selectUi, ({ isbn }) => isbn.uiState)
export const getIsbnAuthor = createSelector(selectFormData, ({ bookDetails }) =>
  bookDetails ? bookDetails.author : null,
)
export const getIsbnBookTitle = createSelector(selectFormData, ({ bookDetails }) =>
  bookDetails ? bookDetails.title : null,
)

export function getBookDetailStatus(state: AllState, isIsbnVisible: boolean) {
  const { bookDetails } = selectFormData(state)

  if (!isIsbnVisible || !bookDetails) return null

  if (bookDetails.author || bookDetails.title) return BookDetailStatus.Found

  return BookDetailStatus.NotFound
}

export const getCanBumpItem = createSelector(selectUi, ({ canBumpItem }) => canBumpItem)

export const getDynamicAttributesSelector = (fieldName: string) => (state: AllState) =>
  selectFormData(state).dynamicAttributes.byName[fieldName] || undefined

export const getDynamicAttributesUiState = createSelector(
  selectUi,
  ({ dynamicAttributes }) => dynamicAttributes.uiState,
)

export const getSelectedDynamicAttributeValue = (state: AllState, fieldName: string) =>
  selectAttributes(state).catalog.attributes.byName[fieldName] || []

export const getSelectedDynamicAttributes = (state: AllState) => {
  const {
    catalog: { attributes },
  } = selectAttributes(state)
  const { dynamicAttributes } = selectFormData(state)

  return attributes.names
    .map(name => {
      if (!dynamicAttributes.byName[name]) return null

      return {
        field: name,
        value: attributes.byName[name],
      }
    })
    .filter((attribute): attribute is DynamicAttribute => !!attribute)
}

export const getIsPromoted = createSelector(selectAttributes, ({ isPromoted }) => isPromoted)

export const getAlertType = createSelector(selectAttributes, ({ alertType }) => alertType)

export const getFeedbackId = createSelector(selectAttributes, ({ feedbackId }) => feedbackId)

export const getMeasurementWidth = createSelector(
  selectAttributes,
  ({ measurementWidth }) => measurementWidth,
)
export const getMeasurementLength = createSelector(
  selectAttributes,
  ({ measurementLength }) => measurementLength,
)

export const getIsBumpChecked = createSelector(
  selectAttributes,
  ({ isBumpChecked }) => isBumpChecked,
)

export const getCurrentStatusId = (state: AllState) => selectAttributes(state).statusId
